import { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { captureException } from "@sentry/core";
import { ObjectResponse } from "@/app/data/objects";
import { useLatestRef } from "@/hooks/useLatestRef";
import { BroadcastChannelName } from "@/constants";
import { broadcastMessage } from "@/utils/broadcastChannel.utils";


type Ref = {
  version: string | null;
  saveInProgress: { [id: string]: boolean };
  data: { [id: string]: any | null };
}

const ref: Ref = {
  version: null,
  saveInProgress: {},
  data: {},
}

// hook is responsible for syncing aws objects data with latest data
// it returns `syncObject` function which accept latests data and updates it in aws every `delay` ms
// `syncObject` can be called multiple times
export const useSyncObject = <T, M>({
  objectDetails,
  updateApi, 
  broadcastChannelName,
  delay = 300,
  updateStoredQueryData,
}: {
  objectDetails: ObjectResponse<T, M> | undefined; // data from aws
  broadcastChannelName: BroadcastChannelName;
  delay?: number; // delay beetween each sync
  updateApi: (data: { id: string, version: string, data: T }) => Promise<ObjectResponse<T, M>>;
  updateStoredQueryData: (data: T, objectId: string) => void; // function which updates redux store with latest data (optimistic update)
}) => {
  const objectRef = useLatestRef<ObjectResponse<T, M> | undefined>(objectDetails);

  if (!objectDetails && !objectRef.current && !objectRef.current) {
    // set object ref only once when it's available
    objectRef.current = objectDetails;
  }

  useEffect(() => {
    // sync version with latest if object was changed wihtout using `syncObject` function, for exmaple if object was updated from another tab
    const v = objectDetails?.version
    if (v && v !== ref.version) {
      ref.version = v
    }
  }, [objectDetails?.version])

  const syncObjectDebouncedApi = useCallback(
    debounce(async (data: T) => {
      const id = objectRef.current?.id as string;

      if (ref.saveInProgress[id]) {
        // last save is still in progress. Sync will be triggered with latest data after previous request is done
        return;
      }

      const latestVersion = ref.version || objectRef.current?.version as string;
      ref.data[id] = null; // clear data so we don't sync it again while request is in progress
      ref.saveInProgress[id] = true;
  
      try {
        const responseObject = await updateApi({ id, version: latestVersion!, data });
        broadcastMessage(broadcastChannelName, responseObject)
        ref.version = responseObject.version; // save latest version
      } catch (e) {
        captureException(e);
        // TODO: do we want to log an error here? or maybe retry it if there is no data waiting and show an error after retry?
      }
      
      ref.saveInProgress[id] = false;
  
      // trigger sync data again if there are new changes
      const latestData = ref.data[id];
      if (latestData) {
        syncObject(latestData);
      }
        
    }, delay)
  , [])

  const syncObject = (data: T) => {
    const id = objectRef.current?.id as string;
    
    updateStoredQueryData(data, id) // sync data in store so it has latest data
    ref.data[id] = data; // save latest data for request

    syncObjectDebouncedApi(data);
  }

  return { 
    syncObject, 
  }
}

