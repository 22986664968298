import { PortfolioResponse, PortfolioValue } from "@/app/data/portfolios";
import { baseApi } from ".";
import { captureException } from "@sentry/core";
import { isEmpty } from "lodash";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { PORTFOLIO_NAV_DEFAULT_VALUE } from "@/constants";
import { DEFAULT_POSITION_SIZE } from "@/app/portfolio/portfolio.constants";

export const portfolioEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPortfolio: builder.query<PortfolioResponse, string>({
      query: (id) => `/objects/${id}`,
      keepUnusedDataFor: 10, // 10 seconds cache
      providesTags: ['PortfolioObject'],
      transformResponse: (response: PortfolioResponse): PortfolioResponse => {
        if (!response) {
          return response;
        }

        if (isEmpty(response?.value?.positions)) {
          return response;
        }

        return {
          ...response,
          value: {
            ...response.value,
            nav: response.value.nav ?? PORTFOLIO_NAV_DEFAULT_VALUE,
            positions: response.value.positions.map(p => {
              p.size = p.size ?? DEFAULT_POSITION_SIZE;
              return p;
            })
          }
        }
      }
    }),
    getPortfolioList: builder.query<PortfolioResponse[], void>({
      query: () => `/objects?type=portfolio`,
      providesTags: ['PortfolioObjectList'],
      keepUnusedDataFor: 10,
    }),
    createPortfolioObject: builder.mutation<PortfolioResponse, { name: string, positions: PortfolioValue['positions'] }>({
      query: ({ name, positions }) => ({
        url: '/objects',
        method: 'POST',
        body: {
          type: 'portfolio',
          metadata: JSON.stringify({ name }),
          value: { 
            positions,
            nav: PORTFOLIO_NAV_DEFAULT_VALUE,
          }
        },
      }),
      invalidatesTags: r => r ? ['PortfolioObjectList'] : [], // invalidate after success only
      async onQueryStarted( _, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          try {
            dispatch(
              portfolioEndpoints.util.updateQueryData('getPortfolioList', undefined, (prevData) => {
                if (!prevData) return [result.data];
                return [...prevData, result.data];
              })
            );
          } catch (e) {
            captureException(e);
            console.error(e);
          }
        } catch {}
      },
    }),
    updatePortfolioObject: builder.mutation<PortfolioResponse, {
      id: string,
      version: string,
      data?: PortfolioValue,
      nav?: number | null
      metadata?: { name: string },
      optimistic?: boolean
    }>({
      query: ({ id, version, data, metadata }) => {
        return {
          url: '/objects',
          method: 'PATCH',
          body: {
            id,
            version,
            ...(metadata && { metadata: JSON.stringify(metadata) }),
            ...(data ? { value: data } : {}),
          },
        }
      },
      async onQueryStarted( { id, data, metadata, optimistic }, { dispatch, queryFulfilled }) {
        const _optimistic = optimistic ?? true;
        let patchResult: PatchCollection | undefined = undefined;
        
        const updatePortfolio = (p: PortfolioResponse) => {
          return {
            ...p,
            ...(metadata && { metadata: JSON.stringify(metadata) }),  // api works in a way that if metadata or positions are not provided it return those keys as empty strings but actually tehre is a value on the server
            value: {
              ...p.value,
              positions: data?.positions || (p.value ? (p.value.positions || []) : []),
              nav: data?.nav ?? p?.value?.nav ?? PORTFOLIO_NAV_DEFAULT_VALUE,
            }
          }
        }

        if (_optimistic) { 
          // optimistic update
          patchResult = dispatch(
            portfolioEndpoints.util.updateQueryData('getPortfolio', id, (portfolio) => {
              return updatePortfolio(portfolio)
            })
          );

          // TODO: implement optimistic update for the list
        }


        // sync data in store after query fullfilled, no need for refetch
        try {
          await queryFulfilled;

          if (_optimistic) {
            // optimistic update - we already have latest data. We need to sync only aws generated data and object `value` we have already up to date
            dispatch(
              portfolioEndpoints.util.updateQueryData('getPortfolio', id, (r: PortfolioResponse) => ({
                ...r,
                value: r.value,
              }))
            );

          } else {
            try {
              // update portfolio item
              dispatch(
                portfolioEndpoints.util.updateQueryData('getPortfolio', id, updatePortfolio)
              );
  
              // update portfolio item in the list
              dispatch(
                portfolioEndpoints.util.updateQueryData('getPortfolioList', undefined, (prevData) => {
                  return prevData.map(p => {
                    return p.id === id ?  updatePortfolio(p) : p
                  })
                })
              );
            } catch (e) {
              captureException(e);
              console.error(e);
            }
          }

          

        } catch {
          if (_optimistic) {
            // revert state back if it failed
            patchResult?.undo?.();
          }
        };
      },
    }),

  }),
});

export const {
  useGetPortfolioQuery,
  useLazyGetPortfolioQuery,
  useGetPortfolioListQuery,
  useCreatePortfolioObjectMutation,
  useUpdatePortfolioObjectMutation,
} = portfolioEndpoints;


