import { AlertItem, AlertState, AlertType } from "../data/alerts";
import { SnoozeReverseMap } from "./alert.constants";
import { AlertTableItem, TargetAlertTableItem } from "./alertsTable";

export const isAlertTableItem = (item: AlertTableItem | null): item is AlertTableItem => Boolean(item)

// alerts are unique by figi and side
export const isSameAlertConfig = (a: AlertItem | AlertTableItem['alert'], b: AlertItem | AlertTableItem['alert']) => {
  if (isVolatilityAlert(a) && isVolatilityAlert(b)) {
    return a.figi === b.figi;
  }

  return a.figi === b.figi && a.side === b.side;
};

export const formatPercentage = (pctg: number | undefined) => {
  if (pctg === undefined) {
    return '-';
  }

  if (pctg <= 5) {
    return '<5%';
  }
  if (pctg >= 95) {
    return '>95%';
  }

  return `${pctg}%`
}

export const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

// TODO: create some global utils file or folder 
export const waitForElement = (selector: string, retries = 3, delay = 1000): Promise<Element | null> => {
  let count = 0;

  const tryAgain = (): Promise<Element | null> => {
    if (count < retries) {
      count++; const element = document.querySelector(selector);
      if (!element) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(tryAgain()), delay);
        });
      }

      return Promise.resolve(element);
    }

    return Promise.resolve(null);
  }
  return tryAgain()
}


export function getSnoozeLabel(durationMs: number) {
  return SnoozeReverseMap[durationMs];
}

export function isAlertDisabled(alert: AlertItem | AlertTableItem['alert']) {
  return alert.state === AlertState.Disabled;
}

export function isAlertActive(alert: AlertItem | AlertTableItem['alert']) {
  return alert.state === AlertState.Active;
}

export function isAlertSnoozed(alert: AlertItem | AlertTableItem['alert']): alert is AlertItem & { snoozedUntil: number } {
  return alert.snoozedUntil !== undefined && alert.snoozedUntil > Date.now();
}

export function getAlertStateLabel(alert: AlertItem | AlertTableItem['alert']) {
  if (isAlertDisabled(alert)) {
    return 'Disabled';
  }

  if (isAlertSnoozed(alert)) {
    return 'Snoozed';
  }

  return 'Active';

}

export const getAlertTypeLabel = (alertType: AlertType) => {
  return alertType === AlertType.Target ? 'Target' : 'Volatility';
}

export const isVolatilityAlert = (alert: AlertItem | AlertTableItem['alert']) => alert.type === AlertType.Volatility;
export const isTargetAlert = (alert: AlertItem | AlertTableItem['alert'])=> alert.type === AlertType.Target || alert.type === undefined; // check for undefined for old data (this data is  only in dev so in theory we can remove it by clearing out the objects from dev)

export const getQuantileIndexByPctg = (pctg: number) => Math.floor(pctg / 5) - 1;
export const getQuantileByPctg = (quantiles: number[], pctg: number) => {
  const index = getQuantileIndexByPctg(pctg);
  return quantiles[index];
};

export const canEditAlertsTarget = (alerts: AlertTableItem[], linkedAlerts: Set<string>) => {
  if (linkedAlerts.size < 2) {
    return true;
  }

  const targetTypes = alerts.filter(a => linkedAlerts.has(a.alert.id)).map(a => a.alert.targetType);
  const uniqueTargetTypes = new Set(targetTypes);

  return uniqueTargetTypes.size === 1;
}
