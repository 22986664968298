import { Side } from "@/app/data/api"
import { ProbabilityTable } from "../probabilityTable"
import Modal from "./modal"
import { PriceType } from "@/types/types";

export type DataItem = {
  title: string;
  quantiles: number[] | undefined;
  side: Side;
  priceType: PriceType
}

// Displays quantiles tables in the modal
export const ProbabilityModal = ({
  modalOpen,
  onClose,
  data,
}: {
  modalOpen: boolean,
  onClose: () => void,
  data: DataItem[],
}) => {
  return (
    <Modal
      show={modalOpen}
      size="content"
      onClose={onClose}
      body={
        <div className={'max-w-full  gap-6 flex'}>

          {data.map((item, index) => {

            return (
              <div className="w-full flex flex-col" key={index}>
                <h4 className="mb-4">{item.title}</h4>
                <div className="p-3 bg-[#1F2034] rounded-lg flex-1">
                  <ProbabilityTable
                    quantiles={item.quantiles || []}
                    side={item.side}
                    priceType={item.priceType}
                  />
                </div>
              </div>
            )
          })}
        </div>
      }
    />
  )
}