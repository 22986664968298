import { BondResponse, BondValue } from "@/app/data/bonds";
import { BroadcastChannelName } from "@/constants";
import { useSyncObject } from "@/hooks/data/useSyncObject"
import { getAppState } from "@/store";
import { bondEndpoints, useUpdateBondObjectMutation } from "@/store/api/bond.endpoints";
import { Note } from "@/types/types";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

export const useSyncBondObject = ({
  bondObject,
}: {
  bondObject: BondResponse | undefined;
}) => {
  const dispatch = useDispatch();
  const [updateBondObjectApi] = useUpdateBondObjectMutation();

  function updateStoredQueryData(data: BondValue, objectId: string) {
    const cachedData = bondEndpoints.endpoints.getBondObject.select(objectId)(getAppState());

    if (!cachedData.data) {
      return;
    }

    const d = {
      ...cachedData.data,
      value: {
        ...cachedData?.data?.value,
        ...data,
      }
    }

    const action = bondEndpoints.util.updateQueryData('getBondObject', d.id, () => d);
    dispatch(action as any)
  }

  // generate sync object 
  const { syncObject } = useSyncObject({
    broadcastChannelName: BroadcastChannelName.BondObjectUpdate,
    objectDetails: bondObject,
    updateStoredQueryData,
    updateApi: ({ data, id, version }) => {
      return updateBondObjectApi({
        id,
        version,
        data,
      }).unwrap()
    }
  })

  
  function createNote(text: string) {
    if (!bondObject) {
      return;
    }

    if (isEmpty(text)) {
      return;
    }

    const newNote: Note = {
      id: uuidv4(),
      text,
      createdAt: new Date().toISOString(),
    }

    syncObject({
      ...bondObject.value,
      notes: [
        newNote,
        ...bondObject.value.notes,
      ],
    })
  }

  function updateNote(updatedNote: Partial<Note> & { id: string }) {
    if (!bondObject) {
      return;
    }

    syncObject({
      ...bondObject.value,
      notes: bondObject.value.notes.map((note) => {
        if (note.id === updatedNote.id) {
          return {
            ...note,
            ...updatedNote,
          };
        }

        return note
      })
    })
  }

  function deleteNote(id: string) {
    if (!bondObject) {
      return;
    }

    syncObject({
      ...bondObject.value,
      notes: bondObject.value.notes.filter((note) => note.id !== id)
    })
  }

  return {
    createNote,
    updateNote,
    deleteNote,
  };
}