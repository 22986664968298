import { useState } from "react";
import ActionModal from "./modal/actionModal";
import Modal from "./modal/modal";

export function SAndPCopyright() {
  const [modalOpen, setModalOpen] = useState(false)
  const year = new Date().getFullYear();

  return (
    <>
      <div className="flex justify-center">
        <p className="p-2 pb-4 text-center text-[#8183B3] text-[0.75rem] cursor-pointer hover:text-[#cecfd7]" onClick={() => setModalOpen(true)}>
          Copyright © {year}, S&P Global Market Intelligence (and its affiliates as applicable). All rights reserved.
        </p>
      </div>

      <ActionModal
        size="content"
        panelClassName="max-h-[80vh] overflow-y-auto"
        body={
          <div className="flex flex-col gap-[1.25rem] items-start w-[600px] max-w-full">
            <p className="mb-4">
              Copyright © {year}, S&P Global Market Intelligence (and its affiliates as applicable). Reproduction of
              any information, opinions, views, data or material, including ratings (“Content”) in any form is
              prohibited except with the prior written permission of the relevant party. Such party, its affiliates and
              suppliers (“Content Providers”) do not guarantee the accuracy, adequacy, completeness, timeliness or
              availability of any Content and are not responsible for any errors or omissions (negligent or otherwise),
              regardless of the cause, or for the results obtained from the use of such Content. In no event shall
              Content Providers be liable for any damages, costs, expenses, legal fees, or losses (including lost
              income or lost profit and opportunity costs) in connection with any use of the Content. A reference to a
              01176430.0_V2_USCMDAD12X-001(Master Distribution Agreement_Rev1023)
            </p>

            <p>
              Agreement Generation Date: October 03, 2023 6 of 12
              particular investment or security, a rating or any observation concerning an investment that is part of the
              Content is not a recommendation to buy, sell or hold such investment or security, does not address the
              suitability of an investment or security and should not be relied on as investment advice. Credit ratings
              are statements of opinions and are not statements of fact.
            </p>
          </div>
        }
        show={modalOpen}
        title=" "
        onClose={() => {
          setModalOpen(false)
        }}
        actionName="Close"
        action={() => {
          setModalOpen(false)
        }}
        hideCancel
      />
    </>
  )
}