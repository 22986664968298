import { SAndPRating } from "@/constants"

export const SAndPRatingTooltipContent = () => {
  return (
    <div className="text-[0.75rem] max-w-[16.25rem]">
      {Object.entries(SAndPRating).map(([rating, numericValue]) => {

        return (
          <div key={rating} className="flex items-center justify-between gap-8">
            <div>{rating}</div>
            <div className="text-right">{numericValue}</div>
          </div>
        )
      })}

      <div className="text-[0.875rem] font-medium mt-4 text-[#C9CADE]">
        * bonds with `0` rating are not included in average rating calculation
      </div>
    </div>
  )
}