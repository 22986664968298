import clsx from "clsx";
import deepmmLogoAllWhite from '../../DeepMM_logo_all_white.svg';
import { useState } from "react";
import Loading from "../loading";

export type ErrorLayoutProps = {
  title: string;
  text: string;
  actionText?: string;
  showLogo?: boolean;
  onClick?: () => void;
}

export const ErrorLayout = ({ 
  title, 
  text, 
  actionText, 
  showLogo,
  onClick 
}: ErrorLayoutProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="min-h-[80vh] flex flex-col items-center justify-center text-center p-8">
      {showLogo && <img className="h-[5.52rem] mb-4" alt="" src={deepmmLogoAllWhite} />}
      <h1 className="font-bold text-[3.25rem] mb-2">{title}</h1>
      <div className="font-medium text-[1rem] mb-8" >{text}</div>


      {actionText && (
        <button 
          type="button" 
          disabled={loading}
          onClick={async () => {
            setLoading(true)
            await onClick?.();
            setLoading(false)
          }}
          className={clsx('inline-flex items-center justify-center h-[3rem] min-w-[10rem] relative px-[1.75rem] py-[0.875rem] text-[1.125rem] focus:outline-none focus:ring-offset-0 focus:ring-2  rounded-[0.375rem]', {
            'focus:ring-[#4384C8] bg-[#484A7A] cursor-pointer': true,
          })}
        >
          {loading ? <Loading className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" /> :  actionText}
        </button>
      )}
    </div>
  )
}