import { useSelector } from "react-redux";
import ActionModal from "../modal/actionModal"
import { confirmationDialogSelector } from "@/store/slices/confirmationDialog.slice";
import { closeConfirmationDialog } from "./confirmationDialog.utils";

// Dialog which can be globally invoked to show a warning message. Check `confirmationDialog.utils`. 
// You can also await for the user to confirm action or dismiss the dialog.
export const ConfirmationDialogController = () => {
  const {
    buttonText,
    content,
    loading,
    onButtonClick,
    onCancelClick,
    show,
    title,
    hideCancel,
  } = useSelector(confirmationDialogSelector).config;

  return (
    <ActionModal
      title={title}
      body={content}
      show={show || false}
      onClose={() => {
        closeConfirmationDialog();
      }}
      onCancel={onCancelClick}
      action={onButtonClick!}
      actionName={buttonText!}
      loading={loading}
      hideCancel={hideCancel}
    />
  )
}