import { useCallback } from 'react';
import { AlertTargetType, Side } from './api';
import { isEmpty } from 'lodash';
import { AlertTableItem } from '../alert/alertsTable';
import { isSameAlertConfig, isTargetAlert, isVolatilityAlert } from '../alert/alert.utils';
import { useCreateAlertObjectMutation, useLazyGetAlertObjectListQuery, useLazyGetAlertObjectQuery, useUpdateAlertObjectMutation } from '../../store/api/alert.endpoints';
import { baseApi } from '../../store/api';
import { useDispatch } from 'react-redux';
import { useOpenPage } from '../../hooks/useOpenPage';
import { toast } from 'react-toastify';
import { captureException, captureMessage } from '@sentry/core';
import { ObjectResponse } from './objects';

export type AlertValue = {
  alerts: AlertItem[];
}

export enum AlertState {
  Active = 'active',
  Disabled = 'disabled',
}

export enum AlertType {
  Target = 'target',
  Volatility = 'volatility',
}

type AlertItemBase = {
  id: string;
  figi: string;
  side: Side,
  size: number;

  // state
  state: AlertState;
  snoozedUntil?: number;
  note?: string; // note can be added by the user. By default this property is missing in old data
}

export type VolatilityAlertItemBase = {
  condition: [number, number]; // [max, min] - for example [95, 5] => means that 95 percentile should be lower than 5 percentile of previous day or vice versa
}

export type VolatilityAlertItem = AlertItemBase & {
  type: AlertType.Volatility;
}

export type TargetAlertItemBase = {
  targetType: AlertTargetType;
  targetValue: number | undefined | null;
  minProbabilityPctg: number;
}

export type TargetAlertItem = AlertItemBase & TargetAlertItemBase & {
  type: AlertType.Target;
}

export type AlertItem = AlertItemBase & VolatilityAlertItemBase & TargetAlertItemBase & {
  type: AlertType;
}
// VolatilityAlertItem | TargetAlertItem;

export type AlertResponse = ObjectResponse<AlertValue>

export const useCreateAlerts = () => {
  const openPage = useOpenPage();
  const dispatch = useDispatch();
  const [createAlertObjectApi] = useCreateAlertObjectMutation();
  const [updateAlertApi] = useUpdateAlertObjectMutation();

  const [fetchAlertsApi] = useLazyGetAlertObjectListQuery();
  const [fetchAlertApi] = useLazyGetAlertObjectQuery();


  const createAlerts = useCallback(
    async (alerts: AlertItem[]) => {
      const errorMessage = 'Request failed, please try again';

      if (isEmpty(alerts)) {
        captureMessage('No alerts to create');
        toast.error('No alerts to create')
        return false;
      }

      let alertObject: AlertResponse | null = null;

      try {
        // get latest alert object
        const alertObjects = await fetchAlertsApi().unwrap();
        alertObject = alertObjects ? alertObjects[0] : null;
      } catch (e) {
        captureException(e);
        toast.error(errorMessage);
        return false;
      }

      if (!alertObject) {
        // create empty alert object if alert object doesn't exist
        try {
          alertObject = await createAlertObjectApi('default').unwrap();

          if (!alertObject) {
            captureMessage("Failed to create alert object");
            toast.error(errorMessage);
            return false;
          }
        } catch (e) {
          captureException(e)
          toast.error(errorMessage);
          return false;
        }
      }

      // get alert object with values
      let alertObjectDetails: AlertResponse | null = null;
      try {
        alertObjectDetails = await fetchAlertApi(alertObject.id).unwrap();
      } catch (e) {
        captureException(e)
        toast.error(errorMessage);
      }

      if (!alertObjectDetails) {
        captureMessage('Failed to fetch alert object details');
        toast.error(errorMessage);
        return false;
      }

      // remove duplicate alerts from current alerts data
      const filteredOldAlerts = alertObjectDetails.value.alerts.filter(a => !alerts.some(b => isSameAlertConfig(a, b)));

      const newAlerts = [
        ...alerts,
        ...filteredOldAlerts
      ]

      try {
        const updatedAlert = await updateAlertApi({
          alerts: newAlerts,
          id: alertObjectDetails.id,
          version: alertObjectDetails.version,
          optimistic: false,
        }).unwrap();

        dispatch(baseApi.util.invalidateTags(['AlertObjectList']));

        if (!updatedAlert?.id) {
          throw new Error('Failed to update alert object, object id is missing in response')
        }

        const alert = alerts[0];
        openPage.alerts(alert?.type);
        return true;
      } catch (e) {
        captureException(e)
        toast.error(errorMessage);
        return false;
      }
    }
    , [openPage, createAlertObjectApi, dispatch, updateAlertApi, fetchAlertsApi, fetchAlertApi]);

  return createAlerts;
}



// (this can be more efficient)
export const deepCompareAlertTableItem = (a: AlertTableItem[], b: AlertTableItem[]) => {
  // convert b to object with alert id as key
  const bIdMap = b.reduce((acc, item) => {
    acc[item.alert.id] = item;
    return acc;
  }, {} as Record<string, AlertTableItem>);

  return a.length === b.length &&
    a.every(item => {
      const a1 = item.alert;
      const b1 = bIdMap[a1.id]?.alert;

      if (!b1) {
        return false;
      }

      // check if alert data is the same
      if (isVolatilityAlert(a1) && isVolatilityAlert(b1)) {
        return (
          a1.side === b1.side &&
          a1.size === b1.size
        )
      } else if (isTargetAlert(a1) && isTargetAlert(b1)) {
        return (
          a1.minProbabilityPctg === b1.minProbabilityPctg &&
          a1.side === b1.side &&
          a1.size === b1.size &&
          a1.targetType === b1.targetType &&
          a1.targetValue === b1.targetValue
        );
      } else {
        throw new Error('Invalid alert type');
      }
    })
}