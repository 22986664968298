import { useSelector } from "react-redux";
import { useGetAlertObjectListQuery, useGetAlertObjectQuery } from "../../../store/api/alert.endpoints";
import { selectToken } from "../../../store/slices/auth.slice";

// fetches alert object and alert list data - returns main alert object
export function useAlertObject() {
  const token = useSelector(selectToken);
  const alertListRes = useGetAlertObjectListQuery(undefined, { skip: !token });

  const alertObject = alertListRes.data ? alertListRes.data[0] : null;
  const alertRes = useGetAlertObjectQuery(alertObject?.id as string, {
    skip: !alertObject?.id || !token
  })

  return {
    alertList: alertListRes.data,
    alertListLoading: alertListRes.isFetching,
    refetchAlertList: alertListRes.refetch,

    alertObjectDetails: alertRes.data,
    alertObjectDetailsLoading: alertRes.isFetching,
    refetchAlertObjectDetails: alertRes.refetch,

    loading: alertListRes.isFetching || alertRes.isFetching
  }
}