import { v4 as uuidv4 } from "uuid";

type State = {
  tabId: string | null;
}

export const broadcastState: State = {
  tabId: null,
}

export const createBroadcastChannel = (channelName: string) => {
  if (typeof window !== "undefined" && "BroadcastChannel" in window) {
    if (!broadcastState.tabId) {
      broadcastState.tabId = uuidv4();
    }

    const channel = new BroadcastChannel(channelName);
    const postMessage = channel.postMessage;

    // we need to wrap postMessage to add tabId so we can identify the sender and ignore the message from current tab
    channel.postMessage = (data: unknown) => {
      postMessage.call(channel, {
        tabId: broadcastState.tabId,
        data,
      });
    }

    return channel;
  }
  return null;
}

export const broadcastMessage = (channelName: string, data?: unknown) => {
  const channel = createBroadcastChannel(channelName);

  if (channel) {
    channel.postMessage(data);
    channel.close();
  }
}