import { Inference, Side } from "@/app/data/api";
import { Bond } from "@/app/data/bondIndex";
import { RunBond } from "@/app/data/runs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!../webWorkers/runWorker';
import { useUiMode } from "@/hooks/useUiMode";
import { DataFields } from "@/hooks/data/useSimpleInferenceData";
import { RunWorkerEventType } from "../run.constants";
import { isEmpty } from "lodash";
import { captureException } from "@sentry/react";
import { useLatestRef } from "@/hooks/useLatestRef";
import { usePrevious } from "react-use";

type InferencesMap = {
  [key: string]: Inference;
}

type WebWorkerDataItem = {
  bid: DataFields;
  dealer: DataFields;
  offer: DataFields;
};

export type WebWorkerData = {
  [figi: string]: WebWorkerDataItem
};

export const useWebworkerInferenceCalculation = ({
  inferences,
  items,
  roundPrice,
  runId,
  loading,
}: {
  inferences: InferencesMap
  items: (RunBond & Bond)[]
  roundPrice: boolean
  runId: string | undefined
  loading: boolean
}) => {

  const dataRef = useRef<WebWorkerData>({});
  const loadingRef = useRef(false);
  const calcDataArgsRef = useRef(null);
  // const itemsRef = useLatestRef(items);
  const runIdRef = useLatestRef(runId);

  const prevRunId = usePrevious(runId);
  const itemsRef = useRef(items);
  

  if (prevRunId !== runId) {
    // reset items on runId change
    itemsRef.current = [];
  } else {
    itemsRef.current = items;
  }

  
  
  const [_, rerender] = useState(0);
  const { uiMode } = useUiMode();


  const workerRef = useRef<Worker | null>(null);
  const worker = useMemo(() => {
    // kill previous worker every time runId changes
    if (workerRef.current) {
      workerRef.current.terminate?.()
      loadingRef.current = false
      calcDataArgsRef.current = null;
    }

    return new Worker();
  }, [runId])
  workerRef.current = worker;
  


  useEffect(() => {
    // kill worker on unmount
    return () => {
      worker?.terminate?.();
    }
  }, [])

  useMemo(() => {
    dataRef.current = getDefaultWebWorkerData(items, dataRef.current);
  }, [items])
  
  const calcData  = useCallback(async (data: any) => {
    if (isEmpty(data.inferences) || isEmpty(data.items)) {
      return;
    }

    if (loadingRef.current) {
      calcDataArgsRef.current = data;
      return;
    }

    loadingRef.current = true;

    try {
      // send inferences to web worker
      await sendInferencesToWorker(data.inferences, worker);

      // calculate data
      worker.postMessage({
        type: RunWorkerEventType.CalculateData,
        data: {
          roundPrice: data.roundPrice,
          items: data.items,
          uiMode: data.uiMode,
          runId: data.runId,
        }
      });
    } catch (error) {
      captureException('Run Worker Error ' + error);
    }
  }, [worker])


  useEffect(() => {
    if (!worker) {
      return;
    }

    if (loading) {
      return;
    }


    calcData({
      inferences,
      roundPrice,
      items,
      uiMode,
      runId,
    });

  }, [inferences, worker, roundPrice, items, uiMode, loading])

  useEffect(() => {
    // listen to worker messages
    if (!worker) return;

    let data = {}; // data from worker comes in batches and this variable accumulates it. It's cleared when all data is received `CalcCompleted`
    worker.onmessage = (event) => {
      switch (event.data.type) {
        case RunWorkerEventType.BatchStart: {
          data = {};
          break;
        } 
        case RunWorkerEventType.BatchResult: 
          const eventData = event.data.data as [string, WebWorkerDataItem][];

          data = {
            ...data,
            ...eventData.reduce((acc, [figi, data]) => {
              acc[figi] = data;
              return acc;
            }, {} as WebWorkerData),
          }
          break;
        case RunWorkerEventType.CalcCompleted: {
          if (event.data.runId === runIdRef.current) {
            dataRef.current = data; 
          }
          data =  {}; // clear data for next batch
          rerender(p => p + 1);
          loadingRef.current = false;
          break;
        }
        case RunWorkerEventType.CalcError: {
          captureException('Run Worker Error ' + event.data.error);
          break
        }
        default:
          throw new Error('Unknown worker event type');
      }
    }
  }, [worker])

  const data = dataRef.current;

  const createDataSort = useMemo(() =>
    (side: Side, key: 'price' | 'ytm' | 'gspread', prop: 'current' | 'prev' | 'diff' | 'tenor') =>
      (a: RunBond & Bond, b: RunBond & Bond) => {
        const aVal = data?.[a.figi][side][key][prop];
        const bVal = data?.[b.figi][side][key][prop];
        return aVal === undefined && bVal !== undefined ? -1 :
          aVal !== undefined && bVal === undefined ? 1 :
            aVal === undefined && bVal === undefined ? 0 :
              aVal! - bVal!;
      }
    , [data]);

  return { data, createDataSort }
}

function getDefaultWebWorkerData(items: (RunBond & Bond)[], currentData?: WebWorkerData): WebWorkerData {

  return items.reduce((result, item) => {
    result[item.figi] = {
      bid: {
        price: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.bid?.price
        },
        ytm: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.bid?.ytm
        },
        gspread: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.bid?.gspread
        },
      },
      dealer: {
        price: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.dealer?.price
        },
        ytm: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.dealer?.ytm
        },
        gspread: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.dealer?.gspread
        },
      },
      offer: {
        price: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.offer?.price
        },
        ytm: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.offer?.ytm
        },
        gspread: {
          current: undefined,
          currentString: '-',
          prev: undefined,
          prevString: '-',
          diff: undefined,
          diffString: '-',
          currentTime: undefined,
          prevTime: undefined,
          data: undefined,
          ...currentData?.[item.figi]?.offer?.gspread
        }
      }
    }

    return result;
  }, {} as WebWorkerData)
}

const sendInferencesToWorker = async (inferences: { [key: string]: Inference }, worker: Worker) => {
  const inferencesArray = Object.entries(inferences);
  const batchSize = 500;
  const numBatches = Math.ceil(inferencesArray.length / batchSize);

  for (let i = 0; i < numBatches; i++) {
    const start = i * batchSize;
    const end = start + batchSize;
    const batch = inferencesArray.slice(start, end);

    // await new Promise((resolve) => setTimeout(resolve, 10)); // Delay 10ms

    worker.postMessage({
      type: RunWorkerEventType.AddInferences,
      data: batch,
    });
  }
};