import { CircleButton } from "@/app/components/buttons/CircleButton"
import ActionModal from "@/app/components/modal/actionModal";
import { NAV_FORMAT } from "@/constants";
import { numericFormatter } from "@/utils/number.utils";
import { PencilIcon, PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import clsx from "clsx";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

export const EditPortfolioNAV = ({
  nav,
  onNavChange,
  isFetchingPortfolio,
}: {
  nav: number | null | undefined
  onNavChange: (nav: number | undefined) => void;
  isFetchingPortfolio?: boolean;
}) => {
  const [modalOpen, setEditModalOpen] = useState(false)
  const [localNav, setLocalNav] = useState(nav)

  useEffect(() => {
    setLocalNav(nav)
  }, [nav])

  const hasNav = typeof nav === 'number';

  if (isFetchingPortfolio) {
    return '-'
  }

  return (
    <div>
      {/* Show add NAV button if NAV is missing */}
      {!hasNav && (
        <CircleButton onClick={() => setEditModalOpen(true)} p="p-0" size="h-6 w-6 mt-0.5">
          <PlusIcon width={16} />
        </CircleButton>
      )}

      {hasNav && (
        <span className="flex items-center gap-1">   
          {numericFormatter(nav, NAV_FORMAT)}

          <CircleButton onClick={() => setEditModalOpen(true)} p="p-0" size="h-7 w-7 mt-0.5">
            <PencilSquareIcon width={16} />
          </CircleButton>
        </span>
      )}

      <ActionModal
        action={() => {
          let navValue = localNav ?? undefined
          if (typeof navValue === 'number' && navValue <= 0) {
            navValue = undefined
          }
          onNavChange(localNav ?? undefined)
        }}
        actionName='Save'
        onClose={() => setEditModalOpen(false)}
        show={modalOpen}
        title='Edit NAV'
        body={(
          <NumericFormat
            {...NAV_FORMAT}
            allowNegative={false}
            value={localNav}
            onValueChange={(e) => setLocalNav(e.floatValue)}
            className={clsx("bg-[#5D5F9D] border-0 px-[1.25rem] py-[0.625rem]",
              "ring-0 rounded-full",
              "text-[1.25rem] text-[#FBFBFD] w-full",
              "focus:ring-2 focus:ring-inset focus:ring-[#4384C8] placeholder:text-[#C9CADE]/[0.5]")}
          />
        )}
      />
    </div>
  )
}