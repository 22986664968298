import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Bond } from '../data/dataProvider';
import { Column, Sort, TableProps, TableSortIcon } from '../components/table/table';
import { AlertItem, AlertType, TargetAlertItem, VolatilityAlertItem } from '../data/alerts';
import { AlertColumn, AlertColumnFilterLabel, AlertColumnTitle, SEARCH_PARAM_NAME, TargetAlertsColumnsOrderConfig, VolatilityAlertsColumnsOrderConfig, targetAlertsColumnsMap, volatilityAlertsColumnsMap } from './alert.constants';

import clsx from 'clsx';
import { canEditAlertsTarget, isAlertDisabled, isAlertSnoozed, isTargetAlert, isVolatilityAlert, stopPropagation } from './alert.utils';
import ActionModal from '../components/modal/actionModal';
import { AlertTargetInput } from './alertTargetInput';
import { AlertInferenceData, ConditionData } from './alert.types';
import './alertsTable.css';
import { AlertHeaderCell } from '@components/data/alertHeaderCell';
import { AlertBellCell } from './table/alertBellCell';
import { getLinkColumnConfig } from '../components/data/table.utils';
import { AlertCard } from './alertCard/alertCard';
import { AlertTargetTypeDropdown } from './dropdowns/alertTargetTypeDropdown';
import { AlertSizeDropdown } from './dropdowns/alertSizeDropdown';
import { AlertSideDropdown } from './dropdowns/alertSideDropdown';
import { MinProbabilityDropdown } from './dropdowns/minProbabilityDropdown';
import { VolatilityConditionDropdown } from './dropdowns/volatilityConditionDropdown';
import { useDesktopDown } from '@hooks/useBreakpoints';
import { FormatNumber } from '../components/formatNumber/formatNumber';
import { TableWithUserPreferences } from '../components/table/tableWithUserPreferences';
import { TableName } from '@/types/types';
import { isEmpty } from 'lodash';
import { createNumberSort, createStringSort } from '@/utils/sort.utils';
import { formatAmountOutstanding } from '@/utils/number.utils';
import { AlertTargetType } from '../data/api';
import { AlertNote } from './alertNote/alertNote';

// export type AlertTableItem = AlertItem & Bond;
export type VolatilityAlertTableItem = {
  alert: VolatilityAlertItem;
  bond: Bond
  disabled?: boolean; // true when user tries to add more than MAX_ALERTS
  targetInvalid?: boolean; // true when target value is invalid
}

export type TargetAlertTableItem = {
  alert: TargetAlertItem;
  bond: Bond
  disabled?: boolean; // true when user tries to add more than MAX_ALERTS
  targetInvalid?: boolean; // true when target value is invalid
}

export type AlertTableItem = {
  alert: AlertItem;
  bond: Bond
  disabled?: boolean; // true when user tries to add more than MAX_ALERTS
  targetInvalid?: boolean; // true when target value is invalid
}


const NO_ALERTS_MESSAGE = 'No alerts found matching filters criteria';

export const getTdCssFunc = (rowHasError?: (item: AlertTableItem) => void, alwaysEnabled?: boolean) => (selected: boolean, { item }: { item: AlertTableItem }) => clsx(
  'relative px-[0.3125rem] first:rounded-l-[0.3125rem] last:rounded-r-[0.3125rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]',
  {
    'bg-[#5D5F9D77]': selected,
    'opacity-50 pointer-events-none': item.disabled && !alwaysEnabled,

    // invalid css styles. Rounded corners doesn't work for collapsed table so we use before element instead 
    [`
    before:pointer-events-none

    before:absolute 
    before:left-0 
    before:top-0 
    before:right-0 
    before:bottom-0 
    
    before:border-t-[2px] 
    before:border-b-[2px] 
    before:border-t-[#FB275a] 
    before:border-b-[#FB275a]

    first:before:border-l-[2px]
    first:before:border-l-[#FB275a]
    first:before:rounded-tl-[0.3125rem]
    first:before:rounded-bl-[0.3125rem]

    last:before:border-r-[2px]
    last:before:border-r-[#FB275a]
    last:before:rounded-tr-[0.3125rem]
    last:before:rounded-br-[0.3125rem]
  `]: rowHasError?.(item)
  })


export const header = (name: ReactNode, colClassName?: string) => (sort?: Sort) => {
  return (
    <div className="h-full px-[0.3125rem] w-full">
      <div className={clsx(colClassName, 'flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-[#EDEDF4] text-start')}>
        <div className="gap-[0.4375rem] grid grid-cols-[1fr,auto] place-items-center">
          <div className="font-[500] min-h-[3.9375rem] flex items-center">{name}</div>
          <TableSortIcon sort={sort} />
        </div>
      </div>
    </div>
  )
}

const coloredHeader = (name: ReactNode) => header(name, 'bg-[#484A7A]');
const blackHeader = (name: ReactNode) => header(name, 'bg-[#0A0B11]');
const blackCell = (contents: ReactNode, selected: boolean) => cell(contents, selected, { color: 'bg-[#0A0B11]' });

export const cell = (contents: ReactNode, selected: boolean, bgConfig?: { color: string, keepBgOnHover?: boolean }, className?: string) => {

  return (
    <div
      className={clsx('group/cell flex flex-col h-full justify-center px-[0.625rem] py-[1.25rem] min-h-[5.125rem] text-[0.875rem] font-[500] whitespace-nowrap', className, {
        [`${bgConfig?.color}`]: bgConfig && !selected,
        'group-hover:bg-[transparent]': bgConfig && !selected && !bgConfig?.keepBgOnHover,
      })}
    >
      {contents}
    </div>
  )
}

const coloredCell = (contents: ReactNode, selected: boolean) => cell(contents, selected, { color: 'bg-[#484A7A]' });

const getColumnTitle = (column: AlertColumn) => AlertColumnTitle[column];
const getColumnFilterLabel = (column: AlertColumn) => AlertColumnFilterLabel[column];
const gct = getColumnTitle; // just short name as we use it in multiple places

type AlertTableProps = {
  alerts: AlertTableItem[],
  alertType: AlertType;
  configureColumns?: (column: Column<AlertTableItem, AlertColumn>[]) => Column<AlertTableItem, AlertColumn>[]; // used to filter out columns or add other columns
  data: AlertInferenceData;
  isRowHighlighted?: (item: AlertTableItem) => boolean;
  linkedAlerts: Set<string>;
  onAlertChange: (alert: Partial<AlertItem> & { id: string }) => void
  onDelete: (id: string) => Promise<void>;
  onRowClick?: ((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: AlertTableItem) => void) | undefined;
  rowHasError?: (item: AlertTableItem) => boolean;
  scrollerCss?: string;
  setLinkedAlerts: (s: Set<string>) => void;
  tableRef?: TableProps<AlertTableItem, AlertColumn>['tableRef'];
  useSearchPagination?: boolean;
}

// <AlertTable />
const AlertTable = ({
  alerts,
  alertType,
  configureColumns,
  data,
  isRowHighlighted,
  linkedAlerts,
  onAlertChange,
  onDelete,
  onRowClick,
  rowHasError,
  scrollerCss,
  setLinkedAlerts,
  tableRef,
  useSearchPagination = true,
}: AlertTableProps) => {
  const isSmallDevice = useDesktopDown();
  const [searchParams] = useSearchParams();
  const searchStr = searchParams.get(SEARCH_PARAM_NAME)
  const [alertIdToDelete, setAlertIdToDelete] = useState<null | string>(null);
  const alertToDelete = alerts.find(a => a.alert.id === alertIdToDelete);
  const columnsOrderConfig = alertType === AlertType.Target ? TargetAlertsColumnsOrderConfig : VolatilityAlertsColumnsOrderConfig;
  const preferenceTableName: TableName = alertType === AlertType.Target ? 'targetAlert' : 'volatilityAlert';


  // object { figi: boolean } - true if user can switch between sides
  const sideAvailabilityMap = useMemo(() => {
    return alerts.reduce((acc, a) => {
      if (!acc[a.alert.figi]) {
        // we have only one alert for this figi - user can switch between sides
        acc[a.alert.figi] = true;
      } else {
        // it's the second alert for this figi - we have both sides already
        acc[a.alert.figi] = false;
      }
      return acc;
    }, {} as Record<string, boolean>)
  }, [alerts])

  const sortConditionData = useCallback((key: keyof ConditionData) => (a: AlertTableItem, b: AlertTableItem) => {
    const aValue = (data.alert[a.alert.id].conditionData?.[key] || 0);
    const bValue = (data.alert[b.alert.id].conditionData?.[key] || 0);
    return aValue - bValue;
  }, [data])

  const canChangeSide = useCallback((alert: AlertTableItem['alert']) => {
    return sideAvailabilityMap[alert.figi];
  }, [sideAvailabilityMap])


  const isTargetReached = useCallback((alert: AlertTableItem['alert']) => {
    return data.alert[alert.id].targetReached;
  }, [data])


  const columns: Column<AlertTableItem, AlertColumn>[] = useMemo(() => {
    if (isSmallDevice) {
      return [];
    }

    const canEditTargetValue = canEditAlertsTarget(alerts, linkedAlerts);

    let c: Column<AlertTableItem, AlertColumn>[] = [
      {
        ...getLinkColumnConfig({
          cell,
          header: (t, s) => header(t)(s),
          items: alerts,
          selectedItems: linkedAlerts,
          setSelectedItems: setLinkedAlerts,
          getKey: (i) => i.alert.id,
        }),
        id: AlertColumn.Link,
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.BellIcon,
        draggable: false,
        Cell: ({ bond, alert }, { selected }) => {
          return cell(
            <AlertBellCell
              alert={alert}
              onStateChange={state => onAlertChange({ id: alert.id, state, snoozedUntil: undefined })}
            />,
            selected
          )
        },
        Header: header(<AlertHeaderCell />),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
        sort: (a, b) => {
          const getVlaue = (a: AlertTableItem['alert']) => {
            if (data.alert[a.id].targetReached) {
              return !isAlertSnoozed(a) ? 4 : 2
            }
            return isAlertDisabled(a) ? 0 : isAlertSnoozed(a) ? 1 : 3;
          };
          return getVlaue(a.alert) - getVlaue(b.alert);
        },
      },
      {
        id: AlertColumn.Cusip,
        Cell: ({ bond }, { selected }) => cell(bond['cusip'], selected),
        Header: header(gct(AlertColumn.Cusip)),
        sort: createStringSort('bond.cusip'),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.Ticker,
        Cell: ({ bond }, { selected }) => cell(bond.ticker, selected),
        Header: header(gct(AlertColumn.Ticker)),
        sort: createStringSort('bond.ticker'),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.Coupon,
        Cell: ({ bond }, { selected }) => cell(`${bond['coupon'].toFixed(3)}%`, selected),
        Header: header(gct(AlertColumn.Coupon)),
        sort: createNumberSort('bond.coupon'),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.Maturity,
        Cell: ({ bond }, { selected }) => cell(bond.maturity, selected),
        Header: header(gct(AlertColumn.Maturity)),
        sort: (a, b) => (new Date(a.bond.maturity)).getTime() - (new Date(b.bond.maturity)).getTime(),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.SAndPRating,
        Cell: ({ bond }, { selected }) => cell(bond.rating || '-', selected),
        Header: header(gct(AlertColumn.SAndPRating)),
        sort: createStringSort('bond.rating'),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.Tenor,
        getValue: (a) => {
          const tenor = data.alert[a.alert.id].tenor
          return typeof tenor === 'number' ? `${tenor}Y` : '-';
        },
        Cell: (a, { selected, value }) => cell(value, selected),
        Header: header(gct(AlertColumn.Tenor)),
        sort: (a, b) => createNumberSort('tenor')(data.alert[a.alert.id], data.alert[b.alert.id]),
        tdCss: getTdCssFunc(rowHasError)
      },
      {
        id: AlertColumn.Series,
        Cell: ({ bond }, { selected }) => cell(bond.series || '-', selected),
        Header: header(gct(AlertColumn.Series)),
        sort: createStringSort('bond.series'),
        tdCss: getTdCssFunc(rowHasError),
      },

      {
        id: AlertColumn.AmountOutstanding,
        Cell: ({ bond }, { selected }) => cell(formatAmountOutstanding(bond.amountOutstanding), selected),
        Header: header(gct(AlertColumn.AmountOutstanding)),
        sort: createNumberSort('bond.amountOutstanding'),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.Size,
        Cell: ({ alert }, { selected }) => coloredCell(
          <div className="min-w-[85px]">
            <AlertSizeDropdown alert={alert} onChange={onAlertChange} />
          </div>,
          selected
        ),
        Header: coloredHeader(gct(AlertColumn.Size)),
        sort: createNumberSort('alert.size'),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },
      {
        id: AlertColumn.Side,
        Cell: ({ alert }, { selected }) => coloredCell(
          <div className="min-w-[3.875rem]">
            <AlertSideDropdown
              alert={alert}
              onChange={onAlertChange}
              canChangeSide={canChangeSide}
            />
          </div>,
          selected
        ),
        Header: coloredHeader(gct(AlertColumn.Side)),
        sort: createStringSort('alert.side'),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },
      {
        id: AlertColumn.TargetType,
        Cell: ({ alert }, { selected }) => coloredCell(
          <AlertTargetTypeDropdown alert={alert} onChange={onAlertChange} />,
          selected
        ),
        Header: coloredHeader(gct(AlertColumn.TargetType)),
        sort: createStringSort('alert.targetType'),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },
      {
        id: AlertColumn.CurrentLevel,
        Cell: ({ alert }, { selected }) => {
          const type = alert.targetType === AlertTargetType.gspread ? 'gspread_decimals' : alert.targetType
          return blackCell(
            <FormatNumber type={type} value={data.alert[alert.id].currentStr} className="min-w-[4.875rem]" />,
            selected,
          )
        },
        Header: blackHeader(gct(AlertColumn.CurrentLevel)),
        sort: (a, b) => createNumberSort('current')(data.alert[a.alert.id], data.alert[b.alert.id]),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.TargetValue,
        Cell: (alert, { selected }) => {
          const disabled = linkedAlerts.has(alert.alert.id) && !canEditTargetValue;
          const disabledReason = disabled ? (
            <>I'ts not possible to edit target value for alerts <br /> with different target type</>
          ) : undefined

          return coloredCell(
            <AlertTargetInput
              key={alert.alert.targetType} // re-render input when target type changes
              alert={alert}
              onChange={onAlertChange}
              disabled={disabled}
              disabledReason={disabledReason}
            />,
            selected
          )
        },
        Header: coloredHeader(gct(AlertColumn.TargetValue)),
        sort: createNumberSort('alert.targetValue'),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },

      {
        id: AlertColumn.Distance,
        Cell: ({ alert }, { selected }) => {
          const d = data.alert[alert.id]
          const isNegative = typeof d.distance === 'number' && d.distance < 0 
          const value = typeof d.distance === 'number' ? Math.abs(d.distance) : d.distance;
          const formatted = <FormatNumber type={alert.targetType} value={value}/>;
          const formattedWithParentheses = isNegative ? <div>({formatted})</div> : formatted;

          return cell(formattedWithParentheses, selected, { color: d.distanceBgColor })
        },
        Header: coloredHeader(gct(AlertColumn.Distance)),
        sort: (a, b) => createNumberSort('distance')(data.alert[a.alert.id], data.alert[b.alert.id]),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.MinProbabilityPctg,
        Cell: ({ alert }, { selected }) => coloredCell(
          <MinProbabilityDropdown alert={alert} onChange={onAlertChange} />,
          selected
        ),
        Header: coloredHeader(gct(AlertColumn.MinProbabilityPctg)),
        sort: createNumberSort('alert.minProbabilityPctg'),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },
      {
        id: AlertColumn.CurrentProbability,
        Cell: ({ alert }, { selected }) => {
          const v = data.alert[alert.id];
          return cell(
            v.currentPctgRange,
            selected,
            { color: v.pctgBgColor }
          )
        },
        Header: coloredHeader(gct(AlertColumn.CurrentProbability)),
        sort: (a, b) => createNumberSort('currentPctg')(data.alert[a.alert.id], data.alert[b.alert.id]),
        tdCss: getTdCssFunc(rowHasError),
      },
      {
        id: AlertColumn.VolatilityCondition,
        Cell: ({ alert }, { selected }) => coloredCell(
          <div className="min-w-[3.875rem]">
            <VolatilityConditionDropdown
              alert={alert}
              onChange={onAlertChange}
            />
          </div>,
          selected
        ),
        Header: coloredHeader(gct(AlertColumn.VolatilityCondition)),
        // sort: createStringSort('alert.side'),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },
      {
        id: AlertColumn.VolatilityPrevMin,
        Cell: ({ alert }, { selected }) => coloredCell(<FormatNumber type={alert.targetType} value={data.alert[alert.id].conditionData.prevMin} />, selected),
        Header: coloredHeader(gct(AlertColumn.VolatilityPrevMin)),
        tdCss: getTdCssFunc(rowHasError),
        sort: sortConditionData('prevMin')
      },
      {
        id: AlertColumn.VolatilityPrevMax,
        Cell: ({ alert }, { selected }) => coloredCell(<FormatNumber type={alert.targetType} value={data.alert[alert.id].conditionData.prevMax} />, selected),
        Header: coloredHeader(gct(AlertColumn.VolatilityPrevMax)),
        tdCss: getTdCssFunc(rowHasError),
        sort: sortConditionData('prevMax')
      },
      {
        id: AlertColumn.VolatilityTodayMin,
        Cell: ({ alert }, { selected }) => blackCell(
          <FormatNumber type={alert.targetType} value={data.alert[alert.id].conditionData.todayMin} />,
          selected,
        ),
        Header: blackHeader(gct(AlertColumn.VolatilityTodayMin)),
        tdCss: getTdCssFunc(rowHasError),
        sort: sortConditionData('todayMin'),
      },
      {
        id: AlertColumn.VolatilityTodayMax,
        Cell: ({ alert }, { selected }) => blackCell(<FormatNumber type={alert.targetType} value={data.alert[alert.id].conditionData.todayMax} />, selected),
        Header: blackHeader(gct(AlertColumn.VolatilityTodayMax)),
        tdCss: getTdCssFunc(rowHasError),
        sort: sortConditionData('todayMax'),
      },

      {
        id: AlertColumn.Note,
        Cell: ({ alert }, { selected }) => cell(
          <AlertNote
            note={alert.note}
            onChange={(note: string) => {
              onAlertChange({ id: alert.id, note: note })
            }}
          />,
          selected,
        ),
        Header: header(gct(AlertColumn.Note)),
        tdCss: getTdCssFunc(rowHasError),
        onCellClick: stopPropagation,
      },
      {
        id: AlertColumn.Remove,
        draggable: false,
        Cell: (b, { selected }) => cell(
          <button
            type="button"
            // onClick handled by `onCellClick`
            className="bg-transparent h-[1.5rem] flex flex-col items-center justify-center rounded-full w-[1.5rem]"
          >
            <XMarkIcon className="w-[1.25rem] text-[#8183B3] group-hover:text-current group-hover/cell:scale-125" />
          </button>,
          selected,
          undefined,
          'hover:cursor-pointer',
        ),
        Header: header(gct(AlertColumn.Remove)),
        onCellClick: (e, item) => {
          e.stopPropagation();
          setAlertIdToDelete(item.alert.id);
        },
        tdCss: getTdCssFunc(rowHasError, true),
      }
    ]

    if (alertType === AlertType.Volatility) {
      c = c.filter(c => volatilityAlertsColumnsMap[c.id])
    } else {
      c = c.filter(c => targetAlertsColumnsMap[c.id])
    }

    return configureColumns ? configureColumns(c) : c;
  },
    [data, rowHasError, canChangeSide, isTargetReached, configureColumns, onAlertChange, alertType, linkedAlerts, isSmallDevice]
  );

  const trCss = useCallback(
    (item: AlertTableItem) => {
      const isLinked = linkedAlerts.has(item.alert.id);
      const targetReached = isTargetReached(item.alert);
      const isTarget = isTargetAlert(item.alert);
      const isVolatility = isVolatilityAlert(item.alert);

      return clsx('rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]', {
        'bg-gradient-to-r from-[#7fb24799] to-[#484a7a00] to-[50%]': targetReached && isTarget && !isLinked, // green gradient
        'bg-gradient-to-r from-[#ff070799] to-[#484a7a00] to-[50%]': targetReached && isVolatility && !isLinked, // red gradient
        'alert-animate-shadow-pulse': isRowHighlighted?.(item),
        'bg-gradient-to-r from-[#5D5F9D77]': isLinked,
      })
    }
    , [isTargetReached, isRowHighlighted, linkedAlerts])

  return (
    <>
      {/* Table */}
      {!isSmallDevice && (
        <TableWithUserPreferences
          columns={columns}
          generateItemKey={b => b.alert.id}
          initialSortColumn={AlertColumn.Maturity}
          initialSortDirection='ascending'
          items={alerts}
          tableName='a'
          theadCss='bg-[#333557] sticky top-0 z-[2]'
          trCss={trCss}
          scrollerCss={scrollerCss}
          tableCss='text-[#FBFBFD]'
          onRowClick={onRowClick}
          tableRef={tableRef}
          getColumnFilterLabel={getColumnFilterLabel}
          columnsOrderConfig={columnsOrderConfig}
          preferenceTableName={preferenceTableName}
          preferenceKey='default'
          noDataMessage={NO_ALERTS_MESSAGE}
          useSearchPagination={useSearchPagination}
        />
      )}

      {/* Mobile alert cards */}
      {isSmallDevice && (
        <div className="flex flex-row gap-[0.75rem] items-stretch overflow-x-auto pb-[1rem] px-[0.625rem] w-full">
          {alerts.map((a) => {
            return (
              <AlertCard
                key={a.alert.id}
                alertItem={a}
                canChangeSide={canChangeSide}
                onDelete={a => setAlertIdToDelete(a.alert.id)}
                onChange={onAlertChange}
                data={data}
              />
            )
          })}

          {isEmpty(alerts) && <div className="text-[#8183B3]">{NO_ALERTS_MESSAGE}</div>}
        </div>
      )}

      {/* Modals */}
      <ActionModal
        action={() => onDelete(alertIdToDelete!)}
        actionName='Delete'
        body={
          <div className="">
            {linkedAlerts.has(alertIdToDelete || '') ? (
              <>
                Delete <span className="text-[#FBFBFD]">{linkedAlerts.size}</span> alert{linkedAlerts.size > 1 ? 's' : ''}?
              </>
            ) : (
              <>
                Delete <span className="text-[#FBFBFD] truncate">{alertToDelete?.bond.ticker} {alertToDelete?.alert.side}</span> alert?
              </>
            )}
          </div>
        }
        onClose={() => setAlertIdToDelete(null)}
        show={Boolean(alertIdToDelete)}
      />
    </>
  );
}

export default AlertTable;
