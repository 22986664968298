import { ReactNode, memo } from "react"
import { Bond } from "@data/bondIndex"
import { Option, useFilters } from "@components/filters/hooks/useFilters";
import { FaXmark } from "react-icons/fa6";

export const SelectedFilters = memo(({
  bonds,
}: {
  bonds: Bond[];
}) => {
  const {
    tickerFilter,
    setTickerFilter,
    tenorFilter,
    setTenorFilter,
    couponFilter,
    setCouponFilter,
    seriesFilter,
    setSeriesFilter,
    ratingFilter,
    setRatingFilter,
    bidFilter,
    setBidFilter,
    offerFilter,
    setOfferFilter,
    bidGspreadFilter,
    setBidGspreadFilter,
    offerGspreadFilter,
    setOfferGspreadFilter,
    bidYtmFilter,
    setBidYtmFilter,
    offerYtmFilter,
    setOfferYtmFilter,
    previousMs,
    setPreviousMs,
    getFormattedPreviousDate,
  } = useFilters(bonds);


  return (
    <div className="flex flex-row gap-[0.625rem] items-center flex-wrap">
      {/* Ticker */}
      <FilterList  filterValues={tickerFilter} setFilter={setTickerFilter} />

      {/* Tenor */}
      <FilterList  filterValues={tenorFilter} setFilter={setTenorFilter} />

      {/* Coupon */}
      <FilterList  filterValues={couponFilter} setFilter={setCouponFilter} />

      {/* Series */}
      <FilterList  filterValues={seriesFilter} setFilter={setSeriesFilter} />

      {/* Rating */}
      <FilterList  filterValues={ratingFilter} setFilter={setRatingFilter} />

      {/* Bid */}
      <FilterList  filterValues={bidFilter} setFilter={setBidFilter} />

      {/* Offer */}
      <FilterList  filterValues={offerFilter} setFilter={setOfferFilter} />

      {/* Bid Gspread */}
      <FilterList  filterValues={bidGspreadFilter} setFilter={setBidGspreadFilter} />

      {/* Offer Gspread */}
      <FilterList  filterValues={offerGspreadFilter} setFilter={setOfferGspreadFilter} />

      {/* Bid YTM */}
      <FilterList  filterValues={bidYtmFilter} setFilter={setBidYtmFilter} />

      {/* Offer YTM */}
      <FilterList  filterValues={offerYtmFilter} setFilter={setOfferYtmFilter} />


      {/* Pevious Date */}
      {previousMs && (
        <FilterItem onClick={() => setPreviousMs(null)}>
          {getFormattedPreviousDate()}
        </FilterItem>
      )}

    </div>
  )
})

// <FilterList />
const FilterList = <T,>({
  filterValues,
  setFilter,
} : {
  filterValues: Option<T>[];
  setFilter: (values: Option<T>[]) => void;
}) => {
  return filterValues.map((f) => {
    if (!f) {
      return null;
    }

    return (
      <FilterItem 
        key={f.label} 
        onClick={() => {
          setFilter(filterValues.filter(t => t !== f))
        }}
      >
        {f.label}
      </FilterItem>
    )
  })
}


// <FilterItem />
const FilterItem = ({
  children,
  onClick,
}: {
  children: ReactNode,
  onClick: () => void,
}) => {
  return (
    <div className="bg-[#484A7A] flex items-center pl-[1.25rem] rounded-full text-[#FBFBFD]">
      <span className="text-[0.875rem]">
        {children}
      </span>
      <button
        className="flex h-[1.75rem] items-center justify-center m-[0.25rem] rounded-full w-[1.75rem] cursor-pointer"
        onClick={onClick}
      >
        <FaXmark className="text-[1rem]" />
      </button>
    </div>
  )
}