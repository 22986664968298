import { PortfolioResponse, PortfolioValue, Position } from "@/app/data/portfolios";
import { BroadcastChannelName } from "@/constants";
import { useSyncObject } from "@/hooks/data/useSyncObject"
import { getAppState } from "@/store";
import { portfolioEndpoints, useUpdatePortfolioObjectMutation } from "@/store/api/portfolio.endpoints";
import { useDispatch } from "react-redux";

export const useSyncPortfolioObject = ({
  portfolioObject,
}: {
  portfolioObject: PortfolioResponse | undefined;
}) => {
  const dispatch = useDispatch();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  function updateStoredQueryData(data: PortfolioValue, objectId: string) {
    const cachedData = portfolioEndpoints.endpoints.getPortfolio.select(objectId)(getAppState());

    if (!cachedData.data) {
      return;
    }

    const d = {
      ...cachedData.data,
      value: {
        ...cachedData?.data?.value,
        ...data,
      }
    }

    const action = portfolioEndpoints.util.updateQueryData('getPortfolio', d.id, () => d);
    dispatch(action as any)
  }

  // generate sync object 
  const { syncObject } = useSyncObject({
    broadcastChannelName: BroadcastChannelName.PortfolioUpdate,
    objectDetails: portfolioObject,
    updateStoredQueryData,
    updateApi: ({ data, id, version }) => {
      return updatePortfolioApi({
        id,
        version,
        data,
      }).unwrap()
    }
  })

  
  function syncPositions(positions: Position[]) {
    if (!portfolioObject) {
      return;
    }

    syncObject({
      ...portfolioObject.value,
      positions,
    })
  }

  function syncNav(nav: number | null | undefined) {
    if (!portfolioObject) {
      return;
    }

    syncObject({
      ...portfolioObject.value,
      nav: nav ?? null,
    })
  }

  return {
    syncPositions,
    syncNav,
  };
}