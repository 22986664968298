import React, { createContext, useEffect, useMemo } from 'react';
import { createHashRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, useLocation, useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import App from './app';
import SearchResults from './app/searchResults';
import Bond from './app/bond';
import Issuer from './app/issuer';
import Portfolio from './app/portfolio';
import Run from './app/run';
import Alert from './app/alert';
import { Provider as ReduxProvider } from "react-redux";
import store from './store';
import 'react-toastify/dist/ReactToastify.css';
import { RefreshToken } from './app/auth/refreshToken';
import { AlertType } from './app/data/alerts';
import { RouteErrorBoundary } from './app/errors/routeErrorBoundary';
import { UiAccessGuard } from './app/components/uiAccessGuard';

declare global {
  interface Window {
    gtag: (command: 'event', eventType: 'page_view', config: { page_path: string }) => void
  }
}


export const ConfigContext = createContext({
  serverList: ['wss://staging1.deepmm.com'],
  api: 'https://sebybpenwb.execute-api.us-west-2.amazonaws.com/Dev'
});

const PageViews = () => {
  const location = useLocation();
  const fullPath = location.pathname + location.search + location.hash;
  useEffect(() => {
    window.gtag('event', 'page_view', { page_path: fullPath })
  }, [fullPath]);
  return <Outlet />;
}


const AuthenticationWrapper: React.FunctionComponent<{api: string, serverList: string[], cognitoConfig: { region: string, userPoolId: string, userPoolWebClientId: string }, children?: React.ReactNode }> = ({ api, serverList, cognitoConfig: { region, userPoolId, userPoolWebClientId }, children }) => {

  useEffect(() => {
    Amplify.configure({ Auth: { region, userPoolId, userPoolWebClientId } });
  }, [region, userPoolId, userPoolWebClientId])

  const { port } = useParams();

  const serverListPlusPort = useMemo(() => !port ? [...serverList] : serverList.map(s => s + '/' + port), [serverList, port]);

  return (
    <ConfigContext.Provider value={{ api, serverList: serverListPlusPort }}>
      <Authenticator loginMechanisms={['email']} hideSignUp={true}>
        <Authenticator.Provider>
          <RefreshToken>
            <UiAccessGuard>
              {children}
            </UiAccessGuard>
          </RefreshToken>
        </Authenticator.Provider>
      </Authenticator>
    </ConfigContext.Provider>
  );
}

const DeepMMDev = () =>
  <AuthenticationWrapper
    api='https://sebybpenwb.execute-api.us-west-2.amazonaws.com/Dev'
    serverList={['wss://staging1.deepmm.com']}
    cognitoConfig={{
      region: 'us-west-2',
      userPoolId: 'us-west-2_m0oZvB6ph',
      userPoolWebClientId: '1hpqr0c8pbiiufsb8n95414jjh'
    }}
  >
    <Outlet />
  </AuthenticationWrapper>

const DeepMMProd = () =>
  <AuthenticationWrapper
    api='https://f8anuvm0b5.execute-api.us-east-1.amazonaws.com/Prod'
    serverList={['wss://deist.deepmm.com','wss://hayek.deepmm.com']}
    cognitoConfig={{
      region: 'us-east-1',
      userPoolId: 'us-east-1_Aks49XkeO',
      userPoolWebClientId: '3lutmljf959sa3p19ac89uvno3'
    }}
  >
    <Outlet />
  </AuthenticationWrapper>

const ExternalRedirect: React.FC<{ to: string}> = ({ to }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return <></>;
};

const createDeepMMRoutes = () => (
  <Route element={<App />} >
    <Route errorElement={<RouteErrorBoundary />}>
      <Route path='bond/:figi?' element={<Bond />}/>
      <Route path='issuer/:ticker?' element={<Issuer />}/>
      <Route path='search/:query?' element={<SearchResults />}/>
      <Route path='portfolio/:portfolio?' element={<Portfolio />}/>
      <Route path="alert" >
        <Route path=':alertType' element={<Alert />}/>
        <Route path='*' index element={<Navigate to={AlertType.Target}  replace/>}/>
      </Route>
      <Route path='run/:run?' element={<Run />}/>
      <Route path='*' element={<Navigate to='search/' replace/>}/>
    </Route>
  </Route>
);

// use createHashRouter so app can run without any SPA routing configuration on the server
const router = createHashRouter(
  createRoutesFromElements(
    <Route element={<PageViews />} errorElement={<RouteErrorBoundary />}>
      <Route path="/app_dev/:port?" element={<DeepMMDev />}>
        {createDeepMMRoutes()}
      </Route>
      <Route path="/app/:port?" element={<DeepMMProd />}>
        {createDeepMMRoutes()}
      </Route>
      <Route path="*" element={<ExternalRedirect to="https://deepmarketmaking.com"/>} />
    </Route>
  )
);

const Site = () => {
  return (
    <ReduxProvider store={store}>
      <RouterProvider router={router} />
    </ReduxProvider>
  )
};

export default Site;
