import { FaCircleCheck } from "react-icons/fa6";

export const CircleCheck = ({
  checked,
}: {
  checked: boolean;
}) => {
  return (
    <span className="w-[0.875rem] h-[0.875rem] border-[#FBFBFD] border-[2px] rounded-[50%] relative">
      {checked && <FaCircleCheck className="w-[0.875rem] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
    </span>
  )
}