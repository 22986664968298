import { memo, useMemo } from "react";
import { uniqBy } from "lodash";
import { AlertItem } from "../data/alerts";
import { isTargetAlert } from "./alert.utils";
import { MAX_ALERTS } from "./alert.constants";
import clsx from "clsx";

const AlertsSummary = memo(({ 
  alerts 
} : { 
  alerts: AlertItem[] | undefined 
}) => {

  const {
    totalBonds,
    targetAlerts,
    volatilityAlerts,
    totalAlerts,
  } = useMemo(() => {
    const a = alerts || [];
    const targetAlertsCount = a.filter(isTargetAlert).length;


    return {
      totalBonds: uniqBy(a, 'figi').length,
      targetAlerts: targetAlertsCount,
      volatilityAlerts: a.length - targetAlertsCount,
      totalAlerts: a.length,
    }
  }, [alerts])

  const itemStyles = 'flex flex-col min-w-[44%] flex-1 sm:min-w-0 sm:flex-auto'

    return (
    <div className="bg-[#333557] mx-[0.625rem] lg:mx-[1.25rem] mt-[1.25rem] flex gap-[1.75rem] px-[1.25rem] py-[1.4688rem] rounded-[0.625rem] flex-col sm:flex-row">
      <h4 className="text-[1.5rem] font-[400] leading-[1.75rem] sm:self-end mr-[2.125rem]">
        Alerts
      </h4>

      <div className="flex gap-[1.75rem] flex-wrap">
        {/* Total bonds */}
        <div className={clsx(itemStyles, 'order-1')}>
          <h5 className="text-[#8183B3] font-[500] text-[0.75rem] leading-[0.875rem]">
            Total Bonds
          </h5>
          <div className="leading-[1.75rem] text-[1.5rem]">{totalBonds}</div>
        </div>

        {/* Target alerts */}
        <div className={clsx(itemStyles, 'order-3 ms:order-2')}>
          <h5 className="text-[#8183B3] font-[500] text-[0.75rem] leading-[0.875rem]">
            Target Alerts
          </h5>
          <div className="leading-[1.75rem] text-[1.5rem]">{targetAlerts}</div>
        </div>

        {/* Volatility alerts */}
        <div className={clsx(itemStyles, 'order-3 ')}>
          <h5 className="text-[#8183B3] font-[500] text-[0.75rem] leading-[0.875rem]">
            Volatility Alerts
          </h5>
          <div className="leading-[1.75rem] text-[1.5rem]">{volatilityAlerts}</div>
        </div>

        {/* Alerts Total */}
        <div className={clsx(itemStyles, 'order-2 sm:order-4')}>
          <h5 className="text-[#8183B3] font-[500] text-[0.75rem] leading-[0.875rem]">
            Total Alerts
          </h5>
          <div className="leading-[1.75rem] text-[1.5rem]">{totalAlerts}/{MAX_ALERTS}</div>
        </div>
      </div>


    </div>
  )
})

export default AlertsSummary;