import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const NoIssuers = () => {
  return (
    <div className="flex flex-row gap-[1rem] items-center px-[1.25rem]">
      <MagnifyingGlassIcon className="text-[#333557] h-[4rem]" />
      <div className="leading-[1.17rem] text-[#8183B3] text-[1rem]">No issuers found</div>
    </div>
  );
};

export default NoIssuers;
