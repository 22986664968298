import StyledListBox from "./styledListbox"
import { SideLabel } from "@/app/bond/bond.constants";
import { Side } from "@/app/data/api";

const sideOptions = Object.values(Side);

export const SideListBox = ({
  side,
  onChange,
}: {
  side: Side,
  onChange: (v: Side) => void,
}) => {
  return (
    <StyledListBox
      value={side}
      selectValue={onChange}
      values={sideOptions}
      optionContent={(v) => SideLabel[v]}
      buttonContent={({ value }) => <span className="text-[#FBFBFD] truncate w-full">{SideLabel[value]}</span>}
    />
  )
}