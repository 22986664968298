import { FunctionComponent } from "react";
import { Issuer } from '../data/dataProvider';

const IssuerCard: FunctionComponent<{ issuer: Issuer }> = ({ issuer }) => {

  return (
    <div className="bg-[#333557] flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.25rem] rounded-[0.625rem] w-[17.1875rem]">
      <div className="flex flex-col items-start w-full">
        <span className="text-[#8183B3]">Ticker</span>
        <span className="overflow-x-auto whitespace-nowrap w-[15.9375rem]">{issuer.ticker}</span>
      </div>
      <div className="flex flex-col items-start w-full">
        <span className="text-[#8183B3]">Name</span>
        <span className="overflow-x-auto whitespace-nowrap w-[15.9375rem]">{issuer.issuer}</span>
      </div>
    </div>
  )
};

export default IssuerCard;
