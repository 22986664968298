import { ReactNode, useEffect, useState } from "react";
import { ErrorLayout, ErrorLayoutProps } from "../errors/errorLayout";
import { useSelector } from "react-redux";
import { selectCanAccessUi, selectIsUiTrialActive, selectIsUiTrialExpired, selectRefreshTokenFn, selectUiTrialExpiration } from "@/store/slices/auth.slice";
import { captureException } from "@sentry/react";
import { ONE_MINUTE } from "@/constants";

// component which checks if user has access to ui and shows an error message if not
export const UiAccessGuard = ({
  children
}: {
  children: ReactNode
}) => {
  const canAccessUi = useSelector(selectCanAccessUi);
  const isUiTrialExpired = useSelector(selectIsUiTrialExpired);
  const uiTrialExpiration = useSelector(selectUiTrialExpiration);
  const isUiTrailActive = useSelector(selectIsUiTrialActive)
  const refreshTokenFn = useSelector(selectRefreshTokenFn);

  useEffect(() => {
    if (isUiTrailActive && uiTrialExpiration) {
      // check if trial expired every minute and trigger a re-render so we can show the error message
      const timerId = setInterval(() => {
        if (uiTrialExpiration * 1000 < Date.now()) {
          clearInterval(timerId);
          refreshTokenFn(); // refresh token to get the latest data
        }
      }, ONE_MINUTE);

      return () => clearInterval(timerId);
    }
  }, [uiTrialExpiration, isUiTrailActive])

  if (!canAccessUi) {
    const commonErrorProps: Partial<ErrorLayoutProps> = {
      showLogo: true,
      actionText: "Refresh",
      onClick: () => refreshTokenFn()
    }

    if (isUiTrialExpired) {
      // Trial expired
      return (
        <ErrorLayout
          {...commonErrorProps}
          title="Trial Expired"
          text="Your trial has expired. Please contact your account manager to continue using the application."
        />
      )
    }


    // user has no access for some reason other than trial expiration
    // currently it can happen only when there is an error in our logic so we log an error to check what happened
    return (
      <>
        <ErrorLayout
          {...commonErrorProps}
          title="No Access"
          text="You have no access to the application. Please contact your account manager or our support team for more information."
        />
        <LogErrorOnce message="User has no access to UI" />
      </>
    )
  }


  return children;
}

const LogErrorOnce = ({ message }: { message: string }) => {
  useEffect(() => {
    captureException(new Error(message));
  }, [])

  return null;
}