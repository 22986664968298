import { FaCaretDown } from 'react-icons/fa6'
import Select, { Props, DropdownIndicatorProps } from 'react-select'


export const FilterListBox = <Option = unknown, IsMulti extends boolean = false>({
  className,
  ...rest
}: Props<Option, IsMulti>) => {
  return (
    <Select 
      className={className}
      menuPlacement='auto'
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator,
      }}
      isSearchable={false}
      {...rest}
      
      styles={{
        control: (base, { menuIsOpen }) => ({
          ...base,
          background: 'transparent',
          border: '0.0625rem solid #2E65A0',
          borderRadius: '0.625rem',
          // borderRadius: menuIsOpen ? '0.625rem 0.625rem 0 0' : '0.625rem',
          color: '#89AFEE',
          display: 'flex',
          fontSize: '0.875rem',
          gap: '0.625rem',
          width: '100%',
          minHeight: '2.875rem',
          transition: 'none',
          boxShadow: 'none',

          '&:hover': {
            borderColor: '#2E65A0',
          }
        }),
        placeholder: (base) => ({
          ...base,
          color: '#A5A6C9',
          fontWeight: '500',
          fontStyle: 'italic',
        }),
        menu: (base, { placement }) => ({
          ...base,
          background: '#5D5F9D',
          borderRadius: '0.625rem',
          // borderRadius: placement ==='top' 
          //   ? '0.625rem 0.625rem 0 0'
          //   : '0 0 0.625rem 0.625rem',
          boxShadow: placement ==='top' 
            ? '10px 10px 30px 0px rgba(7, 1, 31, 0.35), 6px 6px 30px 0px rgba(97, 94, 255, 0.40)'
            : '10px 10px 30px 0px rgba(7, 1, 31, 0.35), -6px -6px 30px 0px rgba(97, 94, 255, 0.40)',
          margin: '0.125rem 0',
          overflow: 'hidden',
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: '14rem',
          padding: 0,
        }),
        option: (base, { isSelected }) => ({
          ...base,
          color: '#FBFBFD',
          fontSize: '0.875rem',
          fontWeight: '500',
          background: isSelected ? '#2E65A0' : '#5D5F9D',
          cursor: 'pointer',
          borderBottom: '1px solid #8183B3',

          '&:hover': {
            background: '#2a81de',
          },

          '&:last-child': {
            borderBottom: 'none',
            borderRadius: '0 0 0.625rem 0.625rem',
          },

          '&:first-child': {
            borderRadius: '0.625rem 0.625rem 0 0',
          }
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0.25rem 0.625rem',
        }),
        singleValue: (base) => ({
          ...base,
          color: '#89AFEE',
          fontWeight: '500',
          fontSize: '0.875rem',
        }),
        multiValue: (base) => ({
          ...base,
          background: '#5D5F9D',
          borderRadius: '0.375rem',
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: '#f5f5f5',
          fontWeight: 500,
        }),
        noOptionsMessage: (base) => ({
          ...base,
          color: '#A5A6C9',
          fontSize: '0.875rem',
          fontStyle: 'italic',
        }),
        input: (base) => ({
          ...base,
          '& input': {
            color: '#A5A6C9 !important',
            outline: 'none',
            boxShadow: 'none !important',
            fotnSize: '0.875rem !important',
            fontWeight: '500 !important',
            fontStyle: 'italic !important',
          }
        }),
        clearIndicator: (base) => ({
          ...base,
          color: '#A5A6C9',
          cursor: 'pointer',
          padding: '0 0.25rem',

          '&:hover': {
            color: '#FBFBFD',
          }
        })
      }}
    />
  )
}

const DropdownIndicator = <Option = unknown>(props: DropdownIndicatorProps<Option>) => {
  const isOpen = props.selectProps.menuIsOpen;
  
  return (
    <div className="flex h-full items-center justify-center px-2.5">
      <FaCaretDown className={` transform transition-transform${isOpen ? ' rotate-180' : ''}`} aria-hidden="true" />
    </div>
  )
}
