import { openYesNoDialog } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import ActionWithInputModal from "@/app/components/modal/actionWithInputModal";
import { Tooltip } from "@/app/components/tooltip";
import { PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";

export const AlertNote = ({
  note,
  onChange,
}: {
  note?: string;
  onChange: (note: string) => void;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const hasNote = Boolean(note);

  function handleSaveNote(newNote: string) {
    const _newNote = newNote.trim();
    const noteIsSame = _newNote === note;
    const noteIsSameEmpty = _newNote === '' && !note; // note can be undefined or empty string

    if (noteIsSame || noteIsSameEmpty) {
      return; // no reason to save data when it's same as before
    }

    onChange(_newNote);
  }

  return (
    <div className="max-w-[200px] inline-flex gap-1 items-center w-fit group/alertNote relative py-2">

      {hasNote && (
        <>
          <Tooltip tooltip={note} maxWidthCss='max-w-[20.625rem]' contentClassName="whitespace-pre-wrap max-h-[50vh] overflow-hidden" >
            <div className="truncate min-w-[50px] pr-[1.5rem] group-hover/alertNote:pr-[3.75rem]">{note}</div>
          </Tooltip>

          {/* edit/delete buttons */}
          <div 
            className={clsx(
              'flex items-center gap-1 absolute right-0 bg-[#5D5F9D] p-1 transition-all duration-200',
              'transform translate-x-[50px] opacity-0', // hidden by default
              'group-hover/alertNote:translate-x-0 group-hover/alertNote:opacity-100', // hover states
            )}
          >
            {/* Edit button */}
            <Tooltip tooltip="Edit">
              <button 
                type="button" 
                className="p-1" 
                onClick={() => setEditModalOpen(true)}
              >
                <PencilSquareIcon width={16} />
              </button>
            </Tooltip>

            {/* Delete Button */}
            <Tooltip tooltip="Delete">
              <button 
                type="button" 
                className="p-1"
                onClick={() => openYesNoDialog({
                  title: 'Delete Note',
                  content: 'Are you sure you want to delete this note?',
                  onButtonClick: () => handleSaveNote(''),
                })}
              >
                <TrashIcon width={16} />
              </button>
            </Tooltip>
          </div>

        </>
      )}

      {/* Add note button */}
      {!hasNote && (
        <button type="button" className="w-[2rem] h-[2rem] cursor-pointer bg-[#484a94] rounded-full flex items-center justify-center" onClick={() => setEditModalOpen(true)}>
          <PlusIcon width={20} />
        </button>
      )}

      {/* Edit note modal */}
      <ActionWithInputModal
        onSubmit={handleSaveNote}
        actionName='Save'
        label='Note'
        placeholder='note'
        setShow={setEditModalOpen}
        show={editModalOpen}
        value={note}
        title='Edit Note'
        multiline
        maxLength={2000}
      />
    </div>
  )
}