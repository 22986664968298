import { useSearchParams } from 'react-router-dom';
import { classNames } from "../..";
import { QueryParam } from '@/constants';
import { memo } from 'react';
import { useUiMode } from '@/hooks/useUiMode';

export const RoundPriceToggle = memo(() => {
  const { isIgUiMode } = useUiMode();

  const [searchParams, setSearchParams] = useSearchParams();
  const label = isIgUiMode ? 'Round to 1 bps' : 'Round to 1/8';

  return (
    <button
      aria-label={label}
      className="bg-[#5D5F9D] flex flex-row gap-[0.625rem] h-[2.25rem] items-center justify-center px-[0.625rem] rounded-[0.625rem] sm:px-[1.25rem]"
      onClick={() => void setSearchParams(prev => {
        if (prev.get(QueryParam.RoundPrice) !== 'true') {
          prev.set(QueryParam.RoundPrice, 'true');
        } else {
          prev.delete(QueryParam.RoundPrice);
        }
        return prev;
      }, { replace: true })}
    >
      <span className="font-medium text-[0.75rem] whitespace-nowrap sm:text-[0.875rem]">{label}</span>
      <div
        className={classNames(
          searchParams.get(QueryParam.RoundPrice) !== 'true'
            ? 'bg-transparent border-[#BFC5F1] border-[0.125rem]'
            : 'bg-[#BFC5F1] border-[#BFC5F1] border-0',
          "box-border h-[0.875rem] rounded-[0.125rem] w-[0.875rem]",
          "focus:outline-none focus:ring-offset-0 focus:ring-[0.125rem] focus:ring-[#4384C8] focus:text-[#BFC5F1]"
        )}
      >
        { searchParams.get(QueryParam.RoundPrice) !== 'true'
            ? null
            : <svg
                className="h-full outline-none pointer-events-none stroke-[#252641] w-full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="19 7.5 9.5 17 5 11.5"></polyline>
              </svg>
        }
      </div>
    </button>
  );
})

