import { useCallback, useMemo } from 'react';
import { NavigateOptions, useLocation, useNavigate, useParams } from 'react-router-dom';

const useAppNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { port } = useParams();
  const baseUrl = useMemo(() => '' + /(\/[^/]*)/.exec(location.pathname)![1] + '/' + (port ? `${port}/` : ''), [location.pathname, port]);
  const appNavigate = useCallback((to: string, options?: NavigateOptions) => navigate(`${baseUrl}${to}`, options), [baseUrl, navigate]);
  return appNavigate;
};

export default useAppNavigate;
