import { XMarkIcon } from '@heroicons/react/20/solid';
import { useDataContext } from '../data/dataProvider';

const ClearSelectedBonds = () => {
  const { selectedBonds, resetSelectedBonds } = useDataContext();

  if (selectedBonds.size < 1) {
    return null;
  }


  return (
    <div className="bg-[#484A7A] flex items-center pl-[1.25rem] rounded-full">
      <span className="text-[0.875rem]">
        {`${selectedBonds.size} selected`}
      </span>
      <button
        className="flex h-[1.75rem] items-center justify-center m-[0.25rem] rounded-full w-[1.75rem]"
        onClick={() => void resetSelectedBonds()}
      >
        <XMarkIcon className="h-[1rem] w-[1rem]" />
      </button>
    </div>
  )
};

export default ClearSelectedBonds;
