import TableQuantityListBoxV2 from "@/app/components/listbox/tableListBoxV2/tableQuantityListBoxV2"
import { quantityOptions } from "@/app/components/listbox/tableQuantityListBox"
import { AlertTableItem } from "../alertsTable";

export const AlertSizeDropdown = ({
  alert,
  onChange,
}: {
  alert: AlertTableItem['alert'],
  onChange: (data: { id: string, size: number }) => void;
}) => {
  return (
    <TableQuantityListBoxV2
      value={`${alert.size / 1000000}` as (typeof quantityOptions)[number]}
      selectValue={v => {
        onChange({ id: alert.id, size: +v * 1000000 })
      }}
    />
  )
}