import { classNames } from "../..";
import { Bond, Side } from '../data/dataProvider';
import { getAsymptoticSpectrumBackground } from "../components/spectrum";
import { Col, Row } from "../components/itemCard";
import clsx from "clsx";
import { InferenceResult } from "@hooks/data/useSimpleInferenceData";
import { getColumnTitle } from "./issuer.utils";
import { IssuerColumn } from "./issuer.constants";
import { useUiMode } from "@hooks/useUiMode";
import { BondCardData } from "../components/data/bondCardData";

export const BondCard = ({
  bond,
  inferenceResult,
  selected
}: {
  bond: Bond,
  inferenceResult: InferenceResult<Bond>;
  selected?: boolean
}) => {
  const { isIgUiMode, isHyUiMode } = useUiMode();
  const gct = getColumnTitle('');
  const { data } = inferenceResult;
  const d = data[bond.figi];

  // bid gspd
  const bidGspdStr = d[Side.bid].gspread.currentString;
  const bidGspdChangeStr = d[Side.bid].gspread.diffString;
  const bidGspdChange = d[Side.bid].gspread.diff;

  // offer gspd
  const offerGSpdStr = d[Side.offer].gspread.currentString;

  // bid yield to maturity
  const bidYTMStr = d[Side.bid].ytm.currentString;
  const bidYTMChangeStr = d[Side.bid].ytm.diffString;
  const bidYTMChange = d[Side.bid].ytm.diff;

  // bid px
  const bidPxStr = d[Side.bid].price.currentString;
  const bidPxChangeStr = d[Side.bid].price.diffString;
  const bidPxChange = d[Side.bid].price.diff;

  // offer px
  const offerPxStr = d[Side.offer].price.currentString;

  // tenor
  const tenor = d[Side.bid].gspread.tenor;


  return (
    <div className={classNames(selected ? "border-[#4384C8]" : "border-transparent", "bg-[#333557] border-2 flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] pb-[0.625rem] pt-[1.25rem] px-[0.625rem] rounded-[0.625rem] w-[17.1875rem] text-[0.875rem]")}>
      <BondCardData bond={bond} tenor={tenor} />

      <div className="bg-[#1F2034] flex flex-col gap-[0.75rem] p-[0.625rem] rounded-[0.4375rem] w-full">

        {/* IG Mode fields */}
        {isIgUiMode && (
          <>
            <Row>
              <Col label={gct(IssuerColumn.BidGSpd)} value={bidGspdStr} />
              <Col
                label={gct(IssuerColumn.BidGSpdChange)}
                value={bidGspdChangeStr}
                valueClassName={clsx(getAsymptoticSpectrumBackground(bidGspdChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                box
                end
              />

            </Row>

            <Row>
              <Col
                label={gct(IssuerColumn.OfferGSpd)}
                value={offerGSpdStr}
              />
            </Row>

            <Row>
              <Col
                label={gct(IssuerColumn.BYTM)}
                value={bidYTMStr}
              />
              <Col
                label={gct(IssuerColumn.BYTMChange)}
                value={bidYTMChangeStr}
                valueClassName={clsx(getAsymptoticSpectrumBackground(bidYTMChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                box
                end
              />
            </Row>
          </>
        )}

        {/* HY mode fields */}
        {isHyUiMode && (
          <>
            <Row>
              <Col
                label={gct(IssuerColumn.BidPx)}
                value={bidPxStr}
              />
              <Col
                label={gct(IssuerColumn.BidPxChange)}
                value={bidPxChangeStr}
                valueClassName={clsx(getAsymptoticSpectrumBackground(bidPxChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                box
                end
              />
            </Row>

            <Row>
              <Col
                label={gct(IssuerColumn.OfferPx)}
                value={offerPxStr}
              />
            </Row>
          </>
        )}

      </div>
    </div>
  )
};

