import { captureException } from "@sentry/core";
import { useEffect } from "react";
import { useRouteError } from "react-router";
import { ErrorLayout } from "./errorLayout";

export function RouteErrorBoundary() {
  const  error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, [error])

  return (
    <ErrorLayout
      title="Error"
      text="Uh-Oh... An unexpected error occurred, don't worry, we are on it. In the mean time, please try again..."
      actionText='Refresh page'
      onClick={() => window.location.reload()}
    />
  );
}