import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDataContext } from '../data/dataProvider';
import Loading from '../loading';
import StyledListBox from '../components/listbox/styledListbox';
import ClearSelectedBonds from '../components/clearSelectedBonds';
import BondCard from './bondCard';
import SearchTable from './searchTable';
import IssuerCard from './issuerCard';
import Prompt from './prompt';
import NoIssuers from './noissuers';
import NoBonds from './nobonds';
import AddToPortfolio from '../portfolio/addToPortfolio';
import AddToRun from '../run/addToRun';
import AddAlertButton from '../alert/addAlertButton';
import { useOpenPage } from '../../hooks/useOpenPage';
import { getPageSize } from '../components/table/hooks/useTablePagination';

const SearchResults = () => {

  const { query } = useParams();
  const { searchBonds, searchIssuers } = useDataContext()
  const [searchParams, setSearchParams] = useSearchParams();
  const { openBondPage, openIssuerPage } = useOpenPage();

  const bonds = useMemo(() => searchBonds && query ? searchBonds(query) : null, [searchBonds, query]);
  const issuers = useMemo(() => searchIssuers && query ? searchIssuers(query) : null, [searchIssuers, query]);

  if (!query || !query.length) {
    return <Prompt />;
  }
  if (!bonds || !issuers) {
    return <Loading className="mt-[2.5rem]" />;
  }
  return (
    <div className="flex flex-col gap-[1.25rem] items-start pt-[1.25rem] w-full">
      <div className="flex flex-col gap-[0.625rem] items-start w-full">
        <div className="pl-[0.625rem] text-[#8183B3] text-[1.25rem]">Issuers</div>
        { issuers.length ?
            <div className="flex flex-row gap-[0.75rem] items-start overflow-x-auto px-[0.625rem] w-full">
              {
                issuers.slice(0,20).map(i => (
                  <div key={i.ticker} onClick={() => void openIssuerPage(i)}>
                    <IssuerCard issuer={i} />
                  </div>
                ))
              }
              {
                issuers.length > 20
                  ? <div className="bg-[#333557] flex flex-row items-center justify-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.5rem] rounded-[0.625rem] w-[17.1875rem]">
                      <span>{`${issuers.length - 20} more...`}</span>
                    </div>
                  : <></>
              }
            </div>
          :
            <NoIssuers />
        }
      </div>
      <div className="flex flex-col gap-[0.625rem] items-start w-full">
        <div className="pl-[0.625rem] pt-[0.625rem] text-[#8183B3] text-[1.25rem]">Bonds</div>
        { bonds.length ?
            <>
              <div className="flex flex-row gap-[0.75rem] items-start overflow-x-auto px-[0.625rem] w-full lg:hidden">
                {
                  bonds.slice(0,20).map(b => (
                    <div key={b.figi} onClick={() => void openBondPage(b)}>
                      <BondCard bond={b} />
                    </div>
                  ))
                }
                {
                  bonds.length > 20
                    ? <div className="bg-[#333557] flex flex-row items-center justify-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.5rem] rounded-[0.625rem] w-[17.1875rem]">
                        <span>{`${bonds.length - 20} more...`}</span>
                      </div>
                    : <></>
                }
              </div>
              <div className="bg-[#333557] flex flex-col hidden mx-[0.625rem] rounded-[0.625rem] w-fit lg:block">
                <div className="flex flex-col lg:h-[calc(100vh-24.75rem)] pb-[0.625rem] pt-[0.875rem] px-[0.625rem] w-fit">
                  <div className="flex flex-row items-center justify-between p-[0.625rem]">
                    <div className="flex flex-row gap-[0.625rem] items-center">
                      <AddToPortfolio />
                      <AddToRun />
                      <AddAlertButton />
                      <ClearSelectedBonds />
                    </div>
                    <div className="flex flex-row gap-[0.625rem] items-center">
                      <span>Page size:</span>
                      <div className="w-[5.5rem]">
                        <StyledListBox
                          selectValue={v => void setSearchParams(prev => { prev.set('s-pageSize', v); return prev; })}
                          value={`${getPageSize(searchParams.get('s-pageSize'))}`}
                          values={['10', '25', '50', '100']}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="h-[calc(100vh-29.875rem)] hidden pb-[0.625rem] w-fit lg:block">
                    <SearchTable
                      bonds={bonds}
                      selectBond={bond => void openBondPage(bond)}
                    />
                  </div>
                </div>
              </div>
            </>
          :
            <NoBonds />
        }
      </div>
    </div>
  );
}

export default SearchResults;
