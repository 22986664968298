import { BroadcastEventData, useBroadcastChannelListener } from "../../hooks/useBroadcastChannel"
import { BroadcastChannelName } from "../../../../constants"
import { AlertResponse } from "../../../data/alerts"
import { useDispatch } from "../../../../store"
import { alertEndpoints } from "../../../../store/api/alert.endpoints"

// syncs data with the latest data from the broadcast channel
export const useAlertBroadcastChannel = () => {
  const dispatch = useDispatch();
  
  useBroadcastChannelListener<BroadcastEventData<AlertResponse>>(
    BroadcastChannelName.AlertUpdate,
    async (data) => {
      // TODO: wait with update until window is still active?

      // update `getAlertObject` query data with the new data
      const action = alertEndpoints.util.updateQueryData('getAlertObject', data.data.id, () => data.data);
      dispatch(action as any)
    },
    []
  )
}