import { useEffect, useState } from "react";
import { useMountedState } from 'react-use'

export const useLinkedAlerts = (resetDeps: any[]) => {
  const [linkedAlerts, setLinkedAlerts] = useState<Set<string>>(new Set());
  const isMounted = useMountedState()

  useEffect(() => {
    if (isMounted()) {
      setLinkedAlerts(new Set())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resetDeps)
  
  return [linkedAlerts, setLinkedAlerts] as const
}