import { FormattedGspread } from "./formattedGspread";
import { FormattedPrice } from "./formattedPrice";
import { FormattedYtm } from "./formattedYtm";

export const FormatNumber = ({
  value,
  type,
  className
}: {
  value: string | number | null | undefined;
  type: 'ytm' | 'price' | 'gspread' | 'gspread_decimals';
  className?: string
}) => {
  switch (type) {
    case 'ytm':
      return <FormattedYtm value={value} className={className} />;
    case 'price':
      return <FormattedPrice value={value} className={className} />;
    case 'gspread':
      return <FormattedGspread
        value={value}
        className={className}
      />;
    case 'gspread_decimals':
      return <FormattedGspread
        value={value}
        className={className}
        decimalScale={1}
      />;
    default:
      throw new Error('Invalid type');
  }
}