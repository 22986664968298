import { useState } from "react"
import { FilterDrawer, FilterName } from "./filterDrawer"
import { SelectedFilters } from "./selectedFilters";
import { Bond } from "@data/bondIndex";
import { FaFilter } from "react-icons/fa6";
import clsx from "clsx";
import { CircleButton } from "../buttons/CircleButton";

export const Filters = ({ 
  activeFilters,
  bonds,
  className,
  pageSizeParamName,
}: { 
  activeFilters?: FilterName[];
  bonds: Bond[];
  className?: string;
  pageSizeParamName?: string;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      {/* Filter Section */}
      <div className={clsx('flex flex-row gap-[0.625rem] items-center', className)}>
        {/* FilterButton */}
        
        <CircleButton onClick={() => setDrawerOpen(true)}>
          <FaFilter className="text-[0.875rem]" />
        </CircleButton>

        {/* SelectedFilters */}
        <SelectedFilters bonds={bonds} />
      </div>


      {/* FilterDrawer */}
      <FilterDrawer
        show={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        bonds={bonds}
        pageSizeParamName={pageSizeParamName}
        activeFilters={activeFilters}
      />
    </>
  )
}