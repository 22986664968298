import TableListBoxV2Base from "@/app/components/listbox/tableListBoxV2/tableListBoxV2Base";
import { DEFAULT_VOLATILITY_CONDITION, volatilityConditionOptions } from "../alert.constants";
import { VolatilityCondition } from "../alert.types";
import { AlertTableItem } from "../alertsTable";

export const VolatilityConditionDropdown = ({
  alert,
  onChange,
}: {
  alert: AlertTableItem['alert'], 
  onChange: (data: { id: string, condition: VolatilityCondition}) => void,
}) => {
  const _value = alert.condition || DEFAULT_VOLATILITY_CONDITION;
  
  return (
    <TableListBoxV2Base
      renderValue={({ value }) => `${value[0]} / ${value[1]}`}
      value={_value}
      options={volatilityConditionOptions as any}
      selectValue={(v) => {
        onChange({ id: alert.id, condition: v })
      }}
    />
  );
}
