import { FunctionComponent } from 'react';
import TableListBoxV2Base, { TableListBoxV2BaseProps } from './tableListBoxV2Base';
import { quantityOptions } from '../tableQuantityListBox';

const TableQuantityListBoxV2: FunctionComponent<{
  selectValue: (v: (typeof quantityOptions)[number]) => void,
  value: (typeof quantityOptions)[number],
  popperProps?: TableListBoxV2BaseProps<any>['popperProps'],
}> =
  ({ selectValue, value, popperProps }) => {
    return (
      <TableListBoxV2Base 
        renderValue={({ value }) => `${value} MM`}
        selectValue={selectValue}
        value={value}
        options={quantityOptions}
        popperProps={popperProps}
      />
    );
  }

export default TableQuantityListBoxV2;
