import { useCallback, useMemo } from 'react';
import { useOpenPage } from '../../hooks/useOpenPage';
import { useCreateRunObjectMutation, useLazyGetRunQuery, useUpdateRunObjectMutation } from '@/store/api/run.endpoints';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/core';
import { useDeleteObjectMutation } from '@/store/api/object.endpoints';
import { UIMode } from '@/types/types';
import { isEqual } from 'lodash';
import { ObjectResponse } from './objects';

export type RunBond = {
  figi: string;
  bidPercentile: number;
  bidSize: number;
  offerPercentile: number;
  offerSize: number;
  bidOfferSpread?: {
    [key in UIMode]?: BidOfferSpread;
  } // this field was added later so it doesn't exist for all runs
};

export type BidOfferSpread = number | 'Profit' | null;

export type RunValue = {
  bonds: RunBond[];
};

export type RunResponse = ObjectResponse<RunValue>


export const useCreateRun = () => {
  const openPage = useOpenPage();
  const [createRunApi] = useCreateRunObjectMutation();

  const createRun = useMemo(() =>
    async (name: string, figis: Set<string>) => {
      if (!name.length) {
        toast.error('Name is required');
        return;
      }
      const bonds = Array.from(figis).map(figi => ({
        figi,
        bidPercentile: 50,
        bidSize: 1000000,
        offerPercentile: 50,
        offerSize: 1000000
      }))

      try {
        const response = await createRunApi({
          name,
          bonds,
        }).unwrap();

        openPage.run(response.id)
        return response;
      } catch (e) {
        captureException(e);
        toast.error('Failed to create run, try again...');
      }
    }
    , [openPage, createRunApi]);

  return createRun;
}


export const useAddToRun = () => {
  const openPage = useOpenPage();
  const [getRunApi] = useLazyGetRunQuery();
  const [updateRunApi] = useUpdateRunObjectMutation();

  const addToRun = useCallback(
    async (id: string, figis: Set<string>) => {
      try {
        const run = await getRunApi(id).unwrap();
        const existingFigis = new Set(run.value.bonds.map(b => b.figi));
        const bonds = [
          ...run.value.bonds,
          ...Array.from(figis).filter(figi => !existingFigis.has(figi)).map(figi => ({
            figi,
            bidPercentile: 50,
            bidSize: 1000000,
            offerPercentile: 50,
            offerSize: 1000000
          }))
        ];

        const response = await updateRunApi({
          id,
          bonds,
          version: run.version
        }).unwrap();
        openPage.run(id)

        return response;
      } catch (e) {
        captureException(e);
        toast.error('Failed to add to run, try again...');
      }
    }
  , [openPage, getRunApi, updateRunApi]);

  return addToRun;
}

export const useRemoveFromRun = () => {
  const [getRunApi] = useLazyGetRunQuery();
  const [updateRunApi] = useUpdateRunObjectMutation();

  const removeFromRun = useMemo(() =>
    async (id: string, figisToRemove: Set<string>) => {
      try {
        const run = await getRunApi(id).unwrap()
        await updateRunApi({
          id,
          bonds: run.value.bonds.filter(b => !figisToRemove.has(b.figi)),
          version: run.version,
        }).unwrap();
      } catch (e) {
        captureException(e);
        toast.error('Failed to remove from run, try again...');
        return false;
      }
    }
  , [getRunApi, updateRunApi]);

  return removeFromRun;
}

export const useEditRun = () => {
  const [getRunApi] = useLazyGetRunQuery();
  const [updateRunApi] = useUpdateRunObjectMutation();

  const editRun = useCallback(
    async (id: string, name: string) => {
      try { 
        const run = await getRunApi(id).unwrap();
        await updateRunApi({
          id,
          metadata: { name },
          version: run.version
        });
      } catch (e) {
        captureException(e);
        toast.error('Failed to edit run, try again...');
        return false;
      }
    }
  , [getRunApi, updateRunApi]);

  return editRun;
}

export const useSaveRunBonds = () => {
  const [updateRunApi] = useUpdateRunObjectMutation();
  const saveRunBonds = useCallback(
    async (id: string, version: string, runBonds: RunBond[]) => {
      try {
        const response = await updateRunApi({
          id,
          version,
          skipListUpdate: true,
          bonds: runBonds.map(b => ({
            figi: b.figi,
            bidPercentile: b.bidPercentile,
            bidSize: b.bidSize,
            offerPercentile: b.offerPercentile,
            offerSize: b.offerSize,
            bidOfferSpread: b.bidOfferSpread ?? undefined,
          }))
        }).unwrap();
        return response;
      } catch (e) {
        captureException(e);
        toast.error('Failed to save run bonds, try again...');
        throw e;
      }
    }
  , [updateRunApi]);
  return saveRunBonds;
}


export const useDeleteRun = () => {
  const [deleteObjectApi] = useDeleteObjectMutation();

  const deletePortfolio = useCallback(
    async (id: string) => {
      try {
        await deleteObjectApi({ type: 'run', id }).unwrap();
      } catch (e) {
        captureException(e);
        toast.error('Failed to delete run, try again...');
        return false;
      }
    }
  , [deleteObjectApi]);

  return deletePortfolio;
}

// (this can be more efficient)
export const deepCompareRunBonds = (a: RunBond[], b: RunBond[]) =>
  a.length === b.length &&
  a.every(b1 => 0 <= b.findIndex(b2 =>
    b1.bidPercentile === b2.bidPercentile &&
    b1.bidSize === b2.bidSize &&
    b1.figi === b2.figi &&
    b1.offerPercentile === b2.offerPercentile &&
    b1.offerSize === b2.offerSize &&
    isEqual(b1.bidOfferSpread, b2.bidOfferSpread)
  )
);
