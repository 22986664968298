import { IssuerColumn, IssuerColumnTitle } from "./issuer.constants";

export const getColumnTitle = (previousString?: string) => (column: IssuerColumn) => {
  const title = IssuerColumnTitle[column];

  if (typeof title !== 'string') {
    return title;
  }

  return title.replace('{prevPeriod}', previousString || '')
};