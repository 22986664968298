import StyledMenu from "@/app/components/styledMenu";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export const RunActionsMenu = ({
  onCopyClick,
  onDelete,
  onDownload,
  onEdit,
}: {
  onCopyClick: (type: 'short' | 'bid_offer' | 'default') => void;
  onDelete: () => void;
  onDownload: () => void;
  onEdit: () => void;
}) => {

  return (
    <StyledMenu
      ariaLabel='Run Menu'
      Button={() => <EllipsisVerticalIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />}
      buttonCss='rounded-full'
      menuItems={[
        {
          ariaLabel: 'Copy to Clipboard',
          Item: () => 'Copy to Clipboard',
          key: 'copy',
          onClick: () => onCopyClick('default'),
        },
        {
          ariaLabel: 'Copy Runs',
          Item: () => 'Copy Runs',
          key: 'copy-runs',
          onClick: () => onCopyClick('short'),
        },
        {
          ariaLabel: 'Copy Bids / Offers',
          Item: () => 'Copy Bids / Offers',
          key: 'copy-runs',
          onClick: () => onCopyClick('bid_offer'),
        },
        {
          ariaLabel: 'Download',
          Item: () => 'Download',
          key: 'download',
          onClick: onDownload,
        },
        {
          ariaLabel: 'Edit',
          Item: () => 'Edit',
          key: 'edit',
          onClick: () => onEdit()
        },
        {
          ariaLabel: 'Delete',
          Item: () => 'Delete',
          key: 'delete',
          onClick: () => onDelete()
        }
      ]}
    />
  )
}