import { Profiler } from "react";

export const ProfilerLog = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  return (
    <Profiler
      id={id}
      onRender={(
        id,
        phase,
        actualDuration,
        baseDuration,
        startTime,
        commitTime,
      ) => {
        if (process.env.REACT_APP_PROFILER_LOG_ENABLED === "true") {
          console.log(
            `ProfilerLog ${id}: time: ${actualDuration} ms... base: ${baseDuration} ms`,
          );
        }
      }}
    >
      {children}
    </Profiler>
  );
};
