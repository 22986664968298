import Loading from "../loading"
import deepmmLogoAllWhite from '../../DeepMM_logo_all_white.svg';

export const AppLoader = () => {
  
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col  items-center">
      <img className="h-[2.52rem]" alt="" src={deepmmLogoAllWhite} />
      <Loading className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" spinnerClassName='w-[200px] h-[200px]' />
    </div>
  )
}