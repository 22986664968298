import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bond, DataContext } from "./dataProvider";
import { useOpenPage } from '../../hooks/useOpenPage';

const useBond = () => {
  const { getBond } = useContext(DataContext);
  const { figi } = useParams();
  const openPage = useOpenPage();

  if (!figi || !figi.length) {
    openPage.search({ replace: true });
  }

  const [bond, setBond] = useState<Bond | null>(null);
  useEffect(() => {
    if (figi && getBond) {
      const newBond = getBond(figi);
      if (!newBond) {
        openPage.search({ replace: true });
        return;
      }
      setBond(prev => (!prev && !newBond) ||
        (prev && newBond && (Object.getOwnPropertyNames(prev) as (keyof Bond)[]).reduce((a, c) => a &&
          prev[c] === newBond[c], true as boolean))
        ? prev : newBond);
    } else {
      setBond(prev => prev ? null : prev);
    }
  }, [openPage, figi, getBond]);

  return bond;
}

export default useBond;
