import { FunctionComponent, ReactNode, useContext, useMemo } from 'react';
import { classNames } from "../..";
import { Bond, DataContext } from '../data/dataProvider';
import { Column } from '../components/table/table';
import { CusipCell } from '../components/data/cusipCell';
import { AlertHeaderCell } from '../components/data/alertHeaderCell';
import { AlertCell } from '../components/data/alertCell';
import { ColumnsOrderConfig, SearchColumn } from './search.constants';
import { TableWithUserPreferences } from '../components/table/tableWithUserPreferences';
import { createNumberSort, createStringSort } from '@/utils/sort.utils';
import { getCheckboxColumnConfig } from '../components/data/table.utils';
import { formatAmountOutstanding } from '@/utils/number.utils';

const tdCss = (selected: boolean) => classNames(selected ? 'bg-[#5D5F9D77]' : '', 'px-[0.3125rem] first:rounded-l-[0.625rem] last:rounded-r-[0.625rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]');

const header = (content: ReactNode, background?: string) =>
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full px-[0.3125rem] w-full">
    <div className={classNames(background || '', 'flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-[#C9CADE] text-start font-bold')}>
      { content }
    </div>
  </div>
const cell = (contents: ReactNode, selected: boolean, background?: string) =>
  <div className={classNames(background && !selected ? `${background} group-hover:bg-[transparent]` : '', 'flex flex-col h-full justify-center px-[0.625rem] py-[1rem] text-[0.875rem]')}>
    {contents}
  </div>

const SearchTable: FunctionComponent<{
  bonds: Bond[],
  selectedBond?: Bond | null,
  selectBond: (bond: Bond) => void }
> = ({
  bonds,
  selectedBond,
  selectBond
}) => {

  const { dispatch, selectedBonds } = useContext(DataContext);

  const columns: Column<Bond, SearchColumn>[] = useMemo(() =>{
    const columns: Column<Bond, SearchColumn>[] = [
      {
        ...getCheckboxColumnConfig({
          cell,
          header: (t, s) => header(t, s),
          items: bonds,
          selectedItems: selectedBonds,
          setSelectedItems: (newSelection) => dispatch({ type: 'setSelectedBonds', payload: newSelection }),
          getKey: (b) => b['figi'],
        }),
        id: SearchColumn.Checkbox,
        tdCss
      },
      {
        draggable: false,
        id: SearchColumn.Alert,
        Cell: (b, {selected}) => cell(<AlertCell cusip={b.cusip} />, selected),
        Header: () => header(<AlertHeaderCell />),
        tdCss
      },
      {
        id: SearchColumn.Cusip,
        Cell: (b, {selected}) => cell(<CusipCell cusip={b.cusip} />, selected),
        Header: () => header('Cusip'),
        tdCss
      },
      {
        id: SearchColumn.Ticker,
        Cell: (b, {selected}) => cell(b['ticker'], selected),
        Header: () => header('Ticker'),
        tdCss
      },
      {
        id: SearchColumn.Issuer,
        Cell: (b, {selected}) => cell(b['issuer'], selected),
        Header: () => header('Issuer'),
        tdCss
      },
      {
        id: SearchColumn.Coupon,
        Cell: (b, {selected}) => cell(`${b['coupon'].toFixed(3)}%`, selected),
        Header: () => header('Coupon'),
        tdCss
      },
      {
        id: SearchColumn.Maturity,
        Cell: (b, {selected}) => cell(b['maturity'], selected),
        Header: () => header('Maturity'),
        tdCss
      },
      {
        id: SearchColumn.SAndPRating,
        Cell: (b, { selected }) => cell(b['rating'] || '-', selected),
        Header: () => header('S&P'),
        tdCss
      },
      {
        id: SearchColumn.Series,
        Cell: (b, {selected}) => cell(b['series'] || '-', selected),
        Header: () => header('Series'),
        tdCss
      },
      {
        id: SearchColumn.AmountOutstanding,
        getValue: b => formatAmountOutstanding(b.amountOutstanding),
        Cell: (b, {selected, value}) => cell(value, selected),
        Header: sort => header('Outstanding', sort),
        tdCss
      },
    ]

    return columns
  }, [dispatch, bonds, selectedBonds]);

  const trCss = useMemo(() =>
    (b: Bond) =>
      classNames(
        selectedBonds.has(b.figi)
          ? 'bg-gradient-to-r from-[#5D5F9D77]'
          : '',
        'rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]'
      )
  , [selectedBonds])
  return (
    <TableWithUserPreferences
      columns={columns}
      generateItemKey={b => b.cusip}
      items={bonds}
      onRowClick={(_, bond) => void selectBond(bond)}
      selectedItem={selectedBond}
      tableCss='min-w-[60rem] mx-[0.625rem]'
      tableName='s'
      theadCss='bg-[#333557] sticky top-0 z-[2]'
      trCss={trCss}
      columnsOrderConfig={ColumnsOrderConfig}
      preferenceTableName='search'
      preferenceKey='default'
      useSearchPagination
    />
  );
}

export default SearchTable;
