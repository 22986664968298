import { ReactNode, forwardRef } from "react";
import { useTabsContext } from "./tabs";
import clsx from "clsx";

export const Tab = forwardRef<HTMLDivElement, {
  children: ReactNode
  value: string;
}>(({
  children,
  value,
  ...rest
}, ref) => {
  const { active, setActiveTab, variant } = useTabsContext();
  const isActive = active === value;

  const css = {
    default: clsx(
      'bg-[#333557] flex flex-row gap-[0.375rem] items-center justify-between pl-[0.625rem] pr-[0.375rem] py-[0.625rem] rounded-t-[0.625rem] sm:gap-[0.625rem] sm:min-w-[9rem] sm:pl-[1.25rem] sm:pr-[0.625rem] h-[3.5rem] cursor-pointer', {
      'opacity-40 hover:opacity-100': !isActive,
    }),
    row: clsx('px-2 py-0.5  text-[#FBFBFD] text-[0.875rem] text-left rounded-[0.5rem] cursor-pointer', {
      'bg-[#0a0b12]': isActive,
      'hover:bg-[#0a0b12]': !isActive,
    }),
  }

  return (
    <div
      ref={ref}
      className={css[variant]}
      onClick={() => setActiveTab(value)}
      {...rest}
    >
      <div className="font-medium text-[0.875rem]">
        {children}
      </div>
    </div>
  )
})