import { FaFileCircleExclamation } from "react-icons/fa6";

export const ImportModalFileRow = ({ fileName }: { fileName?: string }) => {

  if (!fileName) {
    return null;
  }

  return (
    <div className='mt-4 flex items-center gap-[0.3125rem] text-[#EDEDF4]'>
      <FaFileCircleExclamation className='min-w-[1rem]' />
      <span className='min-w-0 flex-1 truncate'>
        <span className=''>{fileName}</span>
      </span>
    </div>
  )
}