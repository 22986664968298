import { Dispatch, FunctionComponent, ReactNode, SetStateAction, useEffect, useId, useState } from 'react';
import ActionModal from './actionModal';
import { TextareaField } from '../fields/TextareaField/TextareaField';
import clsx from 'clsx';

const ActionWithInputModal: FunctionComponent<{
  action?: () => Promise<void | boolean>,
  onSubmit?: (value: string) => void,
  actionName: string,
  body?: ReactNode,
  disabled?: boolean,
  label: string,
  placeholder: string,
  setShow: Dispatch<SetStateAction<boolean>>,
  setValue?: Dispatch<SetStateAction<string>>,
  show: boolean,
  title?: string;
  value?: string;
  multiline?: boolean;
  maxLength?: number;
}> = ({
  action,
  actionName,
  body,
  disabled,
  label,
  placeholder,
  setShow,
  setValue,
  show,
  title,
  value = '',
  onSubmit,
  multiline,
  maxLength,
}) => {
    const [localValue, setLocalValue] = useState(value);
    const inputId = useId();

    useEffect(() => {
      if (show) {
        setLocalValue(value);
      }
    }, [show])

    const onChange = setValue || setLocalValue;
    const _value = setValue ? value : localValue;

    const _action = () => {
      if (action) {
        return action()
      } else if (onSubmit) {
        return onSubmit(_value);
      }
    }

    const baseCss = clsx(
      "bg-[#5D5F9D] border-0 px-[1.25rem] py-[0.625rem]",
      "ring-0 rounded-[0.625rem] ",
      "text-[1rem] text-[#FBFBFD] w-full",
      "focus:ring-2 focus:ring-inset focus:ring-[#4384C8] placeholder:text-[#C9CADE]/[0.5]"
    )

    const inputCss = clsx(baseCss)

    const textareaCss = clsx(baseCss, 'resize-none max-h-[50vh]')

    // input
    const input = (
      <input
        className={inputCss}
        id={inputId}
        name={inputId}
        onChange={e => void onChange(e.target.value)}
        placeholder={placeholder}
        type="text"
        value={_value}
        maxLength={maxLength}
      />
    )

    // textarea
    const textarea = (
      <TextareaField
        id={inputId}
        name={inputId}
        onChange={e => void onChange(e.target.value)}
        placeholder={placeholder}
        value={_value}
        className={textareaCss}
        maxLength={maxLength}
      />
    )

    return (
      <ActionModal
        action={_action}
        actionName={actionName}
        body={
          <div className="w-full">
            {body || <></>}
            <label htmlFor={inputId} className="visually-hidden">{label}</label>
            {multiline ? textarea : input}
          </div>
        }
        disabled={disabled}
        onClose={() => setShow(false)}
        show={show}
        title={title}
      />
    );
  }

export default ActionWithInputModal;
