import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AlertTableItem } from "../../alertsTable";
import { isEmpty, isNumber } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AlertToast } from "../components/alertToast";
import { useAlertObject } from "../../hooks/useAlertObject";
import { alertEndpoints } from "../../../../store/api/alert.endpoints";
import { getAppState } from "../../../../store";
import { selectAlerts, selectTriggeredAlerts } from "../../../../store/slices/alert.slice";
import { useOpenPage } from "../../../../hooks/useOpenPage";

// shows notifications for alerts
export function useAlertNotifications() {
  const dispatch  = useDispatch();
  const openPage = useOpenPage();
  const { alertObjectDetails } = useAlertObject();
  const alertObjectId = alertObjectDetails?.id;
  const alerts = useSelector(selectAlerts);
  const triggeredAlerts = useSelector(selectTriggeredAlerts(true));

  const snoozeRef = useRef({ timeout: null as NodeJS.Timeout | null, closestDate: Infinity });
  const [triggerSnoozeRefresh, setTriggerSnoozeRefresh] = useState(0); // update object to refresh notification after snooze duration over


  // calculate triggered alerts
  useEffect(() => {
    // show/hide notifications
    hideAlertNotificationsIfNeeded(triggeredAlerts)

    if (!isEmpty(triggeredAlerts)) {
      triggeredAlerts.forEach(showHideAlertNotification);
    }
  }, [triggeredAlerts, triggerSnoozeRefresh])

  useEffect(() => {
    // hide notifications on unmount
    return () => {
      hideAlertNotificationsIfNeeded([]);
    }
  }, [])


  // update snooze timeout
  useEffect(() => {
    // timeout for snooze
    const snoozedAlerts = alerts
      .map(a => a.alert.snoozedUntil)
      .filter(isNumber)
    const closestSnoozedAlertMs = Math.min(...snoozedAlerts);

    if (closestSnoozedAlertMs < Date.now()) {
      // snoozed alert is already expired
      return;
    }

    // check if closes snoozed alert will expire faster than the one we're currently waiting for
    if (closestSnoozedAlertMs < snoozeRef.current.closestDate) {
      snoozeRef.current.closestDate = closestSnoozedAlertMs;
      const delay = closestSnoozedAlertMs - Date.now();
      clearTimeout(snoozeRef.current.timeout as any);

      snoozeRef.current.timeout = setTimeout(() => {
        snoozeRef.current.closestDate = Infinity;
        setTriggerSnoozeRefresh(s => s+1)
      }, delay)
    }
  }, [alerts])







  useEffect(() => {
    if (triggerSnoozeRefresh === 0 || !alertObjectId) {
      return;
    }

    const cachedData = alertEndpoints.endpoints.getAlertObject.select(alertObjectId)(getAppState());
    const d = cachedData.data;

    if (!d) {
      return;
    }

    // update snooze until for alerts
    const action = alertEndpoints.util.updateQueryData('getAlertObject', alertObjectId, (d) => {
      if (!d) return d;

      const updatedData = {
        ...d,
        value: {
          ...d.value,
          alerts: d.value.alerts.map(a => {
            if (a.snoozedUntil && a.snoozedUntil < Date.now()) {
              return {
                ...a,
                snoozedUntil: undefined,
              }
            }
  
            return a;
          })
        }
      }
      return updatedData;
    })

    dispatch(action as any);
  }, [triggerSnoozeRefresh, alertObjectId])


  type NotificationsRef = {
    [alertId: string]: {
      alert: AlertTableItem,
      triggeredTime: number;
    }
  }


  const notificationsRef = useRef<NotificationsRef>({});

  function showHideAlertNotification(alert: AlertTableItem) {
    const notification = notificationsRef.current[alert.alert.id]
    const triggeredTime = notification?.triggeredTime ?? Date.now();
    const toastData = {
      autoClose: false as const,
    }

    const content = <AlertToast alert={alert} triggeredTimeMs={triggeredTime} />


    if (notification) {
      // update toast
      const notificationId = `${alert.alert.id}-${notification.triggeredTime}`

      toast.update(notificationId, {
        ...toastData,
        render: content,
      })
    } else {
      // add toast
      const notificationId = `${alert.alert.id}-${triggeredTime}`

      toast(content, { ...toastData, containerId: 'alertToastContainer', toastId: notificationId, onClick: () => openPage.bond(alert.bond) })
      notificationsRef.current[alert.alert.id] = { alert, triggeredTime };
    }
  }






  // hideAlertNotificationsIfNeeded
  function hideAlertNotificationsIfNeeded(triggeredAlerts: AlertTableItem[]) {
    const triggeredAlertIds = triggeredAlerts.map(alert => alert.alert.id);
    const notifications = Object.values(notificationsRef.current);

    notifications.forEach(notification => {
      const alertId = notification.alert.alert.id;
      const notificationId = `${alertId}-${notification.triggeredTime}`

      if (!triggeredAlertIds.includes(alertId)) {
        if (toast.isActive(notificationId)) {
          toast.dismiss(notificationId)
          delete notificationsRef.current[alertId];
        }
      }
    })
  }



}