import { PriceType, UIMode } from "@/types/types";
import { Side } from "../data/api";

export const PriceTypeToQuantileKey: Record<PriceType, 'inferred_price' | 'inferred_ytm' | 'inferred_gspread'> = {
  [PriceType.Price]: 'inferred_price',
  [PriceType.Ytm]: 'inferred_ytm',
  [PriceType.GSpread]: 'inferred_gspread',
}

export const PriceTypeLabel: Record<PriceType, string> = {
  [PriceType.Price]: 'Price',
  [PriceType.Ytm]: 'YTM',
  [PriceType.GSpread]: 'Spread',
}

export const SideLabel: Record<Side, string> = {
  [Side.bid]: 'Bid',
  [Side.offer]: 'Offer',
  [Side.dealer]: 'Dealer',
}

export const RfqLabelMap: Record<UIMode, Record<Side, PriceType[]>> =  {
  [UIMode.Default_14_05_2024]: {
    [Side.bid]: [PriceType.Price],
    [Side.dealer]: [PriceType.Price],
    [Side.offer]: [PriceType.Price],
  },
  [UIMode.HighYield]: {
    [Side.bid]: [PriceType.Price, PriceType.Ytm, PriceType.GSpread],
    [Side.dealer]: [PriceType.Price],
    [Side.offer]: [PriceType.Price],
  },
  [UIMode.InvestmentGrade]: {
    [Side.bid]: [PriceType.Price, PriceType.Ytm, PriceType.GSpread],
    [Side.dealer]: [PriceType.GSpread],
    [Side.offer]: [PriceType.GSpread],
  },
}