import { memo, useMemo, useState } from "react"
import clsx from 'clsx';
import { useDataContext } from "../data/dataProvider";
import AddAlertsModal from "./addAlertsModal";
import StyledMenu from "../components/styledMenu";
import { AlertType } from "../data/alerts";
import ActionModal from "../components/modal/actionModal";
import { selectAlerts } from "@/store/slices/alert.slice";
import { getAppState } from "@/store";
import { MAX_ALERTS } from "./alert.constants";
import { openConfirmationDialogPromise } from "../components/confirmationDialog/confirmationDialog.utils";
import { WARN_ABOUT_MANY_ITEMS_LIMIT } from "@/constants";

const AddAlertButton = memo(({ figi }: { figi?: string }) => {
  const { selectedBonds, resetSelectedBonds } = useDataContext()
  const [showModal, setShowModal] = useState(false);
  const [alertType, setAlertType] = useState(AlertType.Target);
  const [showReachedLimitWarning, setShowReachedLimitWarning] = useState(false);

  const figisToAdd = useMemo(() => {
    return (figi && figi.length) ? new Set([figi]) : selectedBonds;
  }, [figi, selectedBonds]);

  const disabled = figisToAdd.size === 0;

  const handleAddAlertsClick = (t: AlertType) => async () => {
    const alerts = selectAlerts(getAppState());

    if (alerts.length >= MAX_ALERTS) {
      setShowReachedLimitWarning(true);
      return;
    }

    if (figisToAdd.size > WARN_ABOUT_MANY_ITEMS_LIMIT) {
      const confirmed = await openConfirmationDialogPromise({
        title: <>Do you want to add <span className='text-[#fff] font-bold'>{figisToAdd.size}</span> alerts?</>,
        buttonText: 'Proceed',
      });

      if (!confirmed) {
        return;
      }
    }

    setAlertType(t);
    setShowModal(true);
  }

  const button = <button
    type="button"
    className={clsx('inline-flex justify-center px-[1.75rem] py-[0.875rem] text-[0.75rem] focus:outline-none focus:ring-offset-0 focus:ring-2  rounded-[0.375rem]', {
      'focus:ring-[#4384C8] bg-[#484A7A] cursor-pointer': !disabled,
      'bg-[#8183B3]/[0.1] text-[#7D7D82]': disabled,
    })}
    disabled={disabled}
  >
    Add Alert
  </button>

  return (
    <>
      <StyledMenu
        ariaLabel='Portfolio Menu'
        Button={() => button}
        buttonCss='!p-0'
        menuItemsCss="left-0"
        menuItems={[
          {
            ariaLabel: 'Target Alert',
            Item: () => 'Target',
            key: 'targetAlert',
            onClick: handleAddAlertsClick(AlertType.Target),
          },
          {
            ariaLabel: 'VolatilityAlert',
            Item: () => 'Volatility',
            key: 'volaitilityAlert',
            onClick: handleAddAlertsClick(AlertType.Volatility),
          },
        ]}
      />

      <AddAlertsModal
        figisToAdd={figisToAdd}
        onClose={() => setShowModal(false)}
        show={showModal}
        alertType={alertType}
        onSuccess={() => {
          if (!figi) {
            resetSelectedBonds();
          }
        }}
      />

      <ActionModal
        title="You've reached the maximum number of alerts"
        body="You can't add more alerts. Please remove some alerts to add new ones."
        show={showReachedLimitWarning}
        onClose={() => setShowReachedLimitWarning(false)}
        action={async () => {
          setShowReachedLimitWarning(false)
        }}
        actionName="Ok"
      />

    </>
  )
})

export default AddAlertButton;