import { Bond } from "@data/bondIndex"
import { useMemo } from "react"
import { uniqBy } from "lodash"

// calculates filter dropdown unique options based on bond data
export const useFilterOptions = <T extends Bond>(bonds: T[]) => {
  const { 
    tickerOptions, 
    couponOptions, 
    seriesOptions,
    ratingOptions,
  } = useMemo(() => {
    // ticker options
    const tickerOptions = bonds
      .map(p => ({ value: p.ticker, label: p.ticker }))

    // coupon options
    const couponOptions = bonds 
      .map(p => ({ value: p.coupon, label: `${p.coupon}%` }))
      .sort((a, b) => a.value - b.value)

    // series options
    const seriesOptions = bonds
      .map(p => ({ value: p.series || '-', label: p.series || '-' }))
      .sort((a, b) => a.value.localeCompare(b.value))

    // rating options
    const ratingOptions = bonds
      .map(p => ({ value: p.rating || '-', label: p.rating || '-' }))
      .sort((a, b) => a.value.localeCompare(b.value))
      

    return {
      tickerOptions: uniqBy(tickerOptions, 'value'),
      couponOptions: uniqBy(couponOptions, 'value'),
      seriesOptions: uniqBy(seriesOptions, 'value'),
      ratingOptions: uniqBy(ratingOptions, 'value'),
    }
  }, [bonds])

  return {
    tickerOptions,
    couponOptions,
    seriesOptions,
    ratingOptions,
  }
}