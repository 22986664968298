import { Menu, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { useTopLevelPage } from '../../hooks/useTopLevelPage';
import clsx from 'clsx';
import { useAuthenticator } from '@aws-amplify/ui-react';
import useAppNavigate from '@hooks/useAppNavigate';
import { AppViewTypeSelectorDesktop } from './appViewTypeSelector.desktop';
import { FaChevronRight } from 'react-icons/fa6';
import { MenuButton } from '../menuButton';

export function DesktopDropdownMenu() {
  const { signOut } = useAuthenticator((context) => [context.user]);

  const menuItems = [
    { label: 'Portfolio', route: 'portfolio' },
    { label: 'Runs', route: 'run' },
    { label: 'Alerts', route: 'alert' },
    { label: 'Preferences', submenu: <AppViewTypeSelectorDesktop /> },
    { label: 'Sign Out', onClick: signOut },
  ]

  return (
    <Menu as="div" className="relative">
      <div className="flex items-center">
        <Menu.Button>
          <MenuButton />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        unmount={false}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items unmount={false} className="absolute bg-[#5D5F9D] origin-top-right right-0 ring-1 ring-black ring-opacity-5 rounded-[0.625rem] shadow-menu w-48 z-[50] focus:outline-none">
          {menuItems.map((item, idx) => (
            <MenuItem 
              key={idx}
              {...item}
              >
              {item.label}
            </MenuItem>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

// <MenuItem />
const MenuItem = ({
  children,
  route,
  submenu,
  onClick,
}: {
  children: ReactNode
  route?: string;
  submenu?: ReactNode;
  onClick?: () => void;
}) => {
  const topLevelPage = useTopLevelPage();
  const appNavigate  = useAppNavigate();
  const isCurrentPage = topLevelPage === route;

  function handleClick() {
    if (route) {
      appNavigate(route);
    } else {
      onClick?.();
    }
  }

  // border-bottom: 1px solid var(--Blue-400, #8183B3);

  return <Menu.Item key='run' disabled={isCurrentPage} >
    {({ active }) => (
      <button
        type="button"
        className={clsx('group/menu-parent flex items-center justify-between py-2 px-2.5 text-gray-fb text-left text-sm font-medium w-full border-b-[#8183b3] [&:not(:last-child)]:border-b-[1px] relative first:rounded-t-[0.625rem] last:rounded-b-[0.625rem]', {
          'bg-[#484A7A80]': active,
          'bg-[#484A7A]': isCurrentPage,
        })}
        onClick={handleClick}
        
      >
        {children}

        {submenu && (
          <div className="hidden group-hover/menu-parent:block">
            {submenu}
          </div>
        )}
        

        {submenu && <FaChevronRight />}
      </button>
    )}
  </Menu.Item>
}
