import { PriceType } from "@/types/types"
import StyledListBox from "./styledListbox"
import { PriceTypeLabel } from "@/app/bond/bond.constants";

const priceTypeOptions = Object.values(PriceType);

export const PriceTypeListBox = ({
  priceType,
  onChange,
}: {
  priceType: PriceType,
  onChange: (v: PriceType) => void,
}) => {
  return (
    <StyledListBox
      value={priceType}
      selectValue={onChange}
      values={priceTypeOptions}
      optionContent={(v) => PriceTypeLabel[v]}
      buttonContent={({ value }) => <span className="text-[#FBFBFD] truncate w-full">{PriceTypeLabel[value]}</span>}
    />
  )
}