import { Portal } from "@headlessui/react"
import clsx from "clsx"
import React, { ReactElement, ReactNode, useId, ComponentProps } from "react"
import { Tooltip as ReactTooltip  } from "react-tooltip"

type ReactTooltipProps = ComponentProps<typeof ReactTooltip>;

export const Tooltip = ({
  children,
  tooltip,
  maxWidthCss = 'max-w-[18.75rem]',
  contentClassName ,
  className,
  ...rest
}: {
  children: ReactElement
  tooltip: ReactNode;
  maxWidthCss?: string;
  contentClassName?: string;
} & ReactTooltipProps) => {
  const id = useId();

  return (
    <>
      {React.cloneElement(children, { 'data-tooltip-id': id})}

      <Portal>
        <ReactTooltip
          id={id}
          place='top'
          className={clsx('z-30', className)}
          opacity={1}
          {...rest}
        >
          <div className={clsx(maxWidthCss, contentClassName)}>
            {tooltip}
          </div>
        </ReactTooltip>
      </Portal>
    </>
  )
}