import { Fragment } from "react";
import { ImportResultItem } from "./ImportResultItem";
import { isEmpty } from "lodash";

export const ImportResultUniqueIdMissing = ({
  title,
  missingIds,
}: {
  title: string;
  missingIds: string[];
}) => {

  if (isEmpty(missingIds)) {
    return null;
  }

  return (
    <div className='mt-4  p-3 bg-[#1F2034] rounded-md'>
      <ImportResultItem title={title}>
        <div>
          {missingIds.map(id => (
            <Fragment key={id}>
              {id}
              {id !== missingIds[missingIds.length - 1] && <br />}
            </Fragment>
          ))}
        </div>
      </ImportResultItem>
    </div>
  )
}