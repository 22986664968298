import { ToastContainer, cssTransition } from "react-toastify";
import './alertToastProvider.style.css';
import { useSelector } from "react-redux";
import { selectVisibleAlertsNotificationsCount } from "../../../store/slices/alert.slice";
import clsx from "clsx";
import { ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  dark: "bg-white-600 font-gray-300",
  default: "bg-[#0A0B11]",
};

const animation = cssTransition({
  enter: "an-slide-in-right",
  exit: "an-slide-out-right"
});

export function AlertToastProvider() {
  const visibleNotificationsCount = useSelector(selectVisibleAlertsNotificationsCount)

  const toastStyles = clsx('alert-toast-container z-[1000] overflow-auto max-h-[50vh] overflow-x-hidden', {
    'alert-toast-container--multiple': visibleNotificationsCount > 1,
  })


  return (
    <ToastContainer
      className={toastStyles}
      containerId="alertToastContainer" 
      position="top-right"
      toastClassName={(context) => {
        const typeStyles = contextClass[context?.type || "default"];
        return `${typeStyles}  relative flex px-5 py-2.5 mb-0.5 min-h-[3.75rem] rounded-[0.625rem] justify-between overflow-hidden text-[#EDEDF4] overflow-visible`
      }}
      bodyClassName={() => " text-sm font-white font-med block flex items-center w-full [&>div:first-child]:w-full cursor-pointer"}
      closeButton={false}
      transition={animation}
      hideProgressBar
    />
  )
}