import { AnimatePresence, motion } from "framer-motion"
import { ReactNode } from "react"

export const AnimatedHeight = ({ 
  children, 
  visible 
}: {
  children: ReactNode
  visible: boolean
}) => {
  return (
    <AnimatePresence >
      {visible && (
        <motion.div
          style={{ overflow: "hidden", width: "100%" }}
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ duration: 0.3 }}
          exit={{ height: 0 }}

        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}