import TableListBoxV2Base from "@/app/components/listbox/tableListBoxV2/tableListBoxV2Base";
import { AlertTableItem } from "../alertsTable";
import { Portal } from "@headlessui/react";
import { Tooltip } from "react-tooltip";
import { isTargetAlert } from "../alert.utils";
import { TARGET_ALERT_SIDES, VOLATILITY_ALERT_SIDES } from "../alert.constants";
import { Side } from "../../data/api";



export const AlertSideDropdown = ({ 
  alert, 
  onChange,
  canChangeSide,
}: { 
  alert: AlertTableItem['alert'], 
  canChangeSide: (alert: AlertTableItem['alert']) => boolean,
  onChange: (data: { id: string, side: Side }) => void;
}) => {
  const alertSideOptions = isTargetAlert(alert) ? TARGET_ALERT_SIDES : VOLATILITY_ALERT_SIDES

  const isOptionDisabled = (v: Side) => {
    return !canChangeSide(alert) && alert.side !== v;
  }

  return (
    <TableListBoxV2Base
      value={alert.side}
      options={alertSideOptions}
      selectValue={v => {
        onChange({ id: alert.id, side: v })
      }}
      isOptionDisabled={isOptionDisabled}
      renderOption={({ value, disabled }) => {
        const tooltipId = `alert-disabled-side-reason-${alert.id}-${value}`;

        return (
          <>
            <span data-tooltip-id={tooltipId} className="flex">
              {value}
            </span>

            {/* tooltip with the disabled option reason */}
            {disabled && (
              <Portal>
                <Tooltip 
                  id={tooltipId} 
                  place='top'   
                  className="z-30"
                >
                  You can not change side for this alert <br />
                  Alert for {value} side alerady exists
                </Tooltip>
              </Portal>
            )}
          </>
        )
      }}
    />
  )
}