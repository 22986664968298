import { TooltipItem } from "chart.js";
import { CustomLabel, RawData, RawDataFloating, RawDataNormal } from "./chart.types"
import { PriceTypeLabel } from "@/app/bond/bond.constants";
import { PriceType } from "@/types/types";
import { formatAmountOutstanding, formatCoupon, formatPrice } from "@/utils/number.utils";
import { NumericFormatProps } from "react-number-format";
import { IErrorBarOptions } from "chartjs-chart-error-bars";
import { PointData } from "../curveChart/curveChart.types";

export const getMinMaxValues = (data: (RawData | null)[], isFloatingBarsMode: boolean) => {
  let min: number;
  let max: number;

  if (isFloatingBarsMode) {
    const _data = data as (RawDataFloating | null)[];
    min = Math.min(..._data.map((d) => Math.min(d?.y[0] || 0, d?.y[1] || 0)))
    max = Math.max(..._data.map(d => Math.max(d?.y[0] || 0, d?.y[1] || 0)))
  } else {
    const _data = data as (RawDataNormal | null)[];
    min = Math.min(0, ..._data.map(d => Math.min(d?.y || 0, d?.yMin || 0, d?.yMax || 0)))
    max = Math.max(0, ..._data.map(d => Math.max(d?.y || 0, d?.yMax || 0, d?.yMin || 0)))
  }

  return { min, max }
}


export const getTooltipLabels = ({
  tooltipItem,
  priceType,
  delta,
  formatConfig,
  isFloatingBarsMode,
}: {
  tooltipItem: TooltipItem<"bar"> | TooltipItem<"scatter">, // data might be `PointData` or `RawDataFloating`
  priceType: PriceType,
  delta: boolean,
  formatConfig: NumericFormatProps
  isFloatingBarsMode: boolean
}) => {
  const labelSuffix = PriceTypeLabel[priceType]
  const valueSuffix = priceType === PriceType.GSpread ? 'bps' : '';

  // prepare data
  const rawData = 'fifthPercentile' in (tooltipItem as any).raw ? tooltipItem.raw as PointData : tooltipItem.raw as RawDataFloating;
  const { bond, inferenceData } = rawData;
  let min: number | undefined;
  let max: number | undefined;
  let value: number | undefined;
  

  if ('fifthPercentile' in rawData) {
    min = rawData.fifthPercentile;
    max = rawData.ninetyFifthPercentile;
    value = tooltipItem.parsed.y;
  } else {
    const { yMin, yMax, y } = rawData
    min = isFloatingBarsMode ? y[0] : yMin;
    max =isFloatingBarsMode ? y[1] : yMax;
    value = isFloatingBarsMode ? yMin : tooltipItem.parsed.y; // for floating bars mode yMin/yMax have same value
  }

  // create labels
  const labels: CustomLabel[] = [
    { label: `Cusip`, value: bond.cusip, id: 'cusip' },
    { label: `Ticker`, value: bond.ticker, id: 'ticker' },
    { label: `Coupon`, value: formatCoupon(bond.coupon), id: 'coupon' },
    { label: `Maturity`, value: bond.maturity, id: 'maturity' },
    { label: `S&P`, value: bond.rating },
    { label: `Series`, value: bond.series || '-' },
    { label: `Outstanding`, value: formatAmountOutstanding(bond.amountOutstanding) },
  ];

  if (inferenceData.tenor) {
    labels.push({ label: `Benchmark`, value: inferenceData.tenor || '-' });
  }

  if (!delta) {
    // separator 
    labels.push({ type: 'separtor' });

    labels.push(
      { label: `5th Percentile`, value: formatPrice(min || 0, priceType, formatConfig) + ' ' + valueSuffix },
      { label: `95th Percentile`, value: formatPrice(max || 0, priceType, formatConfig) + ' ' + valueSuffix },
    )
  }

  // separator 
  labels.push({ type: 'separtor' });

  // price info
  labels.push({ label: `${labelSuffix}`, value: formatPrice(value, priceType, formatConfig) + ' ' + valueSuffix, id: 'price' });

  return labels;
}


export const getErrorBarsConfig = (isFloatingBarsMode: boolean): Partial<IErrorBarOptions> => {
  let errorBarOptions: Partial<IErrorBarOptions> | undefined;

  if (isFloatingBarsMode) {
    errorBarOptions = {
      errorBarLineWidth: 2,
      errorBarWhiskerLineWidth: 2,
      errorBarWhiskerSize: {
        v: [20, 30],
      },
      errorBarColor: '#fff',
      errorBarWhiskerColor: '#fff',
      errorBarWhiskerRatio: {
        v: [0.5, 0.25],
      },

    }
  } else {
    errorBarOptions = {
      errorBarLineWidth: 1,
      errorBarWhiskerLineWidth: 1,
      errorBarWhiskerSize: {
        v: [20, 30],
      },
      errorBarColor: '#fff',
      errorBarWhiskerColor: '#fff',
    }
  }



  return errorBarOptions
}