import { ReactNode } from "react";
import { useTabsContext } from "./tabs";

export const TabsHeader = ({ 
  children,
}: { 
  children: ReactNode
 }) => {
  const { variant } = useTabsContext();

  const css = {
    'default': 'flex flex-row gap-[0.375rem] items-center sm:gap-[0.625rem]',
    'row': `inline-flex items-center gap-0.5 
        bg-[#343558] p-1
        text-[#89AFEE] text-[0.875rem] text-left
        rounded-[0.5rem]
        `,
  }
  
  return (
    <div className={css[variant]}>
      {children}
    </div>
  );
}