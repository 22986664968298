import { Outlet } from 'react-router-dom';
import DataProvider from './data/dataProvider';
import ApplicationAlerts from './applicationAlerts';
import Header from './header';
import { AlertsController } from './alert/alertsController/alertsController';
import { ToastProvider } from './alert/alertsController/toastProvider';
import { useGetUserPreferencesQuery } from '@/store/api/userPreferences.endpoints';
import { AppLoader } from './components/appLoader';
import { useCaptureError } from '@hooks/useCaptureError';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ConfirmationDialogController } from './components/confirmationDialog/confirmationDialogController';
import { SAndPCopyright } from './components/sAndPCopyright';
import { useUserPreferencesBroadcastChannel } from './userPreferences/hooks/useUserPreferencesBroadcastChannel';

const App = () => {
  const { data, error } = useGetUserPreferencesQuery(); // fetch user preferences
  useCaptureError(error);
  useUserPreferencesBroadcastChannel();

  useEffect(() => {
    if (error) {
      toast.error('We weren\'t able to fetch your preferences. Please try again later.', { toastId: 'fetch-user-preferences-error'});
    }
  }, [error])

  if (!data && !error) {
    // return loader while fetching user preferences as otherwise app will fallback to default preferences for short period of time
    return <AppLoader />;
  }

  return (
    <DataProvider>
      <div className="flex flex-col font-app min-h-screen m-0">
        <Header />
        <Outlet />
      </div>
      <ApplicationAlerts />

      <AlertsController />
      <ToastProvider />
      <ConfirmationDialogController />

      <SAndPCopyright />
    </DataProvider>
  );
}

export default App;
