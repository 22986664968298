import { OnValueChange } from "react-number-format"
import StyledTableNumberInput from "../components/styledTableNumberInput"
import { AlertItem } from "../data/alerts"
import { AlertTableItem } from "./alertsTable"
import { ReactNode, useEffect, useRef, useState } from "react"
import { PRICE_TARGET_FORMAT_MAP } from "./alert.constants"
import { WarningTooltip } from "../components/warningTooltip"

export const AlertTargetInput = ({
  alert,
  disabled,
  disabledReason,
  onChange,
}: {
  alert: AlertTableItem;
  disabled?: boolean;
  disabledReason?: ReactNode | undefined;
  onChange: (alert: Partial<AlertItem> & { id: string }) => void;
}) => {
  const { targetInvalid } = alert;
  const { id, targetValue: tv } = alert.alert

  const lastValidNumberRef = useRef(tv);
  const [localValue, setLocalValue] = useState(tv);

  useEffect(() => {
    if (tv && tv !== localValue) {
      setLocalValue(tv)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tv])
  

  const handleChange: OnValueChange = (values) => {
    const v = values.floatValue;
      
    // update local value
    setLocalValue(v)

    if (typeof v === 'undefined') {
      // do not update alert value if it's emppty
      return
    };

    lastValidNumberRef.current = v; // update last valid number
    onChange({ id, targetValue: v })
  }

  const handleBlur= () => {
    // reset value to last valid number if it's not valid
    if (lastValidNumberRef.current && localValue !== lastValidNumberRef.current) {
      setLocalValue(lastValidNumberRef.current)
    }
  }

  const defaultProps = PRICE_TARGET_FORMAT_MAP[alert.alert.targetType];


  return (
    <div className="relative">
      <StyledTableNumberInput
        name={`alert.${id}.targetValue`}
        value={localValue}
        allowNegative={false}
        {...defaultProps}
        min={0}
        max={10000000}
        required
        invalid={targetInvalid}
        className='max-w-[100px] min-w-[100px]'
        data-target-input={id}
        onValueChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />

      {disabled && disabledReason && (
        <WarningTooltip
          className="text-[#FB275a] absolute w-4 right-1 top-1/2 -translate-y-1/2"
          children={disabledReason}
          id={`alert-${id}-disabled-tooltip`}
        />
      )}
    </div>
  )
}