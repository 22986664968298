import { ReactNode } from 'react';
import Loading from '../../loading';
import Modal from './modal';

const LoadingModal = ({
  show,
  title,
  zIndex,
}: {
  show: boolean;
  title?: ReactNode;
  zIndex?: string;
}) => {

  return (
    <Modal
      body={
        <Loading className="pb-[2rem] pt-[0.25rem]" />
      }
      show={show}
      title={title} 
      zIndex={zIndex}
    />
  );
}

export default LoadingModal;
