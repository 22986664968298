import { useContext, useEffect, useState } from 'react';
import { DataContext, Trade } from "./dataProvider";
import { isEqual } from 'lodash';

const useTrades = (figi: string | undefined, tradeCount: number) => {
  const { send, trades: allTrades } = useContext(DataContext);

  useEffect(() => {
    if (figi && send) {
      send({trade: [{figi, subscribe: true}]});
      return () => { send({trade: [{figi: figi, unsubscribe: true}]}, false); }
    }
  }, [figi, send]);

  useEffect(() => {
    if (figi && tradeCount && send) {
      send({trade: [{figi, index: Array(tradeCount).fill(null).map((_, i) => i - tradeCount)}]});
    }
  }, [figi, tradeCount, send]);

  const [trades, setTrades] = useState<Trade[]>([]);
  useEffect(() => {
    const newTrades = ((figi && allTrades[figi]) || []).slice(-tradeCount);

    setTrades(prev => {
      return isEqual(prev, newTrades) ? prev : newTrades;
    })
  }, [allTrades, figi, tradeCount]);

  return trades;
}

export default useTrades;
