import { AlertItem, AlertResponse } from "../../app/data/alerts";
import { baseApi } from ".";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";

export const alertEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
   endpoints: (builder) => ({
    // Alerts
    getAlertObject: builder.query<AlertResponse, string>({
      query: (id) => `/objects/${id}`,
    }),
    getAlertObjectList: builder.query<AlertResponse[], void>({
      query: () => `/objects?type=alert`,
      providesTags: ['AlertObjectList'],
    }),
    createAlertObject: builder.mutation<AlertResponse, string>({
      query: (name) => ({
        url: '/objects',
        method: 'POST',
        body: {
          type: 'alert',
          metadata: name,
          value: { alerts: [] }
        },
      }),
      invalidatesTags: ['AlertObjectList']
    }),
    updateAlertObject: builder.mutation<AlertResponse, { id: string, version: string, alerts: AlertItem[], optimistic?: boolean }>({
      query: ({ id, version, alerts }) => ({
        url: '/objects',
        method: 'PATCH',
        body: {
          id,
          version,
          value: { alerts }
        },
      }),
      async onQueryStarted( { id, alerts, optimistic }, { dispatch, queryFulfilled }) {
        const o = optimistic ?? true;
        let patchResult: PatchCollection | undefined = undefined;

        if (o) { 
          // optimistic update
          patchResult = dispatch(
            alertEndpoints.util.updateQueryData('getAlertObject', id, (alert) => {
              return {
                ...alert,
                value: {
                  ...alert.value,
                  alerts
                }
              }
            })
          );
        }

        try {
          const result = await queryFulfilled;
          // update data with latest data from response
          
          if (o) {
            // optimistic update - we already have latest data. We need to sync only aws generated data and object `value` we have already up to date
            dispatch(
              alertEndpoints.util.updateQueryData('getAlertObject', id, (r: AlertResponse) => ({
                ...result.data,
                value: r.value,
              }))
            );
          }  else {
            // it wasn't an optimistic update so update the data with the response
            dispatch(
              alertEndpoints.util.updateQueryData('getAlertObject', id, () => result.data)
            );
          }
        } catch (error) {
          if (o) {
            // revert state back if it failed
            patchResult?.undo?.();
          }
        }
      },
    }),
  }),
});

export const { 
  useGetAlertObjectQuery, 
  useLazyGetAlertObjectQuery,
  useGetAlertObjectListQuery,
  useLazyGetAlertObjectListQuery, 
  useCreateAlertObjectMutation,
  useUpdateAlertObjectMutation 
} = alertEndpoints;


