import { createSlice } from "@reduxjs/toolkit";
import { TAppState, dispatcher } from "..";
import { ReactNode } from "react";
import { PA } from "@/types/utility.types";

export type ConfirmationDialogConfig = {
  show?: boolean;
  title?: ReactNode;
  buttonText?: string;
  cancelButtonText?: string;
  onButtonClick?: () => (Promise<void | boolean> | void),
  onCancelClick?: () => void,
  content?: ReactNode,
  onClose?: () => void,
  loading?: boolean;
  hideCancel?: boolean;
}

type  ConfirmationDialogState = {
  config: ConfirmationDialogConfig;
  queue: ConfirmationDialogConfig[];
}


const initialState: ConfirmationDialogState = {
  config: {
    show: false,
  },
  queue: [],
};

const confirmationDialog = createSlice({
  name: "confirmationDialog",
  initialState,
  reducers: {
    openConfirmationDialog: (state, action: PA<Partial<ConfirmationDialogConfig>>) => {
      // pass props to the ConfirmationDialog component
      const { onButtonClick, onClose, cancelButtonText,  onCancelClick, ...rest } = action.payload;
      
      const config = {
        onButtonClick: async (...args: any[]) => {

          if (onButtonClick) {
            // @ts-ignore
            return  onButtonClick?.(...args);
          }
        },
        onClose: () => {
          onClose?.();
          dispatcher(closeConfirmationDialog());
        },
        onCancelClick: !cancelButtonText
          ? undefined
          : () => {
              onCancelClick?.();
              dispatcher(closeConfirmationDialog());
            },
        cancelButtonText,
        ...rest,
        show: true,
      };


      if (state.config.show) {
        // dialog is already opened, add new dialog to the queue
        state.queue.push(config);
      } else {
        state.config = config;
      }
    },
    closeConfirmationDialog: (state) => {
      const [nextDialog, ...restQueue] = state.queue;

      if (nextDialog) {
        state.config = nextDialog;
        state.queue = restQueue
        return;
      }

      state.config.show = false;
    },
    reset: (state) => {

      state.config = {
        show: false,
      }
    },
    resetQueue: (state) => {
      state.queue = [];
    },
    updateConfirmationDialog: (state, { payload }) => {
      state.config = { ...state.config, ...payload }
    }
  },
});

export const {
  closeConfirmationDialog,
} = confirmationDialog.actions;

export const confirmationDialogActions = confirmationDialog.actions;
export const confirmationDialogReducer = confirmationDialog.reducer;

export const confirmationDialogSelector = (state: TAppState) => state.confirmationDialog;
export const selectIsConfirmationDialogOpen = (state: TAppState) => Boolean(state.confirmationDialog.config?.show);