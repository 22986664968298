import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const Prompt = () => {
  return (
    <div className="flex flex-col gap-[1.875rem] px-[0.625rem] py-[2.5rem]">
      <MagnifyingGlassIcon className="text-[#333557] h-[5.0625rem]" />
      <div className="flex flex-col leading-[1.17rem] text-[#DDDDE2] text-[1rem] text-center">
        <span>Start searching for the desired</span>
        <span>Bond information.</span>
      </div>
    </div>
  );
};

export default Prompt;
