import clsx from "clsx"
import { ReactNode } from "react"

export const CircleButton = ({
  children,
  size,
  p,
  className,
  onClick,
}: {
  children: ReactNode;
  size?: string; // size css
  p?: string; // padding
  className?: string;
  onClick?: () => void;
}) => {
  const buttonCss = clsx(
    className,
    'bg-[#5D5F9D] hover:contrast-125 rounded-full  flex justify-center items-center',
    size || 'h-[2.25rem] w-[2.25rem] min-w-[2.25rem]',
    p || 'p-[0.5625rem]'
  )

  return (
    <button
      type="button"
      className={buttonCss}
      onClick={onClick}
    >
      {children}
    </button>
  )
}