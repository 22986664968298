import { MouseEventHandler } from "react";

export interface LinkToggleProps {
  'aria-label': string;
  count?: number;
  linked: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const LinkToggle = ({ count, linked, ...rest}: LinkToggleProps) =>
  <button
  {...rest}
    className="cursor-auto flex flex-row h-[1.5rem] items-center justify-center rounded-full w-[1.5rem] relative"
  >
    {
      linked
        ? <svg
          className="h-[1.25rem] outline-none pointer-events-none stroke-[#FBFBFD] w-[1.25rem]"
          fill="none"
          viewBox="0 0 21 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.07617 14H6.07617C4.96951 14 4.02617 13.6095 3.24617 12.8286C2.46617 12.0477 2.07617 11.1033 2.07617 9.99529C2.07617 8.88732 2.46617 7.94444 3.24617 7.16667C4.02617 6.38889 4.96951 6 6.07617 6H9.07617V7.5H6.07617C5.38173 7.5 4.79145 7.74306 4.30534 8.22917C3.81923 8.71528 3.57617 9.30556 3.57617 10C3.57617 10.6944 3.81923 11.2847 4.30534 11.7708C4.79145 12.2569 5.38173 12.5 6.07617 12.5H9.07617V14ZM7.07617 10.75V9.25H13.0762V10.75H7.07617ZM11.0762 14V12.5H14.0762C14.7706 12.5 15.3609 12.2569 15.847 11.7708C16.3331 11.2847 16.5762 10.6944 16.5762 10C16.5762 9.30556 16.3331 8.71528 15.847 8.22917C15.3609 7.74306 14.7706 7.5 14.0762 7.5H11.0762V6H14.0762C15.1828 6 16.1262 6.39046 16.9062 7.17138C17.6862 7.95229 18.0762 8.89674 18.0762 10.0047C18.0762 11.1127 17.6862 12.0556 16.9062 12.8333C16.1262 13.6111 15.1828 14 14.0762 14H11.0762Z" fill="white" />
        </svg>
        : <svg
          className="h-[1rem] outline-none pointer-events-none stroke-[#A5A6C9] w-[1rem]"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.7083 11.5833L13.5417 10.4375C14.1219 10.2806 14.5934 9.97748 14.956 9.52815C15.3187 9.07883 15.5 8.56944 15.5 8C15.5 7.30556 15.2569 6.71528 14.7708 6.22917C14.2847 5.74306 13.6944 5.5 13 5.5H10V4H13C14.1067 4 15.05 4.39044 15.83 5.17133C16.61 5.95221 17 6.89661 17 8.00454C17 8.7654 16.7951 9.46528 16.3854 10.1042C15.9757 10.7431 15.4167 11.2361 14.7083 11.5833ZM11.875 8.75L10.375 7.25H12V8.75H11.875ZM15 16.125L0.875 2L1.9375 0.9375L16.0625 15.0625L15 16.125ZM8 12H5C3.89333 12 2.95 11.61 2.17 10.83C1.39 10.05 1 9.10667 1 8C1 7.11206 1.26389 6.32063 1.79167 5.62571C2.31944 4.93079 3.06944 4.38194 4.04167 3.97917L5.5 5.5H5.02083C4.3125 5.5 3.71528 5.74306 3.22917 6.22917C2.74306 6.71528 2.5 7.30556 2.5 8C2.5 8.69444 2.74306 9.28472 3.22917 9.77083C3.71528 10.2569 4.30556 10.5 5 10.5H8V12ZM6 8.75V7.25H7.16667L8.64583 8.75H6Z" fill="#A5A6C9" />
        </svg>
    }

    {typeof count === 'number' && count > 0 && (
      <div className="h-3.5 px-[3px] bg-[#EDEDF4] rounded-[0.625rem] items-center flex text-[#1F2034] text-[0.625rem] font-medium absolute left-[100%] top-0">
      {count}
    </div>
    )}
  </button>;

export default LinkToggle;
