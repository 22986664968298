import { BroadcastEventData, useBroadcastChannelListener } from "@/app/alert/hooks/useBroadcastChannel";
import { BroadcastChannelName } from "@/constants";
import { preferencesEndpoints } from "@/store/api/userPreferences.endpoints";
import { UserPreferences } from "@/types/types";
import { useDispatch } from "react-redux";

// syncs data with the latest data from the broadcast channel
export const useUserPreferencesBroadcastChannel = () => {
  const dispatch = useDispatch();
  
  useBroadcastChannelListener<BroadcastEventData<UserPreferences>>(
    BroadcastChannelName.UserPreferencesUpdate,
    async (data) => {
      const action = preferencesEndpoints.util.updateQueryData('getUserPreferences', undefined, () => data.data);
      dispatch(action as any)
    },
    []
  )
}