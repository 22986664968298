import { CsvDataRow } from "@/types/utility.types";
import { dataRowsToCsv, removeCsvEmptyLines } from "@/utils/csv.utils";
import { captureException } from "@sentry/react";
import { DependencyList, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { TableRefData } from "../table";

export const useTableToCsv = <Item, Key = string, ArgsData = unknown>({
  getColumnTitle,
  parseValue,
  filterColumn,
  filterRow = () => true,
  parseCsv,
  removeEmptyLines,
}: {
  getColumnTitle: (columnKey: Key) => string,
  parseValue?: (data: {
    value: any,
    columnKey: Key,
    item: Item,
  }) => string | number | undefined,
  filterColumn?: (data: { data: ArgsData | undefined; columnKey: Key, isHeader?: boolean }) => boolean
  filterRow?: (data: { data: Record<string, string | number | undefined>; item: Item }) => boolean
  parseCsv?: (csv: string) => string
  removeEmptyLines?: boolean
}, deps: DependencyList = []) => {

  const tableRef = useRef<TableRefData<Item, Key> | null>(null);
  useEffect(() => {
    tableRef.current = null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)


  function handleCopyClick(data?: ArgsData) {
    const rows = getCsvRows(data);

    if (!rows) {
      toast('No data to copy', { toastId: 'NO_DATA_TO_COPY' });
      return;
    }

    let csv = dataRowsToCsv(rows, '\t');

    if (removeEmptyLines) {
      csv = removeCsvEmptyLines(csv);
    }

    if (parseCsv) {
      csv = parseCsv(csv);
    }

    try {
      navigator.clipboard.writeText(csv);
      toast('Data copied to clipboard', { toastId: 'COPY_SUCCESS' });
    } catch (e) {
      captureException(e);
      toast.error('Failed to copy data', { toastId: 'COPY_FAILED' });
    }
  }

  function getCsvRows(data?: ArgsData) {
    const tableData = tableRef.current?.getPrintData();
    if (!tableData) {
      return;
    }

    const csvRows: CsvDataRow[] = [];
    const headers = tableData.headers.map(key => {
      if (filterColumn && !filterColumn({ data, columnKey: key, isHeader: true })) {
        return null;
      }

      return getColumnTitle(key);
    }).filter(Boolean) as string[];
    
    csvRows.push(headers);

    const rows = tableData.rows
      .filter(filterRow)
      .map(row => {
      const dataArr = [];
      for (const key of tableData.headers) {
        if (filterColumn && !filterColumn({ data: data, columnKey: key })) {
          continue;
        }

        const value = row.data[key as string];
        const parsedValue = parseValue ? parseValue({ value, columnKey: key, item: row.item }) : value;
        dataArr.push(parsedValue);
      }

      return dataArr;
    });
    csvRows.push(...rows);

    return csvRows;
  }

  return {
    tableRef,
    handleCopyClick,
    getCsvRows
  }
}
