import { useDispatch, useSelector } from "react-redux";
import { ONE_SECOND } from "../../../../constants";
import { getSnoozeLabel, stopPropagation } from "../../alert.utils"
import { alertActions, selectSnoozeDefaultMs } from "../../../../store/slices/alert.slice";
import { ReactNode, memo } from "react";
import { SnoozeMap, SnoozeReverseMap } from "../../alert.constants";
import TableListBoxV2Base from "../../../components/listbox/tableListBoxV2/tableListBoxV2Base";
import clsx from "clsx";

export const AlertSnoozeButton = memo(({
  className,
  onSnooze,
  renderLabel = (d) => `Snooze ${d}`,
}: {
  className?: string
  onSnooze: (durationMs: number) => void;
  renderLabel?: (durationLabel: string) => ReactNode;
}) => {
  const defaultSnoozeDurationMs = useSelector(selectSnoozeDefaultMs);

  return (
    <div className={clsx('flex items-center', className)} onClick={stopPropagation}>
      <button
        type="button"
        className="p-2.5 text-[#8183B3] hover:text-[#9fa1d2] flex items-center"
        // onClick={() => onSnooze(20 * ONE_SECOND)}
        onClick={() => onSnooze(defaultSnoozeDurationMs)}
      >
        {renderLabel(getSnoozeLabel(defaultSnoozeDurationMs))}
      </button>
      <div className="text-[#8183B3] hover:text-[#9fa1d2]">
        <SnoozeDropdown />
      </div>
    </div>
  )
})

// <SnoozeDropdown /> -> selects default duration
function SnoozeDropdown() {
  const dispatch = useDispatch();

  const defaultSnoozeDurationMs = useSelector(selectSnoozeDefaultMs);
  const values = Object.keys(SnoozeMap) as (keyof typeof SnoozeMap)[];
  const value = SnoozeReverseMap[defaultSnoozeDurationMs]

  return (
    <TableListBoxV2Base
      className="h-[1.5rem] w-[1.5rem] bg-[#252641] !justify-center"
      renderValue={() => ''}
      renderOption={({ value }) => `${value}`}
      selectValue={(value) => {
        const snoozeMs = SnoozeMap[value];
        if (typeof snoozeMs === 'number') {
          // update default snooze duration
          dispatch(alertActions.setSnoozeDefaultMs(snoozeMs));
        }
      }}
      value={value}
      options={values}
      popperProps={{
        placement: 'bottom-end',
        strategy: 'fixed',
        portal: true,
        zIndex: 1001,
      }}
    />
  )
}