import { InferenceResult } from "@/hooks/data/useSimpleInferenceData";
import { Bond } from "@data/bondIndex";
import { memo, useState } from "react";
import { PriceType } from "@/types/types";
import { PriceTypeListBox } from "@components/listbox/priceTypeListBox";
import { Side } from "@data/api";
import { SideListBox } from "@components/listbox/sideListBox";
import { Checkbox } from "@components/checkbox";
import { Chart } from "./components/chart/chart";
import { CurveChart } from "./components/curveChart/curveChart";
import { Tabs } from "../tabs/tabs";
import { TabsHeader } from "../tabs/tabsHeader";
import { Tab } from "../tabs/tab";
import { FaChartBar, FaChartLine } from "react-icons/fa";
import useLocalStorage from "@/hooks/useLocalStorage";
import { LocalStorageKey } from "@/constants";

export const BondsChart = memo(({
  bonds,
  inferenceResult,
  side,
  priceType,
  onSideChange,
  onPriceTypeChange,
}: {
  bonds: Bond[];
  inferenceResult: InferenceResult<Bond>['data'];
  side: Side
  priceType: PriceType;
  onSideChange: (side: Side) => void
  onPriceTypeChange: (priceType: PriceType) => void;
}) => {
  const [delta, setDelta] = useState(false)
  const [chartType, setChartType] = useLocalStorage<'bar' | 'curve'>(LocalStorageKey.BondsChartType, 'bar');


  const chartTypeSwitcher = (
    <Tabs
      active={chartType}
      onChange={(t) => setChartType(t)}
      variant="row"
    >
      <TabsHeader>
        <Tab value={'bar'}>
          <div className='h-6 flex items-center'>
            <FaChartBar />
          </div>
        </Tab>
        <Tab value={'curve'}>
          <div className='h-6 flex items-center'>
            <FaChartLine />
          </div>
        </Tab>
      </TabsHeader>
    </Tabs>
  )

  const ChartComponent = chartType === 'bar' ? Chart : CurveChart;

  return (
    <div>
      <div className="flex gap-2.5 items-center flex-wrap justify-between">
        <div className="flex gap-2.5 items-center">
          <div className="flex gap-2.5 min-w-[20rem]">
            <SideListBox side={side} onChange={onSideChange} />
            <PriceTypeListBox priceType={priceType} onChange={onPriceTypeChange} />
          </div>
          <Checkbox
            ariaLabel='delta'
            label={<>&#916;</>}
            checked={delta}
            onClick={() => setDelta(!delta)}
          />
        </div>

        {chartTypeSwitcher}
      </div>

      <ChartComponent
        className="pt-2 mt-2"
        bonds={bonds}
        inferenceResult={inferenceResult}
        side={side}
        priceType={priceType}
        delta={delta}
      />
    </div>
  )
})