import clsx from 'clsx';
import { SortItem } from './hooks/useTableSort';
import { FaXmark, FaGripVertical } from "react-icons/fa6";
import { SortableList } from '../dnd/sortableList';
import { DraggableItem } from '../dnd/draggableItem';
import { UniqueIdentifier } from '@dnd-kit/core';
import { ReactNode } from 'react';

export const TableSortPriority = <T = UniqueIdentifier>({ 
  className,
  getColumnFilterLabel,
  onRemove,
  onSort,
  sort,
}: { 
  className?: string;
  getColumnFilterLabel?: (columnName: T) => ReactNode;
  onRemove: (column: T) => void;
  onSort: (sort: SortItem<T>[]) => void;
  sort: SortItem<T>[];
}) => {

  function renderItem(item: SortItem<T>) {
    return (
      <SortItemElement 
        key={item.column as string} 
        item={item} 
        onRemove={onRemove}  
        getColumnFilterLabel={getColumnFilterLabel}
      />
    )
  }

  return (
    <div className={clsx('flex bg-[#1F2034] rounded-[1.875rem] justify-start items-center', className)}>
      
      <div className="self-stretch px-5 bg-[#0A0B11] justify-center items-center flex rounded-l-[1.875rem] whitespace-nowrap min-h-[3.5rem]">
        <div className="text-[#C9CADE] text-sm font-medium">High Priority</div>
      </div>

      <SortableList<any>
        items={sort}
        renderItem={renderItem}
        onChange={onSort}
      >
        <div className="self-stretch p-2.5 items-start gap-2.5 flex flex-wrap">
          {sort.map(s => (
            <DraggableItem key={s.id as string} id={s.id as string} >
              {renderItem(s)}
            </DraggableItem>
          ))}
        </div>
      </SortableList>
      
    </div>
  )
}

// <SortItemElement />
const SortItemElement = <T = string>({
  item,
  getColumnFilterLabel,
  onRemove,
}: {
  item: SortItem<T>;
  getColumnFilterLabel?: (columnName: T) => ReactNode;
  onRemove: (column: T) => void;
})  => {
  const text = getColumnFilterLabel ? getColumnFilterLabel(item.column) : item.column as string;

  return (
    <div className="h-9 px-2 py-2 bg-[#484A7A] rounded-[6.25rem] justify-start items-center gap-1 flex group">
      <span className='p-1 text-[0.8125rem] text-[#C9CADE] cursor-pointer flex group-hover:text-[#FBFBFD]'>
        <FaGripVertical />
      </span>
      
      <div className="text-gray-50 text-sm font-medium whitespace-nowrap">
        {text}
      </div>

      <span 
        className="flex p-1 text-[1rem] hover:scale-125 cursor-pointer" 
        onClick={() => onRemove(item.column)}
      >
        <FaXmark  />
      </span>
    </div>
  )
}