import { useMemo } from "react";
import useInferences, { InferenceRequest } from "../../data/useInferences";
import { AlertTableItem } from "../alertsTable";
import { AtsIndicator, Side } from "../../data/api";
import { isTargetAlert } from "../alert.utils";
import { AlertTargetTypeToPriceType } from "../alert.constants";

// configures getInference for alerts
export const useGetAlertInference = (alerts: AlertTableItem[]) => {
  const inferenceRequests: InferenceRequest[] = useMemo(() => {
    
    return alerts.map(a => {
      let side: Side;
      
      if (isTargetAlert(a.alert)) {
        // for alerts when bid is selected the offer level is the current level and vice versa. That's why when alert is for `bid` we want to feth inference for `offer`
        // side = a.alert.side === Side.bid ? Side.offer : Side.bid;
        side = a.alert.side;
      } else {
        side = a.alert.side;
      }
      
      const rfq_label = AlertTargetTypeToPriceType[a.alert.targetType]
      const inferenceRequest: InferenceRequest = { 
        figi: a.alert.figi, 
        atsIndicator: AtsIndicator.N, 
        quantity: a.alert.size, 
        side,
        rfq_label,
      } 
      
      return inferenceRequest;
    })
  }, [alerts]);

  const [getInference] = useInferences(inferenceRequests);

  return getInference;
}