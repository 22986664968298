import { CsvDataRow } from "@/types/utility.types";
import { downloadFile } from "./downloadFile";
import { captureMessage } from "@sentry/react";

export const dataRowsToCsv = (dataRow: CsvDataRow[], colSeparator = ',') => {
  return dataRow.map(r => r.map(v =>typeof v === 'string' &&  v.indexOf(',') >= 0 ? `"${v}"` : v).join(colSeparator)).join("\n")
}

export const downloadCsv = (filename: string, data: CsvDataRow[]) => {
  if (!data) {
    captureMessage('Trying to download csv file without data');
    return;
  }

  if (!filename) {
    filename = 'download.csv';
  }

  if (!filename.endsWith('.csv')) {
    filename += '.csv';
  }

  const csvContent = "data:text/csv;charset=utf-8," + dataRowsToCsv(data);
  const href = encodeURI(csvContent);
  downloadFile(filename, href)
}

export const removeCsvEmptyLines = (csvStr: string) => {
  return csvStr.split('\n').filter(r => r.trim().length > 0).join('\n');
}