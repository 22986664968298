import { dispatcher } from '@/store';
import { ConfirmationDialogConfig, confirmationDialogActions } from '@/store/slices/confirmationDialog.slice';

export const openConfirmationDialog = (config: ConfirmationDialogConfig) => {
  dispatcher(confirmationDialogActions.openConfirmationDialog(config));
};

export const openYesNoDialog = (config: ConfirmationDialogConfig) => {
  dispatcher(
    confirmationDialogActions.openConfirmationDialog({
      cancelButtonText: 'No',
      buttonText: 'Yes',
      ...config,
    })
  );
};

export const openYesNoDialogPromise = async (config: ConfirmationDialogConfig) => {
  return new Promise(resolve => {
    openYesNoDialog({
      ...config,
      onButtonClick: () => resolve(true),
      onCancelClick: () => resolve(false),
    })
  })
}

export const openConfirmationDialogPromise = async (config: ConfirmationDialogConfig) => {
  return new Promise(resolve => {
    openConfirmationDialog({
      ...config,
      onButtonClick: () => resolve(true),
      onCancelClick: () => resolve(false),
    })
  })
}


export const closeConfirmationDialog = () => {
  dispatcher(confirmationDialogActions.closeConfirmationDialog());
}

export const clearConfirmationDialog = () => {
  dispatcher(confirmationDialogActions.closeConfirmationDialog());
  dispatcher(confirmationDialogActions.reset());
  dispatcher(confirmationDialogActions.resetQueue());
}

