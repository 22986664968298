import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaTimes } from 'react-icons/fa';
import clsx from 'clsx';

const animationConfigMap = {
  left: {
    enterFrom: '-translate-x-full',
    enterTo: 'translate-x-0',
    leaveFrom: 'translate-x-0',
    leaveTo: '-translate-x-full',
  },
  right: {
    enterFrom: 'translate-x-full',
    enterTo: 'translate-x-0',
    leaveFrom: 'translate-x-0',
    leaveTo: 'translate-x-full',
  },
}

export const Drawer = ({
  hideBackdrop,
  children,
  open, 
  onClose,
  panelCss,
  position = 'left',
  top = 'top-0',
}: {
  hideBackdrop?: boolean;
  children: ReactNode;
  open: boolean; 
  onClose: () => void;
  panelCss?: string;
  position?: 'left' | 'right';
  top?: string;
}) => {
  const animationConfig = animationConfigMap[position]; 

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[10]" onClose={onClose} >
        {!hideBackdrop && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000]/[0.80]" />
          </Transition.Child>
        )}
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-300"
          leave="transform transition ease-in-out duration-300"
          {...animationConfig}
        >
          <Dialog.Panel className={clsx(
            'bg-[#333557] bottom-0 fixed flex flex-col overflow-y-auto', 
            panelCss, 
            top,
            {
              'left-0 shadow-xl': position === 'left',
              'right-0 shadow-drawer-right': position === 'right',
            }
          )}>
            

            <button type="button" className="absolute p-2 right-0.5 top-0.5" onClick={onClose}> 
              <FaTimes />
            </button>
            
            {children}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

