import { PriceType } from "@/types/types";
import { AtsIndicator, Side } from "./api";
import { Percentile } from "@/constants";

export const createInferenceKey = (figi: string, atsIndicator: AtsIndicator, quantity: number, side: Side, rfq_label: PriceType) => {
  return `${figi}_${atsIndicator}_${quantity}_${side}_${rfq_label}`;
};

export const getQuantileIndexV2 = (percentile: Percentile, side: Side, priceType: PriceType) => {
  const percentileNum = +percentile
  const p = priceType === PriceType.Price
    ? side === Side.offer ? 100 - percentileNum : percentileNum
    : side === Side.bid ? 100 - percentileNum : percentileNum;

  return p / 5 - 1;
};