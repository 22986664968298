export const selectFile = async (accept: string): Promise<File | null> => {
  // TODO: check if we need to remove the input element after the file is selected

  return new Promise((resolve) => {

    function fileSelectionCancelled() {
      resolve(null)
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept; // Specify the file types you want to accept
    input.onchange = () => {
      window.removeEventListener('click', fileSelectionCancelled)

      if (input.files && input.files.length > 0) {
        resolve(input.files[0]);
      } else {
        resolve(null);
      }
    };
    input.click();

    // detect any click on window which will cancel the file selection
    // window.addEventListener('click', fileSelectionCancelled)
  });
}