import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { setStore } from "./getStore";
import { baseApi } from "./api";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware),
});

setStore(store);

export const useDispatch = () => useReduxDispatch();

export const dispatcher = store.dispatch;

export default store;

export type TAppState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<TAppState> = useReduxSelector;

export const getStore = () => store;
export const getAppState = () => store.getState();

