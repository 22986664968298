import { memo } from "react";
import { NumericFormat } from "react-number-format";

export const FormattedYtm = memo(({ value, className }: { value: string | number | null | undefined; className?: string }) => {
  return (
    <NumericFormat
      allowLeadingZeros
      value={value}
      displayType='text'
      defaultValue='-'
      thousandSeparator=","
      allowedDecimalSeparators={['.', ',']}
      decimalScale={2}
      suffix="%"
      fixedDecimalScale
      className={className}
    />
  )
})