import { selectTriggeredAlertsCount } from "@/store/slices/alert.slice";
import { useSelector } from "react-redux";

export const AlertsCounterMobile = () => {
  const triggeredAlertsCount = useSelector(selectTriggeredAlertsCount);

  if (!triggeredAlertsCount) {
    return null;
  }

  return (
    <div className="bg-[#EDEDF4] text-[#1F2034] text-[0.75rem] flex px-2 py-0.5 rounded-[0.625rem]">
      {triggeredAlertsCount}
    </div>
  )
}