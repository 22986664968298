
import * as Sentry from '@sentry/react';
import { APP_ENV, APP_RELEASE } from '../constants';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export const intiSentry = () => {
  Sentry.init({
    dsn: "https://1a96e7210e5dfe49aaa7169d09afb2fa@o4507057627136000.ingest.us.sentry.io/4507061891694592",
    enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
    environment: APP_ENV,
    release: APP_RELEASE,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["deepmm.com", 'dev.deepmm.com'],

  });
};

