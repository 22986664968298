import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLatestRef } from './useLatestRef';

const getLocalStorageItem = <T>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// hook which saves state in localStorage. Hooks is synced between tabs instantly
const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (v: T) => void] => {
  const initialLSValue = useMemo(() => {
    return getLocalStorageItem<T>(key);
  }, [key])


  const [storedValue, setStoredValue] = useState<T>(
    initialLSValue !== null ? initialLSValue : initialValue
  );

  const storedValueRef = useLatestRef(storedValue);
  useEffect(() => {
    const handleStoredValueChange = () => {
      const value = getLocalStorageItem(key);
      if (!isEqual(value, storedValueRef.current)) {
        setStoredValue(value as T);
      }
    };

    window.addEventListener('storage', handleStoredValueChange);

    return () => {
      window.removeEventListener('storage', handleStoredValueChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
