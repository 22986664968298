import { NUMBER_FORMAT_MAP, ONE_MILLION, ONE_THOUSAND, SAndPRating } from "@/constants";
import { PriceType } from "@/types/types";
import { NumericFormatProps, numericFormatter as reactNumericFormatter } from "react-number-format";

export const toFixed = (num: number | string, count: number = 2) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return parseFloat(num.toFixed(count));
};

export const priceOrString = (price: number | undefined) => {
  const v = price ?? '';
  return typeof v === 'string' ? v : toFixed(v, 3);
}

export const formatPrice = (price: number | undefined, priceType = PriceType.Price, config?: NumericFormatProps): string => {
  const format = NUMBER_FORMAT_MAP[priceType];

  if (typeof price !== 'number') {
    return '';
  }


  return reactNumericFormatter(`${price}`, { ...format, ...config })
}

export const formatAmountOutstanding = (value: number | undefined): string => {
  if (typeof value !== 'number') {
    return '-';
  }

  // MM -> means millions
  const suffix = value === 0
    ? ''
    : value >= ONE_MILLION
      ? ' MM'
      : ' K';
  const _value = value >= ONE_MILLION ? value / ONE_MILLION : value / ONE_THOUSAND;

  return reactNumericFormatter(`${_value}`, {
    thousandSeparator: ',',
    decimalScale: 0,
    fixedDecimalScale: true,
    suffix,
  });
}

export const numberOrUndefined = (value: number) => {
  return isNaN(value) ? undefined : value;
}

export const roundToEighth = (value: number) => Math.round(value * 8) / 8;

export const roundToDecimals = (value: number, decimals: number) => {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}

export const formatCoupon = (value: number) => {
  return value.toFixed(3) + '%'
}

export const parenthesisNumber = (value: number) => {
  return value < 0 ? `(${Math.abs(value)})` : value;
}

export const formatDelta = (value: number) => {
  return parenthesisNumber(value);
}

export type CustomNumericFormatProps = NumericFormatProps & {
  parenthesis?: boolean; // show parenthesis for negative values. -> `($99)` instead of `-$99`
}

export const numericFormatter = (value: number, format: CustomNumericFormatProps) => {
  const { parenthesis, ...rest } = format;
  const isNegative = value < 0;
  const v = parenthesis ? Math.abs(value) : value;

  const formatted = reactNumericFormatter(`${v}`, rest);


  return parenthesis && isNegative ? `(${formatted})` : formatted;
}


export const getSAndPNumericValue = (rating: string) => {
  return SAndPRating[rating as keyof typeof SAndPRating] ?? 0;
}

export const convertRatingToSAndP = (rating: number): string => {
  const intValue = Math.round(rating);
  return Object.entries(SAndPRating).find(([name, value]) => value === intValue)?.[0] || '';
}

export const getNumber = (value: number | undefined, defaultValue: number = -Infinity) => {
  return typeof value === 'number' ? value : defaultValue;
}
