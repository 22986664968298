import { memo } from "react";
import { useSelector } from "react-redux";
import { selectTriggeredAlertsCount } from "../../store/slices/alert.slice";

export const AlertsCounter = memo(() => {
  const triggeredAlertsCount = useSelector(selectTriggeredAlertsCount);

  if (triggeredAlertsCount === 0) {
    return null;
  }


  return (
    <div className="h-3.5 px-[3px] bg-[#EDEDF4] rounded-[0.625rem] items-center flex text-[#1F2034] text-[0.625rem] font-medium absolute right-0 top-0 translate-x-1/2 ">
      {triggeredAlertsCount}
    </div>
  )
})