import WebSocketDeactivatedAlert from './webSocketDeactivatedAlert';
import ApplicationErrorsNotification from './applicationErrorsNotification';

const ApplicationAlerts = () =>
  <>
    <div
      aria-live="assertive"
      className="flex fixed inset-0 items-start pointer-events-none px-[1rem] py-[1.25rem] z-[9] sm:p-[1.25rem]"
    >
      <div className="flex flex-col items-end justify-end space-y-[1rem] w-full mt-auto">
        <ApplicationErrorsNotification />
      </div>
    </div>
    <WebSocketDeactivatedAlert />
  </>;

export default ApplicationAlerts;
