import { useState, useEffect, useRef } from 'react';

const getWindowDimensions = () => ({ width: window.innerWidth, height: window.innerHeight });

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const setTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    const handleResize = () => {
      if (setTimeoutRef.current !== null) {
        clearTimeout(setTimeoutRef.current);
      }
      setTimeoutRef.current = setTimeout(() => void setWindowDimensions(getWindowDimensions()), 200);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (setTimeoutRef.current !== null) {
        clearTimeout(setTimeoutRef.current);
      }
    }
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;
