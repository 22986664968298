import { useEffect, useRef, useState } from "react";
import { broadcastState, createBroadcastChannel } from "../../../utils/broadcastChannel.utils";

/**
 * Our hook will return an object with three properties:
 * - send: a function that will send a message to all other tabs
 * - state: the current state of the broadcast
 * - subscribe: a function that will subscribe to the broadcast (Only if options.subscribe is true)
 */
export type UseBroadcastChannelReturn<T> = {
  send: (val: T) => void;
  state: T | undefined;
  subscribe: (callback: (e: T) => void) => () => void;
};

export type BroadcastEventData<T> = {
  tabId: string;
  data: T;
}

export type UseBroadcastChannelOptions = {
  subscribe?: boolean;
};

/**
 *
 * @param name The name of the broadcast channel
 * @param val The initial value of the broadcast
 * @returns Returns an object with three properties: send, state and subscribe
 */
export const useBroadcastChannel = <T>(
  name: string,
  val?: T,
  options?: UseBroadcastChannelOptions
): UseBroadcastChannelReturn<T> => {
  const [state, setState] = useState<T | undefined>(val);
  const channelRef = useRef<BroadcastChannel | null>(null);
  const listeners = useRef<((e: T) => void)[]>([]);

  const send = (val: T) => {
    if (!channelRef.current) {
      return;
    }

    channelRef.current.postMessage(val);

    if (!options?.subscribe) {
      setState(val);
    }

    listeners.current.forEach((listener) => listener(val));
  };

  const subscribe = (callback: (e: T) => void) => {
    listeners.current.push(callback);
    return () =>
      listeners.current.splice(listeners.current.indexOf(callback), 1);
  };

  useEffect(() => {
    if (!channelRef.current) {
      const channel = createBroadcastChannel(name)

      if (!channel) {
        return;
      }

      channelRef.current = channel;
    }

    channelRef.current.onmessage = (e) => {

      if (e.data.tabId === broadcastState.tabId) {
        // ignore events from current tab
        return;
      }

      if (!options?.subscribe) {
        setState(e.data);
      }

      listeners.current.forEach((listener) => listener(e.data));
    };

    return () => {
      if (!channelRef.current) {
        return;
      }

      channelRef.current.close();
      channelRef.current = null;
    };
  }, [name, options]);

  return { send, state, subscribe };
};

export const useBroadcastChannelListener = <T,>(channelName: string, callback: (data: T) => void, deps: unknown[]) => {
  const { subscribe } = useBroadcastChannel<T>(channelName)

  useEffect(() => {
    const unsubscribe = subscribe(callback)

    return () => unsubscribe()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}