import { Portal } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

export function WarningTooltip({ 
  children, 
  className,
  id,
}: { 
  children: React.ReactNode, 
  className?: string 
  id: string, 
}) {
  return (
    <>
      <ExclamationTriangleIcon
        className={className}
        data-tooltip-id={id}
      />
      <Portal>
        <Tooltip
          id={id}
          place='top'
          style={{ zIndex: 50 }}
        >
          {children}
        </Tooltip>
      </Portal>
    </>
  )
}