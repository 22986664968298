import { useCallback, useEffect } from "react";
import { alertEndpoints, useUpdateAlertObjectMutation } from "../../../../store/api/alert.endpoints";
import { AlertTableItem } from "../../alertsTable";
import { broadcastMessage } from "../../../../utils/broadcastChannel.utils";
import { BroadcastChannelName } from "../../../../constants";
import { AlertItem, AlertState } from "../../../data/alerts";
import { useDispatch } from "react-redux";
import { getAppState } from "../../../../store";
import { useAlertObject } from "../../hooks/useAlertObject";
import { selectAlerts } from "../../../../store/slices/alert.slice";
import { debounce, keyBy } from "lodash";
import { useLatestRef } from "../../../../hooks/useLatestRef";
import { captureException } from "@sentry/core";

export type PartialUpdateData = Partial<AlertItem> & { id: string };

type Ref = {
  version: string | null;
  saveInProgress: { [alertId: string]: boolean };
  data: { [alertId: string]: AlertTableItem[] | AlertItem[] | null };
}

const ref: Ref = {
  version: null,
  saveInProgress: {},
  data: {},
}

export const useSyncAlerts = () => {
  const dispatch = useDispatch();
  const [updateAlertsApi] = useUpdateAlertObjectMutation();
  const { alertObjectDetails } = useAlertObject();

  const alertObjectDetailsRef = useLatestRef(alertObjectDetails);

  useEffect(() => {
    // sync version with latest if alert object was changed wihtout using `syncAlerts` function, for exmaple if alert object was updated from another tab
    const v = alertObjectDetails?.version
    if (v && v !== ref.version) {
      ref.version = v
    }
  }, [alertObjectDetails?.version])

  const syncAlertsDebouncedApi = useCallback(
    debounce(async (alerts: AlertItem[]) => {
      const id = alertObjectDetailsRef.current?.id as string;

      if (ref.saveInProgress[id]) {
        // last save is still in progress. Sync will be triggered with latest data after previous request is done
        return;
      }

      const latestVersion = ref.version || alertObjectDetailsRef.current?.version as string;
      ref.data[id] = null;
      ref.saveInProgress[id] = true;
  
      try {
        const data = await updateAlertsApi({ id, version: latestVersion!, alerts }).unwrap();
        broadcastMessage(BroadcastChannelName.AlertUpdate, data)
        ref.version = data.version; // save latest version
      } catch (e) {
        captureException(e);
        // TODO: do we want to log an error here? or maybe retry it if there is no data waiting and show an error after retry?
      }
      
      ref.saveInProgress[id] = false;
  
      // trigger sync alerts again if there are new changes
      const latestAlertsData = ref.data[id];
      if (latestAlertsData) {
        syncAlerts(latestAlertsData);
      }
        
    }, 300)
  , [])

  const syncAlerts = (alerts: AlertTableItem[] | AlertItem[]) => {
    const id = alertObjectDetailsRef.current?.id as string;
    const aList = getAlertItems(alerts);
    updateStoredQueryData(aList, id) // sync data in store so it has latest data
    ref.data[id] = aList; // save latest data for request

    syncAlertsDebouncedApi(aList);
  }

  function updateStoredQueryData(alerts: AlertItem[], alertObjectId: string) {
    const cachedData = alertEndpoints.endpoints.getAlertObject.select(alertObjectId)(getAppState());


    if (!cachedData.data) {
      return;
    }

    const d = {
      ...cachedData.data,
      value: {
        ...cachedData?.data?.value,
        alerts,
      }
    }

    const action = alertEndpoints.util.updateQueryData('getAlertObject', d.id, () => d);
    dispatch(action as any)
  }

  // accepts updated alert partial data and updates it in the store
  const updateAlert = (alertData: PartialUpdateData, alertIds?: Set<string>) => {
    // update alert configuration
    const prev = selectAlerts(getAppState());
    const { id, ...upd} = alertData;;

    const newAlerts = prev.map(a => {
      const shouldUpdate = alertIds?.size 
        ? alertIds.has(a.alert.id)
        : a.alert.id === alertData.id;

      if (shouldUpdate) {
        const updAlert: AlertTableItem['alert'] = { 
          ...a.alert, 
          ...upd, 
          state: upd.state || AlertState.Active,
          id: a.alert.id, // keep it as the last line so id is not overwritten
        }

        if (updAlert.targetType !== a.alert.targetType) {
          updAlert.targetValue = undefined;
        }

        const updatedAlert: AlertTableItem = {
          ...a,
          alert: updAlert,
          targetInvalid: updAlert.targetValue === undefined,
        }

        return updatedAlert;
      }
      return a;
    })

    syncAlerts(newAlerts);
  }


  // deleteAlert
  const deleteAlert = async (alertId: string) => {
    if (!alertObjectDetails?.id) {
      return;
    }

    const prev = selectAlerts(getAppState());
    const newAlerts = prev.filter(a => a.alert.id !== alertId);

    syncAlerts(newAlerts);
  }

  // deleteAlerts
  const deleteAlerts = async (ids: Set<string>) => {
    if (!alertObjectDetails?.id) {
      return;
    }

    const prev = selectAlerts(getAppState());
    const newAlerts = prev.filter(a => !ids.has(a.alert.id));

    syncAlerts(newAlerts);
  }

  // updateAlerts
  const updateAlerts = (alerts: PartialUpdateData[]) => {
    // update alert configuration
    const prev = selectAlerts(getAppState());
    const alertMap = keyBy(alerts, 'id');

    const newAlerts = prev.map(a => {
      if (!alertMap[a.alert.id]) {
        return a;
      }

      // update alert configuration
      return {
        ...a,
        alert: {
          ...a.alert,
          ...alertMap[a.alert.id],
          state: alertMap[a.alert.id].state || AlertState.Active
        }
      }
    })

    syncAlerts(newAlerts);
  };

  return { 
    syncAlerts, 
    deleteAlert, 
    deleteAlerts,
    updateAlert, 
    updateAlerts,
  }
}


function getAlertItems(alerts: AlertTableItem[] | AlertItem[]) {
  return alerts.map(a => {
    return 'alert' in a ? (a.alert as AlertItem) : a;
  })
}