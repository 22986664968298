import { HtmlHTMLAttributes, ReactNode } from "react";
// import AlertsSearch from "./alertsSearch";
import { Filters } from "../components/filters/filters";
import { QueryParam } from "@/constants";
import { useSelector } from "react-redux";
import { selectAlertBonds } from "@/store/slices/alert.slice";
import { ALERT_FILTERS } from "./alert.constants";



// <AlertsTableSection />
const AlertsTableSection = ({
  children
}: {
  children: ReactNode
}) => {
  const alertBonds = useSelector(selectAlertBonds)

  return (
    <div className="bg-[#333557]   flex flex-col p-[.625rem] lg:p-[1.25rem] rounded-b-[0.625rem] rounded-tr-[0.625rem]">

      {/* top row */}
      <div className="flex justify-between items-center w-full mb-[1.875rem]">
        <Filters 
          bonds={alertBonds}
          pageSizeParamName={QueryParam.AlertPageSize}
          activeFilters={ALERT_FILTERS}
        />
        {/* hide for now */}
        {/* <AlertsSearch /> */}
      </div>

      {/* content */}
      {children}
    </div>

  )
}

// <IconButton />
const IconButton = (props: HtmlHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      className="bg-[#5D5F9D] h-[2.25rem] rounded-full w-[2.25rem] flex items-center justify-center"
      // hidden temporarily until we have the filter functionality
      style={{ display: 'none' }}
      {...props}
    />
  )
}

export default AlertsTableSection;