import Loading from "@/app/loading";
import { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } from "@/store/api/userPreferences.endpoints";
import { UIMode } from "@/types/types";
import { captureException, captureMessage } from "@sentry/react";
import clsx from "clsx"
import { ReactNode, memo } from "react";
import { toast } from "react-toastify";
import { CircleCheck } from "../circleCheck";

export const AppViewTypeSelectorMobile = memo(({ onChangeCompleted }: { onChangeCompleted: () => void }) => {
  const { data: preferences, isLoading, isError } = useGetUserPreferencesQuery();
  const [updatePreferencesApi, mutationData] = useUpdateUserPreferencesMutation();

  const handleClick = (uiMode: UIMode) => async () => {
    if (!preferences) {
      captureMessage('User preferences not loaded while trying to update')
      return;
    }

    if (preferences.uiMode === uiMode) {
      return;
    }

    try {
      await updatePreferencesApi({ uiMode, version: preferences.version }).unwrap();
      
      onChangeCompleted();
    } catch (error) {
      captureException(error);
      toast.error('Something went wrong while updating your preferences. Please try again later.');
    }

  }

  const loading = isLoading || mutationData.isLoading;

  return (
    <div className="flex flex-col relative">

      {isError && (
        <div className="absolute flex p-2 text-red-500">
          We weren't able to load your preferences.
        </div>
      )}

      {loading && (
        <Loading 
          className="absolute flex items-center justify-center w-full h-full" 
          spinnerClassName="w-8 h-8 border-[4px] border-[#C9CADE77_transparent_#C9CADE77_transparent]"
        />
      )}

      <div className={clsx('flex flex-col', {
        'opacity-40 pointer-events-none': loading,
        'opacity-0 pointer-events-none': isError,
      })}>
        <Button
          onClick={handleClick(UIMode.HighYield)}
          selected={preferences?.uiMode === UIMode.HighYield}
        >
          High Yield
        </Button>

        <Button
          onClick={handleClick(UIMode.InvestmentGrade)}
          selected={preferences?.uiMode === UIMode.InvestmentGrade}
        >
          Investment Grade
        </Button>
      </div>
    </div>
  )
})

const Button = ({
  children,
  onClick,
  selected,
}: {
  children: ReactNode;
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <div
      role="button"
      className={clsx('flex gap-5 justify-between items-center py-2 px-5 text-gray-fb text-left text-sm font-medium w-full', {
        'bg-[#5D5F9D]': selected
      })}
      onClick={onClick}
    >
      {children}

      <CircleCheck checked={selected} />
    </div>
  )
}