import { QuantityOptions } from "@/hooks/useQuantityDropdown"
import GlobalPercentileListbox from "./listbox/globalPercentileListbox"
import StyledListBox from "./listbox/styledListbox"
import { Side } from "../data/api"
import clsx from "clsx"

export const InferenceConfigurationDropdowns = ({
  quantity,
  setQuantity,
  quantityOptions,
  className,
  quantityVisible,
}: {
  quantity: QuantityOptions[number],
  setQuantity: (v: QuantityOptions[number]) => void,
  quantityOptions: QuantityOptions
  className?: string
  quantityVisible?: boolean
}) => {
  return (
    <div className={clsx('flex flex-row gap-[0.625rem] max-w-[40rem] w-full flex-wrap', className)}>
      <div className='flex-1 min-w-[6.25rem]'>
        <GlobalPercentileListbox multiline={true} side={Side.bid} />
      </div>
      <div className='flex-1 min-w-[6.25rem]'>
        <GlobalPercentileListbox multiline={true} side={Side.dealer} />
      </div>
      <div className='flex-1 min-w-[6.25rem]'>
        <GlobalPercentileListbox multiline={true} side={Side.offer} />
      </div>
      {quantityVisible && (
        <div className='flex-1 min-w-[150px]'>
          <StyledListBox value={quantity} selectValue={setQuantity} values={quantityOptions} />
        </div>
      )}
    </div>
  )
}