import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';

export const DraggableItem = ({ 
  id,
  disabled,
  children, 
}: { 
  id: UniqueIdentifier,
  disabled?: boolean;
  children: ReactNode | (
    (args: { 
      listeners: ReturnType<typeof useSortable>['listeners']; 
      setNodeRef: ReturnType<typeof useSortable>['setNodeRef']; 
      attributes: ReturnType<typeof useSortable>['attributes'];
      style: React.CSSProperties;
    }) => ReactNode
  );
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id, disabled });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 10 : undefined
  };

  if (typeof children === 'function') {
    return children({ listeners, setNodeRef, style, attributes });
  }

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} >
      {children}
    </div>
  );
};