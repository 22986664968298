import { DEFAULT_UI_MODE } from "@/constants";
import { useGetUserPreferencesQuery } from "@/store/api/userPreferences.endpoints";
import { UIMode } from "@/types/types";

export const useUiMode = () => {
  const { data: preferencesRes } = useGetUserPreferencesQuery();
  const uiMode = preferencesRes?.uiMode || DEFAULT_UI_MODE;

  return { 
    uiMode,
    isIgUiMode: uiMode === UIMode.InvestmentGrade,
    isHyUiMode: uiMode === UIMode.HighYield,
  }
}
