import { useAlertBroadcastChannel } from "./hooks/useAlertBroadcastChannel"
import { useAlertNotifications } from "./hooks/useAlertNotifications";
import { useCalculateAlertsGlobalState } from "./hooks/useCalculateAlertsGlobalState";
import { AlertToastProvider } from "./alertToastProvider";
import { AlertsNotificationsActions } from "./components/alertsNotificationsActions";

export function AlertsController() {
  useAlertBroadcastChannel();
  useAlertNotifications();
  useCalculateAlertsGlobalState();

  return (
    <>
      <AlertToastProvider />
      <AlertsNotificationsActions />
    </>
  )
}