import TablePercentileListBoxV2 from "@/app/components/listbox/tableListBoxV2/tablePercentileListBoxV2"
import { AlertTableItem } from "../alertsTable";
import { percentileOptions } from "@/constants";

export const MinProbabilityDropdown = ({
  alert,
  onChange,
}: {
  alert: AlertTableItem['alert'],
  onChange: (data: { id: string, minProbabilityPctg: number }) => void;
}) => {
  return (
    <TablePercentileListBoxV2
      value={('' + alert.minProbabilityPctg) as (typeof percentileOptions)[number]}
      selectValue={v => {
        onChange({ id: alert.id, minProbabilityPctg: +v })
      }}
    />
  )
}