import Timer from "@/app/components/timer";
import { selectIsUiTrialActive, selectUiTrialExpiration } from "@/store/slices/auth.slice"
import { useSelector } from "react-redux"

export const TrialTimer = ({ className }: { className: string }) => {
  const isUiTrialActive = useSelector(selectIsUiTrialActive);
  const expiration = useSelector(selectUiTrialExpiration);

  if (!isUiTrialActive || !expiration) {
    return null;
  }

  return (
    <div className={className}>
      Trial ends in 
      <span className="font-semibold text-[1rem]">
        <Timer expiryTimestamp={expiration * 1000}>
          {({ days, hours, minutes, seconds, formatted }) => {
            if (days > 1) {
              return <>{days} days</>;
            }

            if (days === 0 && hours === 0 && minutes === 0) {
              return <>{seconds}s</>
            }

            return <>{hours}h:{formatted.minutes}m</>
          }}
        </Timer>
      </span>
    </div>
  )
}