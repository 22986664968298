import { Side } from '@/app/data/api';
import { Bond } from '@/app/data/bondIndex';
import { InferenceResult } from '@/hooks/data/useSimpleInferenceData';
import { PriceType } from '@/types/types';
import { BarWithErrorBarsController, BarWithErrorBar } from 'chartjs-chart-error-bars';

import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { formatCoupon, formatPrice } from '@/utils/number.utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { PriceTypeLabel } from '@/app/bond/bond.constants';
import { generateMemoizedGetRandomColor } from '@/utils/color.utils';
import { useLatest } from 'react-use';
import { getFormattedTimeToMaturity } from '@/utils/date.utils';
import { isEmpty } from 'lodash';
import { LoadingOverlay } from '@/app/loading';
import clsx from 'clsx';
import { useOpenPage } from '@/hooks/useOpenPage';
import { CommonRawData, RawData, RawDataFloating, RawDataNormal } from './chart.types';
import { getErrorBarsConfig, getMinMaxValues, getTooltipLabels } from './chart.utils';
import { externalTooltipHandler } from './chart.tooltip';
import { getFormatConfig } from '../../bondsChart.utils';
import { useChartHeight } from './hooks/useChartHeight';

ChartJS.register(
  BarWithErrorBarsController,
  BarWithErrorBar,
)

const plugins = [
  BarWithErrorBarsController,
  BarWithErrorBar,
  BarElement,
  Tooltip,
  ChartDataLabels,
]

const colors = {
  ticksColor: `#8183B3`,
}

export const Chart = ({
  bonds,
  inferenceResult,
  priceType,
  side,
  delta,
  className,
}: {
  bonds: Bond[];
  inferenceResult: InferenceResult<Bond>['data'];
  priceType: PriceType;
  side: Side;
  delta: boolean;
  className?: string
}) => {
  const MIN_BAR_WIDTH = 50;
  const chartRef = useRef<ChartJS | null>(null);
  const getBondRandomColor = useMemo(() => generateMemoizedGetRandomColor(), []) // we want to keep the same color for the same bond on rerender
  const openPage = useOpenPage();
  const barStyle = delta ? 'normal' : 'floating';
  const isFloatingBarsMode = barStyle === 'floating';


  const { chartData, data } = useMemo(() => {
    const data = (bonds || []).map((b, idx) => {
      const inferenceData = inferenceResult[b.figi][side][priceType] || {}
      const { current, diff, quantiles = [] } = inferenceData;
      const y = delta ? diff : current;

      let fifthPercentile: number | undefined;
      let ninetyFifthPercentile: number | undefined;

      if (!delta) {
        // calculate 5/95th percentiles only if we are not in delta mode
        const isBidGspread = side === Side.bid && priceType === PriceType.GSpread;
        const isOfferPrice = side === Side.offer && priceType === PriceType.Price;

        if (isBidGspread || isOfferPrice) {
          // we need to get opposite quantiles for bid gspread and offer price
          fifthPercentile = quantiles[quantiles.length - 1];
          ninetyFifthPercentile = quantiles[0];
        } else {
          fifthPercentile = quantiles[0];
          ninetyFifthPercentile = quantiles[quantiles.length - 1];
        }
      }

      if (typeof y === 'undefined') {
        // y value is requried;
        return null;
      }


      if (isFloatingBarsMode) {
        // we need both 5th and 95th percentiles for floating bars mode to show graph
        if (typeof fifthPercentile === 'undefined' || typeof ninetyFifthPercentile === 'undefined') {
          return null;
        }
      }

      const d: CommonRawData = {
        id: b.figi,
        bond: b,
        x: `${b.cusip}_${b.maturity}`, // format is important cusip_maturity as we parse it to get the maturity later for x axis. X also should be unique so we can not use just maturity
        inferenceData,
      }

      if (isFloatingBarsMode) {
        return {
          ...d,
          y: [fifthPercentile, ninetyFifthPercentile],
          yMax: y,
          yMin: y,
        } as RawDataFloating
      } else {
        return {
          ...d,
          y,
          yMin: fifthPercentile,
          yMax: ninetyFifthPercentile,
        } as RawDataNormal
      }

    }).filter(Boolean)

    const errorBarOptions = getErrorBarsConfig(isFloatingBarsMode)

    const chartData: ChartData<"bar", any> = {
      labels: data.map(d => d?.x || ''),
      datasets: [{
        borderWidth: 2,
        borderColor: 'transparent',
        barThickness: 50,
        backgroundColor: data.map((d) => getBondRandomColor(d?.bond.figi || 'default')),
        data,
        ...(errorBarOptions as any)
      }]
    }

    return { chartData, data };
  }, [bonds, side, inferenceResult, priceType, delta, getBondRandomColor])



  const { min, max } = getMinMaxValues(data, isFloatingBarsMode);

  const dataRef = useLatest(data);

  const options = useMemo(() => {
    const formatConfig = getFormatConfig(priceType, delta)

    const options: ChartOptions<'bar'> = {
      responsive: true,
      maintainAspectRatio: false,
      // animation: true, // TODO: disable it for more than x items
      // normalized: true,
      // parsing: false,
      scales: {
        y: {
          min,
          max,
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          beginAtZero: !isFloatingBarsMode,
          ticks: {
            align: 'center',
            crossAlign: 'far',
            color: colors.ticksColor,
            minRotation: 0, // set min/max rotation to same value to make performance better
            maxRotation: 0,
            callback: function (value) {
              return typeof value === 'number' ? formatPrice(value, priceType, formatConfig) : value;
            }
          },
        },
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            minRotation: 0,  // set min/max rotation to same value to make performance better
            maxRotation: 0,
            color: colors.ticksColor,
            padding: 10,
            // sampleSize: dataRef.current.length,
            align: 'center',
            callback: function (value) {
              const d = dataRef.current[value as number];

              if (!d) {
                return '';
              }

              return getFormattedTimeToMaturity(d.bond.maturity).toString();
            }
          },
          stacked: false,
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: () => '',
            label: (tooltipItem) => {
              return getTooltipLabels({
                tooltipItem, 
                priceType, 
                delta, 
                formatConfig, 
                isFloatingBarsMode,
              }) as string[]; // we implement custom tooltip in chart.tooltip.ts which handles custom tooltip data type `CustomLabel`. Assigning string[] here to make typescript happy
            }
          },
          caretPadding: 10,
          displayColors: false,
          position: 'nearest',
          enabled: false,
          external: externalTooltipHandler

        },
        datalabels: {
          color: 'blue',
          rotation: 90,
          align: 'left',
          formatter: function (value, context) {
            const data = context.chart.data.datasets?.[0]?.data?.[context.dataIndex] as any;

            if (!data) {
              return '';
            }

            const {
              ticker,
              coupon,
              maturity,
            } = data?.bond

            return `${ticker} - ${formatCoupon(coupon)} - ${maturity}`
          },
          labels: {
            title: {
              font: {
                weight: 'normal',
              }
            },
            value: {
              color: '#fff'
            }
          }
        }
      }
    };
    return options;
  }, [priceType, min, max, delta])



  useLayoutEffect(() => {
    // this effect is used to initialize chart
    const ctx = (document.getElementById('bondsChart') as any);

    if (!ctx) {
      return;
    }

    if (!data.length) {
      return;
    }

    if (chartRef.current) {
      return;
    }

    chartRef.current = new ChartJS(ctx, {
      type: BarWithErrorBarsController.id as any,
      data: chartData,
      options,
      plugins,
    });
  }, [chartData, options])

  useLayoutEffect(() => {
    if (!chartRef.current) {
      return;
    }

    // update chart data
    chartRef.current.data.labels = chartData.labels;
    chartRef.current.data.datasets[0].backgroundColor = chartData.datasets[0].backgroundColor;
    chartRef.current.data.datasets[0].data = chartData.datasets[0].data;
  }, [chartData])

  useLayoutEffect(() => {
    if (!chartRef.current) {
      return;
    }

    // update chart options
    chartRef.current.options = options;
  }, [options])

  useLayoutEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [chartData, options]);

  useEffect(() => {
    // destroy chart on unmount
    // needed to prevent memory leaks
    // without this effect there is also an issue when we click on a bar and redirect to next page
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    }
  }, [])

  // event handlers
  const handleClick = useCallback((event: any) => {
    const chart = chartRef.current;
    if (!chart) {
      return
    }

    const res = chart.getElementsAtEventForMode(
      event,
      'nearest',
      { intersect: true },
      true
    );
    // If didn't click on a bar, `res` will be an empty array
    if (res.length === 0) {
      return;
    }

    // get data item from the clicked bar
    const dataItem = chart.data.datasets[res[0].datasetIndex].data[res[0].index] as any as RawData;
    openPage.bond(dataItem.bond);
  }, [])

  // calculated values
  const chartTitle = <>{PriceTypeLabel[priceType]} {delta && <>&#916;</>}</>
  const m = data.length === 1 ? 250 : 250;
  const minWidth = Math.max(m, (data.length + 1) * MIN_BAR_WIDTH);
  const loading = isEmpty(data);
  const height = useChartHeight();


  return (
    <div className={clsx('relative', className)}>
      <div className="relative pt-8">
        <h4 className='absolute top-0 left-1/2 -translate-x-1/2'>
          {chartTitle}
        </h4>
      </div>
      {loading && <LoadingOverlay className="rounded-lg" />}
      <div className='overflow-x-auto relative'>
        <div className='relative' style={{ width: minWidth, height }}>
          <canvas
            id="bondsChart"
            onClick={handleClick}
            style={{
              height,
            }}
          />
        </div>
      </div>
    </div>
  )
}

