import { NavigateOptions, useSearchParams } from "react-router-dom";
import { Bond, Issuer } from "../app/data/bondIndex";
import useAppNavigate from "./useAppNavigate";
import { useMemo } from "react";
import { AlertType } from "../app/data/alerts";
import { useLatestRef } from "./useLatestRef";


type ExtendedNavigateOptions = NavigateOptions & {
  keepQuery?: boolean;
}

export const useOpenPage = () => {
  const appNavigate = useAppNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsRef = useLatestRef(searchParams);
  

  return useMemo(() => {
    function openBondPage(bond: Bond) {
      appNavigate(`bond/${bond.figi}`);
    }
  
    function openIssuerPage(issuer: Issuer, options?: NavigateOptions | undefined) {
      appNavigate(`issuer/${issuer.ticker}`, options);
    }
  
    function openRunsPage(options?: NavigateOptions | undefined) {
      appNavigate('run', options);
    }
  
    function openRunPage(runId: string | undefined, options?: NavigateOptions | undefined) {
      appNavigate(`run/${runId}`, options);
    }

    function openPortfoliosPage(options?: NavigateOptions | undefined) {
      appNavigate('portfolio', options);
    }
  
    function openPortfolioPage(portfolioId: string | undefined, options?: NavigateOptions | undefined) {
      appNavigate(`portfolio/${portfolioId}`, options);
    }
    
    function openSearchPage(options?: NavigateOptions | undefined) {
      appNavigate('search', options);
    }

    function openSearchResultPage(searchStr: string, options?: NavigateOptions | undefined) {
      appNavigate(`search/${encodeURIComponent(searchStr)}`, options);
    }

    function openAlertsPage(type = AlertType.Target, options?: ExtendedNavigateOptions | undefined) {
      const search = options?.keepQuery ? `?${searchParamsRef.current}` : '';
      appNavigate(`alert/${type}${search}`, options);
    }

    return {
      openBondPage,
      openIssuerPage,
      openRunsPage,
      openRunPage,
      openPortfoliosPage,
      openPortfolioPage,
      openSearchPage,
      openSearchResultPage,
      openAlertsPage,
  
  
      // object access to properties - const openPage = useOpenPage(); - openPage.bond(bond);
      bond: openBondPage,
      issuer: openIssuerPage,
      runs: openRunsPage,
      run: openRunPage,
      portfolios: openPortfoliosPage,
      portfolio: openPortfolioPage,
      search: openSearchPage,
      searchResult: openSearchResultPage,
      alerts: openAlertsPage,
    }

  }, [appNavigate])
}