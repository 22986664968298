import { AlertSideDropdown } from "../../dropdowns/alertSideDropdown";
import { AlertSizeDropdown } from "../../dropdowns/alertSizeDropdown";
import { AlertTargetTypeDropdown } from "../../dropdowns/alertTargetTypeDropdown";
import { AlertCardProps } from "../alertCard";
import { Col, DarkBox, Row } from "@components/itemCard";
import { VolatilityConditionDropdown } from "../../dropdowns/volatilityConditionDropdown";
import clsx from "clsx";
import { FormattedPrice } from "@/app/components/formatNumber/formattedPrice";

export const VolatilityAlertDetails = ({
  alertItem,
  canChangeSide,
  data,
  onChange,
}: AlertCardProps) => {
  const { alert } = alertItem;
  const d = data.alert[alert.id];


  return (
    <>
      <DarkBox>
        <Row>
          <Col label="Size">
            <AlertSizeDropdown alert={alert} onChange={onChange} />
          </Col>

          <Col label="Side" end>
            <AlertSideDropdown
              alert={alert}
              onChange={onChange}
              canChangeSide={canChangeSide}
            />
          </Col>
        </Row>

        <Row>
          <Col label="Type">
            <AlertTargetTypeDropdown alert={alert} onChange={onChange} />
          </Col>
          <Col label="Condition">
            <VolatilityConditionDropdown alert={alert} onChange={onChange} />
          </Col>
        </Row>
      </DarkBox>


      <DarkBox>
        <Row>
          <Col
            label={<>Current <br /> Lower Bound</>}
            valueClassName={clsx({ 'bg-[#ff070799]': d.isGapUp })}
            box={d.isGapUp}
          >
            <FormattedPrice value={d.conditionData.todayMin} />
          </Col>
          <Col
            label={<>Current <br /> Upper Bound</>}
            valueClassName={clsx({ 'bg-[#ff070799]': d.isGapDown })}
            box={d.isGapDown}
          >
            <FormattedPrice value={d.conditionData.todayMax} />
          </Col>
        </Row>

        <Row>
          <Col
            label={<>Prev <br /> Lower Bound</>}
            valueClassName={clsx({ 'bg-[#ff070799]': d.isGapDown })}
            box={d.isGapDown}
          >
            <FormattedPrice value={d.conditionData.prevMin} />
          </Col>
          <Col
            label={<>Prev <br /> Upper Bound</>}
            valueClassName={clsx({ 'bg-[#ff070799]': d.isGapUp })}
            box={d.isGapUp}
          >
            <FormattedPrice value={d.conditionData.prevMax} />
          </Col>
        </Row>


      </DarkBox>
    </>
  )
}