import { useContext, useEffect, useState } from 'react';
import { DataContext, WebSocketError } from '../data/dataProvider';
import Loading from '../loading';
import Modal from '../components/modal/modal';

const WebSocketDeactivatedAlert = () => {

  const { webSocketError } = useContext(DataContext);

  const [show, setShow] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!webSocketError) {
      setShow(false);
    } else if (webSocketError === WebSocketError.deactivated) {
      setShow(true);
      setProcessing(false);
    }
  }, [webSocketError]);

  return (
    <Modal
      body={
        processing
          ? <Loading className="pb-[2rem] pt-[0.25rem]" />
          : <div className="flex flex-col gap-[1.25rem] items-start w-full">
              <div>This can be caused by network issues, exceeding the connection limit, or logging in with a different browser or device.</div>
              <div className="flex flex-row gap-[0.25rem] items-center justify-end w-full">
                <button
                  className="bg-[#5D5F9D] px-[2rem] py-[0.625rem] rounded-[0.625rem] text-[0.875rem] text-[#FBFBFD]"
                  onClick={() => {
                    setProcessing(true);
                    window.location.reload();
                  }}
                >
                  Reconnect
                </button>
              </div>
            </div>
      }
      show={show}
      title={processing ? 'Reconnecting' : 'Connection closed'}
      zIndex='z-[9999]'
    />
  );
}

export default WebSocketDeactivatedAlert;
