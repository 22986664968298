import { Fragment, ReactNode, useState } from "react"
import { MenuButton } from "../menuButton"
import { Drawer } from "@/app/components/drawer/drawer";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTopLevelPage } from "../../hooks/useTopLevelPage";
import useAppNavigate from "@hooks/useAppNavigate";
import clsx from "clsx";
import { AppViewTypeSelectorMobile } from "./appViewTypeSelector.mobile";
import { AlertsCounterMobile } from "./alertsCounter.mobile";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TrialTimer } from "../trialTimer";

export const MenuMoible = () => {
  const [open, setOpen] = useState(false);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [submenu, setSubmenu] = useState<'preferences' | undefined>(undefined);

  const menuItems = [
    { label: 'Portfolio', route: 'portfolio' },
    { label: 'Runs', route: 'run' },
    { label: 'Alerts', route: 'alert', rightContent: <AlertsCounterMobile /> },
    { label: 'Preferences', hasSubmenu: true, onClick: () => setSubmenu('preferences') },
    { label: 'Sign Out', onClick: signOut },
  ]


  return (
    <>
      <MenuButton onClick={() => setOpen(!open)} />

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        position="right"
        panelCss="w-[300px] overflow-hiden pt-[2.25rem]"
      >

        <div className="flex flex-col gap-2.5 mt-5 mb-[3.125rem]">
          {menuItems.map((item, idx) => (
            <MenuItem
              key={idx}
              {...item}
              closeMenu={() => setOpen(false)}
            >
              {item.label}
            </MenuItem>
          ))}
        </div>

        <Submenu 
          show={Boolean(submenu)} 
          onBack={() => setSubmenu(undefined)}
          onClose={() => {
            setSubmenu(undefined)
            setOpen(false);
          }}
        />

        <TrialTimer 
          className="mt-auto mb-4 py-[0.9375rem] mx-5 px-5 rounded-lg bg-[#1f2034] flex justify-between items-center"
        />
      </Drawer>
    </>
  )
}


// <MenuItem />
const MenuItem = ({
  children,
  route,
  onClick,
  closeMenu,
  hasSubmenu,
  rightContent,
}: {
  children: ReactNode
  route?: string;
  onClick?: () => void;
  closeMenu: () => void;
  hasSubmenu?: boolean;
  rightContent?: ReactNode;
}) => {
  const topLevelPage = useTopLevelPage();
  const appNavigate = useAppNavigate();
  const isCurrentPage = topLevelPage === route;

  function handleClick() {
    if (route) {
      appNavigate(route);
    } else {
      onClick?.();
    }

    if (!hasSubmenu) {
      closeMenu();
    }
  }

  return (
    <button
      type="button"
      className={clsx('flex justify-between py-[0.9375rem] px-5 pl-[0.9375rem] border-l-[5px] text-[0.875rem] font-medium leading-[1rem]', {
        'border-l-[#8183B3]': isCurrentPage,
        'border-l-transparent': !isCurrentPage,
      })}
      onClick={handleClick}
    >
      {children}
      {rightContent}
      {hasSubmenu && <FaChevronRight className="font-bold" />}
    </button>
  )
}


// <Submenu />
const Submenu = ({
  show,
  onBack,
  onClose,
}: {
  show: boolean;
  onBack: () => void;
  onClose: () => void;
}) => {
  return (
    <Drawer
      open={show}
      onClose={onClose}
      position="right"
      panelCss="w-[300px] overflow-hiden"
      hideBackdrop
    >
      <button type="button" className="flex items-center gap-3 pl-4 h-[2.125rem]" onClick={onBack}>
        <FaChevronLeft />
        <h2 className="text-[#FBFBFD] text-[1rem]  font-medium">Preferences</h2>
      </button>

      <div className="mt-8">
        <AppViewTypeSelectorMobile onChangeCompleted={() => onClose()} />
      </div>
    </Drawer>
  )
}