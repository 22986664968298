import { DEFAULT_PRICE_FORMAT } from "@/constants";
import { NumericFormat } from "react-number-format";

export const FormattedPrice = ({ value, className }: { value: string | number | null | undefined; className?: string }) => {
  return (
    <NumericFormat
      {...DEFAULT_PRICE_FORMAT}
      value={value}
      displayType='text'
      defaultValue='-'
      className={className}
    />
  )
}