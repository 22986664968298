import { ColumnConfigMapType, PriceType, RfqLabelMap, UIMode } from "@/types/types";
import { useUiMode } from "../useUiMode";
import notUndefined from "@/utils/notUndefined";
import { Side } from "@/app/data/api";
import { useMemo } from "react";
import { isArray, isEqual, mergeWith } from "lodash";

// calculates inference rfq labels from columns for each side 
export const useGetInferenceRfqLabelsFromColumns = <T extends string>(
  columnsOrderConfig: Record<UIMode, T[]>,
  columnConfigMap: ColumnConfigMapType<T>
): RfqLabelMap => {
  const { uiMode } = useUiMode();

  const rfqLabels = useMemo(() => {
    const columns = columnsOrderConfig[uiMode]; // get columns for current uiMode

    const configs = columns.map(column => columnConfigMap[column]).filter(notUndefined); // get column configs

    const rfqLabels = configs.reduce((acc, config) => {
      if (!config) return acc;

      if (Array.isArray(config)) {
        config.forEach(c => {
          acc[c.side].add(c.priceType);
        })
      } else {
        acc[config.side].add(config.priceType);
      }


      return acc;
    }, {
      [Side.bid]: new Set<PriceType>(),
      [Side.offer]: new Set<PriceType>(),
      [Side.dealer]: new Set<PriceType>(),
    })

    return Object.entries(rfqLabels).reduce((acc, [side, priceTypes]) => {
      acc[side as Side] = Array.from(priceTypes);
      return acc;
    }, {} as RfqLabelMap)
  }, [uiMode, columnConfigMap, columnsOrderConfig])

  return rfqLabels;
}

export const mergeRfqLabels = (rfqLabelMap1: RfqLabelMap, rfqLabelMap2: RfqLabelMap): RfqLabelMap => {
  function customizer(objValue: any, srcValue: any) {
    if (isArray(objValue)) {
      return Array.from(new Set([...objValue, ...srcValue]))
    }
  }

  const mergedRfqLabels = mergeWith({}, rfqLabelMap1, rfqLabelMap2, customizer);

  if (isEqual(rfqLabelMap1, mergedRfqLabels)) {
    // return the original rfqLabelMap1 if there are no changes
    return rfqLabelMap1;
  }

  return mergedRfqLabels;
}