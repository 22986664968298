import { FunctionComponent } from 'react';
import TableListBoxV2Base, { TableListBoxV2BaseProps } from './tableListBoxV2Base';
import { Percentile, percentileOptions } from '@/constants';

const TablePercentileListBoxV2: FunctionComponent<{
  selectValue: (v: Percentile) => void,
  value: Percentile
  popperProps?: TableListBoxV2BaseProps<any>['popperProps'];
}> =
  ({ selectValue, value, popperProps }) => {
    return (
      <TableListBoxV2Base 
        renderValue={({ value }) => `${value}%`}
        selectValue={selectValue}
        value={value}
        options={percentileOptions}
        popperProps={popperProps}
      />
    );
  }

export default TablePercentileListBoxV2;
