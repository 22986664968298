import {
  DndContext,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
  Active,
  DndContextProps,
} from '@dnd-kit/core';
import { SortableContext, SortableContextProps, arrayMove } from '@dnd-kit/sortable';
import { ReactNode, useMemo, useState } from 'react';
import { SortableOverlay } from './sortableOverlay';
import { horizontalWrapStrategy } from './utils/horizontalWrapStrategy';

interface BaseItem {
  id: UniqueIdentifier;
}

type TProps<T> = {
  children: ReactNode;
  items: T[];
  onChange?: (items: T[]) => void; // `items` - reordered items after drag and drop
  renderItem: (item: T) => ReactNode;
  onDragEnd?: () => void;
  onDragOver?: DndContextProps['onDragOver'];
  onDragMove?: DndContextProps['onDragMove'];
  strategy?: SortableContextProps['strategy'];
};

export const SortableList = <T extends BaseItem>({
  children,
  items,
  renderItem,
  onChange,
  onDragEnd,
  onDragOver,
  onDragMove,
  strategy,
}: TProps<T>) => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );


  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange?.(arrayMove(items, activeIndex, overIndex));
        }

        setActive(null);
        onDragEnd?.();
      }}
      onDragMove={onDragMove}
      onDragCancel={() => {
        setActive(null);
      }}
      onDragOver={onDragOver}
    >
      {/* SorableContext */}
      <SortableContext 
        items={items} 
        strategy={strategy || horizontalWrapStrategy}
      >
        {children}
      </SortableContext>

      {/* Overlay */}
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};
