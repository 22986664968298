export enum SearchColumn {
  Checkbox = 'checkbox',
  Alert = 'alert',
  Cusip = 'cusip',
  Ticker = 'ticker',
  Issuer = 'issuer',
  Coupon = 'coupon',
  Maturity = 'maturity',
  SAndPRating = 'SAndPRating',
  Series = 'series',
  AmountOutstanding = 'AmountOutstanding',
}

export const ColumnOrder = [
  SearchColumn.Checkbox,
  SearchColumn.Alert,
  SearchColumn.Cusip,
  SearchColumn.Ticker,
  SearchColumn.Issuer,
  SearchColumn.Coupon,
  SearchColumn.Maturity,
  SearchColumn.SAndPRating,
  SearchColumn.Series,
  SearchColumn.AmountOutstanding,
]

export const ColumnsOrderConfig = {
  default: ColumnOrder,
}