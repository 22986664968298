import { CheckIcon, PlayIcon } from '@heroicons/react/20/solid';
import ListBox, { ListboxProps } from '../listbox';
import clsx from 'clsx';
import { ReactNode, useEffect } from 'react';

export type TableListBoxV2BaseProps<T extends readonly string[]> = {
  className?: string;
  isOptionDisabled?: (v: T[number]) => boolean;
  options: T,
  popperProps?: ListboxProps<T>['popperProps'];
  renderOption?: (props: { value: T[number], disabled?: boolean }) => ReactNode;
  renderValue?: (props: { value: T[number] }) => ReactNode;
  selectValue: (v: T[number]) => void;
  value: T[number];
}

export const TableListBoxV2Base = <T extends readonly string[]>({
  className,
  isOptionDisabled,
  options,
  popperProps,
  renderOption,
  renderValue = ({ value }) =>  value,
  selectValue,
  value,
}: TableListBoxV2BaseProps<T>) => {
  const _renderOption = renderOption || renderValue;
  
  return (
    <ListBox
      button={props =>
        <div className={clsx('group/button flex justify-between items-center gap-[0.625rem] whitespace-nowrap', className)}>
          {renderValue({ value: props.value })}

          <PlayIcon
            className={clsx({
              'min-w-[0.625rem] w-[0.625rem] transform transition-transform text-[#BFC5F1] group-hover/button:text-[#fff]': true,
              'rotate-90': !props.open,
              '-rotate-90 text-[#fff]': props.open
            })}
            aria-hidden="true"
          />
        </div>
      }
      option={(optionValue, props) =>
        <>
          <div className={`${props.selected ? 'font-medium' : 'font-normal'}`}>
            {_renderOption({ value: optionValue, disabled: isOptionDisabled?.(optionValue) })}
          </div>
          {props.selected ? (
            <span className="absolute inset-y-0 left-0 flex items-center pl-[0.25rem]">
              <CheckIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />
            </span>
          ) : null}
        </>
      }
      buttonCss={() => 'w-full'}
      optionCss={(v) => {
        const disabled = isOptionDisabled?.(v);

        return clsx('bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[1.6rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0]', {
          'hover:bg-[#484A7A]': !disabled,
          'text-opacity-50': disabled,
        })
      }}
      optionsCss={' absolute -left-[0.625rem] max-h-[12rem] mt-[0.1875rem] overflow-auto rounded-[0.625rem] w-[5rem] z-[1]'}
      selectValue={(v) => {
        if (isOptionDisabled?.(v)) return;
        
        selectValue(v)
      }}
      value={value}
      values={options}
      popperProps={popperProps}
    />
  );
}

export default TableListBoxV2Base;
