import { useEffect, useLayoutEffect, useRef } from 'react';

export const useUpdateTextAreaHeight = ({
  additionalHeight = 10,
  resizeTrigger,
}: {
  additionalHeight?: number;
  resizeTrigger?: any;
}) => {
  const ref = useRef<HTMLTextAreaElement | null>(null);

  const updateTextareHeight = () => {
    if (ref.current) {
      const el = ref.current;
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight + additionalHeight}px`;
    }
  };

  useEffect(() => {
    // Update textarea height only after the fonts are loaded
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        updateTextareHeight();
      }
    };
  }, []);

  useEffect(() => {
    updateTextareHeight();
  }, [ref]);

  useLayoutEffect(() => {
    updateTextareHeight();
  }, [resizeTrigger])

  return {
    ref,
    updateTextareHeight,
  };
};