import { AtsIndicator, Side } from "@/app/data/api";
import { Bond } from "@/app/data/bondIndex";
import { InferenceRequest } from "@/app/data/useInferences";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { PriceType } from "@/types/types";

export const useBondsInferenceRequests = <T extends Bond>({ 
  bonds, 
  rfqLabelMap,
  getQuantity,
}: { 
  bonds: T[], 
  rfqLabelMap:  Record<Side, PriceType[]>,
  getQuantity: (item: T, side: Side) => number;
}) => {

  const bondRequests: InferenceRequest[] = useMemo(() => {
    if (!bonds) {
      return [];
    }
    return ([Side.bid, Side.dealer, Side.offer])
      .reduce((acc, side) => {
        const rfqLabels = rfqLabelMap[side];

        if (isEmpty(rfqLabels)) { // no need to request data for the side if we don't have any labels
          return acc;
        }

        // create bond request for each rfq label
        const bondRequests = bonds.reduce((acc, b) => {
          rfqLabels.forEach(rfqLabel => {
            acc.push({
              figi: b.figi,
              atsIndicator: AtsIndicator.N,
              quantity: getQuantity(b, side),
              side,
              rfq_label: rfqLabel,
            })
          })
          return acc
        }, [] as InferenceRequest[])

        return [...acc, ...bondRequests];
      }, [] as InferenceRequest[]) 
  }, [bonds, getQuantity, rfqLabelMap]);

  return bondRequests;
}