import { FunctionComponent, useContext, useMemo } from 'react';
import { DataContext, Side } from '../../data/dataProvider';
import PercentileListbox from './percentileListbox';

const GlobalPercentileListbox: FunctionComponent<{ multiline: boolean, side: Side }> = ({ multiline, side }) => {

  const { bidPercentile, dealerPercentile, dispatch, offerPercentile } = useContext(DataContext);
  const percentile = useMemo(() => side === Side.bid ? bidPercentile : side === Side.dealer ? dealerPercentile : offerPercentile, [bidPercentile, dealerPercentile, offerPercentile, side]);
  const type = useMemo(() => side === Side.bid ? 'setBidPercentile' : side === Side.dealer ? 'setDealerPercentile' : 'setOfferPercentile', [side]);

  return (
    <PercentileListbox
      selectValue={v => void dispatch({ type, payload: v })}
      sideOptions={{multiline, side}}
      value={percentile}
    />
  );
};

export default GlobalPercentileListbox;
