import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Loading from '../../loading';
import { noop } from 'lodash';
import { XMarkIcon } from '@heroicons/react/24/outline';

export type ModalProps = {
  body: ReactNode, 
  loading?: boolean
  show: boolean, 
  size?: 'content' | 'small',
  title?: ReactNode 
  panelClassName?: string;
  zIndex?: string
  onClose?: () => void
}

const Modal = ({ 
  body, 
  loading,
  show, 
  size = 'small',
  title,
  panelClassName,
  zIndex,
  onClose,
}: ModalProps) => {

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className={clsx(
          "relative",
          zIndex || "z-[10]"
        )}
        onClose={onClose || noop}
        
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#0A0B11]/[0.70]" />
        </Transition.Child>
        <div className="fixed flex inset-0 items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={clsx(
              'align-middle bg-[#333557] overflow-hidden p-[1.5rem] rounded-[0.625rem] shadow-xl text-[#C9CADE] text-left transform transition-all',  panelClassName, {
                'max-w-[28rem] w-full': size === 'small',
                'w-fit max-w-[calc(100vw-2rem)]': size === 'content',
              }
            )}>

              {onClose && (
                <button type="button" className="absolute right-2 top-2 p-1 hover:scale-110 z-30" onClick={() => onClose()}>
                  <XMarkIcon className='w-5' />
                </button>
              )}

              {title && <Dialog.Title className="mb-[1.5rem]  text-[1.25rem]">{title}</Dialog.Title>}
              {body}

              {loading && (
                <div className='absolute h-full w-full top-0 right-0 flex justify-center items-center bg-[#33355799] z-30'>
                  <Loading spinnerClassName='border-[#C9CADE_transparent_#C9CADE_transparent]' />
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default Modal;
