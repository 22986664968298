import { RunResponse, RunValue } from "@/app/data/runs";
import { baseApi } from ".";
import { captureException } from "@sentry/core";

export const runEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    // getRun
    getRun: builder.query<RunResponse, string>({
      query: (id) => `/objects/${id}`,
      keepUnusedDataFor: 10, // 10 seconds cache
      providesTags: ['RunObject'],
    }),
    // getRunList
    getRunList: builder.query<RunResponse[], void>({
      query: () => `/objects?type=run`,
      providesTags: ['RunObjectList'],
      keepUnusedDataFor: 10,
    }),
    // createRunObject
    createRunObject: builder.mutation<RunResponse, { name: string, bonds: RunValue['bonds'] }>({
      query: ({ name, bonds }) => ({
        url: '/objects',
        method: 'POST',
        body: {
          type: 'run',
          metadata: JSON.stringify({ name }),
          value: { bonds }
        },
      }),
      invalidatesTags: r => r ? ['RunObjectList'] : [], // invalidate after success only
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          try {
            dispatch(
              runEndpoints.util.updateQueryData('getRunList', undefined, (prevData) => {
                if (!prevData) return [result.data];
                return [...prevData, result.data];
              })
            );
          } catch (e) {
            captureException(e)
            console.error(e);
          }
        } catch { }
      },
    }),
    // updateRunObject
    updateRunObject: builder.mutation<RunResponse, {
      id: string,
      version: string,
      bonds?: RunValue['bonds'],
      metadata?: { name: string },
      skipListUpdate?: boolean,
    }>({
      query: ({ id, version, bonds, metadata }) => ({
        url: '/objects',
        method: 'PATCH',
        body: {
          id,
          version,
          ...(metadata && { metadata: JSON.stringify(metadata) }),
          ...(bonds ? { value: { bonds } } : {}),
        },
      }),
      async onQueryStarted({ id, bonds, metadata, skipListUpdate }, { dispatch, queryFulfilled }) {
        // sync data in store after query fullfilled, no need for refetch
        try {
          await queryFulfilled;

          try {
            const updateRun = (p: RunResponse) => {
              return {
                ...p,
                ...(metadata && { metadata: JSON.stringify(metadata) }),  // api works in a way that if metadata or bonds are not provided it return those keys as empty strings but actually tehre is a value on the server
                value: {
                  ...p.value,
                  bonds: bonds || (p.value ? p.value.bonds : p.value),
                }
              }
            }

            // update run item
            dispatch(
              runEndpoints.util.updateQueryData('getRun', id, updateRun)
            );

            if (!skipListUpdate) {
              // update run item in the list
              dispatch(
                runEndpoints.util.updateQueryData('getRunList', undefined, (prevData) => {
                  return prevData.map(p => {
                    return p.id === id ? updateRun(p) : p
                  })
                })
              );
            }
          } catch(e) {
            captureException(e)
            console.error(e)
          }

        } catch { }
      },
    }),

  }),
});

export const {
  useGetRunQuery,
  useLazyGetRunQuery,
  useGetRunListQuery,
  useUpdateRunObjectMutation,
  useCreateRunObjectMutation,
} = runEndpoints;


