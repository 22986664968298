

import { captureException } from '@sentry/react';
import React from 'react';
import { ErrorLayout } from './errorLayout';


export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any) {
    // You can also log the error to an error reporting service
    captureException(error);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorLayout 
          title="Error"
          text="Uh-Oh... An unexpected error occurred, don't worry, we are on it. In the mean time, please try again..."
          actionText='Refresh page'
          onClick={() => window.location.reload()}
        />
      );
    }

    return this.props.children;
  }
}


