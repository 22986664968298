import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/20/solid';
import deepmmLogoAllWhite from '../../DeepMM_logo_all_white.svg';
import Search from './search';
import clsx from 'clsx';
import { AlertsCounter } from './alertsCounter';
import { useOpenPage } from '../../hooks/useOpenPage';
import { useTopLevelPage } from './hooks/useTopLevelPage';
import { useDesktopDown } from '@hooks/useBreakpoints';
import { DesktopDropdownMenu } from './components/desktop/dropdownMenu.desktop';
import { MenuMoible } from './components/mobile/menu.mobile';
import { TrialTimer } from './components/trialTimer';


const Header = () => {
  const openPage = useOpenPage();
  const topLevelPage = useTopLevelPage();
  const isDesktopDown = useDesktopDown();

  const alertsCounter = <AlertsCounter />;

  return (
    <div className="bg-[#252641] gap-x-[1rem] flex justify-between  h-[--header-height] items-center px-[1rem] ">
      <div className="flex items-center h-full gap-x-[1rem]">
        <Link className="col-start-1 row-start-1 z-[10] shrink-0" to="/">
          <img className="h-[2.52rem]" alt="" src={deepmmLogoAllWhite} />
        </Link>
        <Search />

        {!isDesktopDown &&
          <TrialTimer
            className="flex flex-col justify-center items-center px-4 py-2 rounded-md bg-[#5d5f9d] text-[0.875rem] whitespace-nowrap"
          />}
      </div>

      <div className='flex items-center h-full gap-x-[1rem]'>
        <div className="sm:flex flex-row h-full hidden items-center sm:col-start-4 gap-[0.625rem] lg:pr-[2.625rem]">
          <DesktopMenuButton
            active={topLevelPage === 'portfolio'}
            onClick={() => void openPage.portfolios()}
            text="Portfolio"
          />
          <DesktopMenuButton
            active={topLevelPage === 'run'}
            onClick={() => void openPage.runs()}
            text="Runs"
          />
          <DesktopMenuButton
            active={topLevelPage === 'alert'}
            onClick={() => void openPage.alerts()}
          >
            Alerts
            {alertsCounter}
          </DesktopMenuButton>
        </div>
        <div
          className="col-start-3 flex items-center sm:col-start-5 sm:hidden relative py-[0.625rem] px-1 cursor-pointer"
          onClick={() => void openPage.alerts()}
        >
          <BellIcon className="h-[1.5rem] text-[#4384C8]" aria-hidden="true" />
          {alertsCounter}
        </div>
        <div className="col-start-4 flex items-center sm:col-start-6">
          {isDesktopDown ? <MenuMoible /> : <DesktopDropdownMenu />}
        </div>
      </div>
    </div>
  );
}

// <DesktopMenuButton />
function DesktopMenuButton({
  active,
  children,
  onClick,
  text,
}: {
  active: boolean;
  children?: ReactNode;
  onClick: () => void;
  text?: string;
}) {
  return (
    <button
      disabled={active}
      className={clsx('box-border h-full px-[0.625rem] text-[0.875rem] min-w-[5rem]', {
        "border-b-[#8183B3] border-t-transparent border-y-[0.3125rem] text-[#8183B3]": active,
      })}
      onClick={onClick}
    >
      <span className='flex relative items-center justify-center py-[8px]'>
        {text}
        {children}
      </span>
    </button>
  )
}

export default Header;
