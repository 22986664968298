import { Bars3Icon } from "@heroicons/react/20/solid";
import { forwardRef } from "react";

export const MenuButton = forwardRef<HTMLButtonElement, { onClick?: () => void }>(({ 
  onClick,
}, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className="py-[1rem] rounded-[0.3125rem] focus:outline-none focus:ring-2 focus:ring-teal-2"
      onClick={onClick}
    >
      <span className="sr-only">Open menu</span>
      <Bars3Icon className="h-[2rem] text-[#4384C8]" aria-hidden="true" />
    </button>
  )
})