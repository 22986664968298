import { createSlice } from "@reduxjs/toolkit";
import { TAppState } from "..";
import { LocalStorageKey, ONE_HOUR } from "../../constants";
import { AlertInferenceData } from "../../app/alert/alert.types";
import { PA } from "../../types/utility.types";
import { AlertTableItem } from "../../app/alert/alertsTable";
import { isAlertActive, isAlertSnoozed, isTargetAlert } from "../../app/alert/alert.utils";
import { AlertType } from "../../app/data/alerts";

export type TAlertReducerState = {
  snoozeDefaultMs: number;
  inferenceData: AlertInferenceData
  alerts: AlertTableItem[];
  highlightedAlertId: string | null;
};

const snoozeDefaultMs = parseInt(localStorage.getItem(LocalStorageKey.AertSnoozeDefaultMS) || '') || ONE_HOUR;

const initialState: TAlertReducerState = {
  snoozeDefaultMs: snoozeDefaultMs,
  inferenceData: {
    alert: {},
    cusip: {},
  },
  alerts: [],
  highlightedAlertId: null, // id of the alert that should be highlighted on page load
};

const alertSlice = createSlice({
  initialState,
  name: "alert",
  reducers: {
    setSnoozeDefaultMs: (state, action: PA<number>) => {
      state.snoozeDefaultMs = action.payload;
      localStorage.setItem(LocalStorageKey.AertSnoozeDefaultMS, action.payload.toString());
    },
    setInferenceData: (state, action: PA<AlertInferenceData>) => {
      state.inferenceData = action.payload;
    },
    setAlerts: (state, action: PA<AlertTableItem[]>) => {
      state.alerts = action.payload;
    },
    setHighlightedAlertId: (state, action: PA<string | null>) => {
      state.highlightedAlertId = action.payload;
    },
  },
});

export const alertActions = alertSlice.actions;
export const alertReducer = alertSlice.reducer;

export const selectSnoozeDefaultMs = (state: TAppState) => state.alert.snoozeDefaultMs;
export const selectInferenceData = (state: TAppState) => state.alert.inferenceData;
export const selectAlerts = (state: TAppState) => state.alert.alerts;
export const selectHighlightedAlertId = (state: TAppState) => state.alert.highlightedAlertId;

export const selectTriggeredAlerts = (excludeSnoozed?: boolean) => (state: TAppState) => {
  const alerts = selectAlerts(state);
  const data = selectInferenceData(state);  

  const triggeredAlerts = alerts.filter(alert => {
    const alertData = data.alert[alert.alert.id];
    if (!alertData?.targetReached) {
      return false;
    }

    return excludeSnoozed ? !isAlertSnoozed(alert.alert) : true;
  })
  
  return triggeredAlerts;
}

export const selectTriggeredAlertsCount = (state: TAppState) => {
  return selectTriggeredAlerts()(state).length;
}
export const selectVisibleAlertsNotifications = (state: TAppState) => {
  return selectTriggeredAlerts(true)(state);
}

export const selectVisibleAlertsNotificationsCount = (state: TAppState) => {
  return selectTriggeredAlerts(true)(state).length;
}

export const selectAlertTargetReached = (alertId: string) => (state: TAppState) => {
  return selectInferenceData(state).alert[alertId].targetReached;
}


const EMPTY_ALERTS_BY_CUSIP: AlertTableItem[] = [];
export const selectAlertsByCusip = (cusip: string) => (state: TAppState) => {
  const inferenceData = selectInferenceData(state);
  const alertData = inferenceData?.cusip?.[cusip];

  if (!alertData) {
    return EMPTY_ALERTS_BY_CUSIP
  }

  // TODO: create a map for alerts by cusip instead of filtering
  const alerts = selectAlerts(state);
  const filteredAlerts = alerts.filter(a => a.bond.cusip === cusip);

  return filteredAlerts;
}

export const selectTriggeredAlertsByCusip = (cusip: string) => (state: TAppState) => {
  const alerts = selectAlertsByCusip(cusip)(state);
  const inferenceData = selectInferenceData(state);
  const alertData = inferenceData?.cusip?.[cusip];

  if (!alertData) {
    return EMPTY_ALERTS_BY_CUSIP;
  }

  const triggeredAlerts = alerts.filter(a => isAlertActive(a.alert) && alertData[a.alert.side]?.targetReached);
  return triggeredAlerts;
}

export const selectAlertsByType = (type: AlertType) => (state: TAppState) => state.alert.alerts.filter(a => {
  if (type === AlertType.Target) {
    return isTargetAlert(a.alert); 
  } else {
    return a.alert.type === type;
  }
});

export const selectAlertInferenceData = (id: string) => (state: TAppState) => state.alert.inferenceData.alert[id];

export const selectAlertBonds = (state: TAppState) => state.alert.alerts.map(a => a.bond);