import { useState } from "react";

export const quantityOptions = [
  '0.001 MM',
  '0.01 MM',
  '0.1 MM',
  '0.25 MM',
  '0.5 MM',
  '1 MM',
  '2 MM',
  '3 MM',
  '4 MM',
  '5 MM'
] as const;

export type QuantityOptions = typeof quantityOptions;


export const useQuantityDropdown = () => {
  const [quantity, setQuantity] = useState<QuantityOptions[number]>('1 MM');
  const quantityNumber = (+quantity.slice(0, -3)) * 1000000;

  return {
    quantity,
    quantityNumber,
    setQuantity,
    quantityOptions,
  }
}