import clsx from "clsx"
import { FaPercent } from "react-icons/fa6"

export const PercentilesButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
        type="button"
        className={clsx('bg-[#5D5F9D] border-[1px] border-transparent group-hover/tr:border-[#A5A6C9] font-medium leading-[1.17rem]  rounded-full text-[#FBFBFD] text-[0.875rem] h-[1.5625rem] w-[1.5625rem] flex items-center justify-center', {
          'hover:contrast-125': onClick,
        })}
        onClick={onClick}
      >
        <FaPercent />
      </button>
  )
}