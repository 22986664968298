import { Dispatch, SetStateAction, useEffect, useId, useState } from 'react';
import Modal from './modal';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { CsvDataRow } from '@/types/utility.types';
import { downloadCsv } from '@/utils/csv.utils';
import { captureException } from '@sentry/react';

type Props = {
  getRows: () => CsvDataRow[] | undefined;
  name: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

export const DownloadCsvModal = ({
  getRows,
  name,
  show,
  setShow
}: Props) => {
  const filenameId = useId();
  const [filename, setFilename] = useState<string>(name);

  useEffect(() => {
    if (show) {
      setFilename(name || '');
    }
  }, [name, show, setFilename]);

  return (
    <>
      <Modal
        body={
          <div className="flex flex-col gap-[1.25rem] place-items-center">
            <label htmlFor={filenameId} className="visually-hidden">Filename</label>
            <input
              className={clsx("bg-[#5D5F9D] border-0 px-[1.25rem] py-[0.625rem]",
                "ring-0 rounded-full",
                "text-[1.25rem] text-[#FBFBFD] w-full",
                "focus:ring-2 focus:ring-inset focus:ring-[#4384C8] placeholder:text-[#C9CADE]/[0.5]")}
              id={filenameId}
              name="filename"
              onChange={e => void setFilename(e.target.value)}
              placeholder="Filename"
              type="text"
              value={filename} 
            />
            <div className="flex flex-row gap-[0.25rem] items-center justify-end w-full">
              <button
                className="bg-[#333557] px-[2rem] py-[0.625rem] rounded-[0.625rem] text-[0.875rem] text-[#FBFBFD]"
                onClick={() => { setShow(false); }}
              >
                Cancel
              </button>
              <button
                className="bg-[#5D5F9D] px-[2rem] py-[0.625rem] rounded-[0.625rem] text-[0.875rem] text-[#FBFBFD]"
                disabled={!filename?.length}
                onClick={() => {
                  try {
                    const rows = getRows();
                    if (isEmpty(rows) || rows === undefined) {
                      toast('No data to download', { autoClose: 5000 });
                      setShow(false);
                      return;
                    }

                    if (!filename.trim().length) {
                      toast.error('Filename cannot be empty');
                      return;
                    }

                    downloadCsv(filename, rows);
                    setShow(false);
                  } catch (e) {
                    console.error(e)
                    captureException(e)
                    toast.error('Error downloading data');
                  }
                }}>
                Download
              </button>
            </div>
          </div>
        }
        show={show}
        title={<div className="w-full">Download</div>} />
    </>
  );
}

