import { BellAlertIcon } from "@heroicons/react/20/solid";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, selectInferenceData, selectTriggeredAlertsByCusip } from "../../store/slices/alert.slice";
import { isEmpty } from "lodash";
import { useOpenPage } from "../../hooks/useOpenPage";
import clsx from "clsx";
import { getAlertTypeLabel, isTargetAlert, isVolatilityAlert } from "./alert.utils";
import { Portal } from "@headlessui/react";
import { Tooltip } from "react-tooltip";
import { AlertTableItem } from "./alertsTable";
import { FormattedPrice } from "../components/formatNumber/formattedPrice";
import { FormatNumber } from "../components/formatNumber/formatNumber";

export const AlertTriggeredIcon = memo(({
  cusip,
  width = 20,
}: {
  cusip: string;
  width?: number;
}) => {
  const dispatch = useDispatch();
  const openPage = useOpenPage();
  const triggeredAlerts = useSelector(selectTriggeredAlertsByCusip(cusip))
  const inferenceData = useSelector(selectInferenceData);
  

  if (isEmpty(triggeredAlerts)) {
    return null;
  }

  const volatilityAlert = triggeredAlerts.find(a => isVolatilityAlert(a.alert));
  const hasVolatilityAlert = !!volatilityAlert;
  const hasMultipleAlerts = triggeredAlerts.length > 1;
  const tooltipId = `alert-triggered-b-${cusip}`

  const handleClick = (a?: AlertTableItem) => () => {
    if (hasMultipleAlerts && !a) {
      return;
    }

    const alert = a || triggeredAlerts[0];
    dispatch(alertActions.setHighlightedAlertId(alert.alert.id));
    openPage.alerts(alert.alert.type);
  }

  

  return (
    <>
      <BellAlertIcon
        data-tooltip-id={tooltipId}
        width={width}
        className={clsx('cursor-pointer', {
          'text-[#FFD700]': !hasVolatilityAlert,
          'text-[#FB275A]': hasVolatilityAlert && inferenceData.alert[volatilityAlert.alert.id].isGapDown,
          'text-[#24f540]': hasVolatilityAlert && inferenceData.alert[volatilityAlert.alert.id].isGapUp,
        })}
        onClick={handleClick()}
      />

      {/* Tooltip */}
      {hasMultipleAlerts && (
        <Portal>
          <Tooltip
            id={tooltipId}
            place='top'
            className="z-30 min-w-[8.875rem] shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]"
            clickable
          >
            {triggeredAlerts.map(alert => {
              const label = getAlertTypeLabel(alert.alert.type)
              const targetType = alert.alert.targetType;

              return (
                <div key={alert.alert.id} className="flex justify-between gap-4 p-2 hover:cursor-pointer hover:bg-[#5D5F9D] rounded-[0.375rem]" onClick={handleClick(alert)}>
                  <span className="min-w-[5rem]" >
                    {label}
                  </span>

                  <span className="mr-auto min-w-[3.25rem]">
                    {alert.alert.side}
                  </span>

                  {isTargetAlert(alert.alert) && (
                    <FormatNumber type={targetType} value={inferenceData.alert[alert.alert.id].current} />
                  )}
                </div>
              )
            })}
          </Tooltip>
        </Portal>
      )}

    </>
  )
})