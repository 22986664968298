import { BellAlertIcon } from "@heroicons/react/20/solid";
import { BellIcon as BellIconOutline, BellSnoozeIcon } from "@heroicons/react/24/outline";
import { AlertTableItem } from "../alertsTable";
import clsx from "clsx";

import { getAlertStateLabel, isAlertDisabled, isAlertSnoozed } from "../alert.utils";
import { Tooltip } from "react-tooltip";
import { Portal } from "@headlessui/react";
import { memo } from "react";
import { DateTime } from "luxon";
import { AlertState } from "../../data/alerts";
import { useSelector } from "react-redux";
import { selectAlertTargetReached } from "../../../store/slices/alert.slice";

export const AlertBellCell = memo(({
  alert,
  onStateChange,
}: {
  alert: AlertTableItem['alert'],
  onStateChange: (state: AlertState) => void;
}) => {
  const targetReached = useSelector(selectAlertTargetReached(alert.id))


  const Icon = isAlertDisabled(alert)
    ? BellIconOutline
    : isAlertSnoozed(alert)
      ? BellSnoozeIcon
      : targetReached
        ? BellAlertIcon
        : BellAlertIcon;


  const tooltipId = `alert-state-${alert.id}`

  function handleClick() {
    const nextState = isAlertSnoozed(alert)
      ? AlertState.Active
      : isAlertDisabled(alert)
        ? AlertState.Active
        : AlertState.Disabled;

    onStateChange(nextState);
  }

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer absolute p-2.5 -translate-x-2.5"
      data-tooltip-id={tooltipId}
    >

      <Icon
        className={clsx('w-[1.25rem] text-[#8183B3] group-hover:text-current', {
          'text-current': targetReached
        })}
      />
      <Portal>
        <Tooltip
          id={tooltipId}
          place='top'
          className="z-30 text-center"
        >
          {getAlertStateLabel(alert)} <br />
          {isAlertSnoozed(alert) && (
            <>
              until {DateTime.fromMillis(alert.snoozedUntil).toLocaleString({ month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
            </>
          )}
        </Tooltip>
      </Portal>
    </div>
  )
})