import { InferenceResult } from "@/hooks/data/useSimpleInferenceData";
import { DEFAULT_POSITION_SIZE, PortfolioColumn, PortfolioColumnTitle } from "./portfolio.constants";
import { PortfolioResponse, Position } from "../data/portfolios";
import { Bond } from "../data/bondIndex";
import { Side } from "../data/api";
import { POSITION_VALUE_FORMAT } from "@/constants";
import { numericFormatter } from "@/utils/number.utils";
import { openConfirmationDialog } from "../components/confirmationDialog/confirmationDialog.utils";
import { FaFileCircleExclamation } from "react-icons/fa6";
import { closeConfirmationDialog } from "@/store/slices/confirmationDialog.slice";
import { ImportResultItem } from "./components/ImportResultItem";
import { ImportModalFileRow } from "./components/ImportModalFileRow";
import { isEmpty } from "lodash";
import { Fragment } from "react";
import { ImportResultUniqueIdMissing } from "./components/ImportResultUniqueIdMIssing";


export const getColumnTitle = (previousString?: string) => (column: PortfolioColumn) => {
  const title = PortfolioColumnTitle[column];

  if (typeof title !== 'string') {
    return title;
  }

  return title.replace('{prevPeriod}', previousString || '')
};

export const calculateBps = (positionValue: number, nav: number) => {
  if (nav === 0) {
    return 0;
  }

  return positionValue / nav * 10000;
}


export function getPositionValue(position: Position, data: InferenceResult<Position & Bond>['data']): number
export function getPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], delta?: boolean): number
export function getPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], delta?: boolean, formatted?: false): number
export function getPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], delta?: boolean, formatted?: true): string
export function getPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], delta: boolean = false, formatted: boolean = false): number | string {
  const { figi, size } = position;

  if (!data[figi]) {
    // TODO: what do we want to return if inference data is missing?
    return 0;
  }

  const bidData = data[figi][Side.bid];
  const offerData = data[figi][Side.offer];
  const bid = (delta ? bidData.price.diff : bidData.price.current) || 0;
  const offer = (delta ? offerData.price.diff : offerData.price.current) || 0;
  const price = size >= 0 ? bid : offer;

  const value = size * price / 100;

  if (formatted) {
    return numericFormatter(value, POSITION_VALUE_FORMAT);
  }

  return value;
}

export function getPrevPositionValue(position: Position, data: InferenceResult<Position & Bond>['data']): number
export function getPrevPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], formatted?: true): string
export function getPrevPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], formatted?: false): number
export function getPrevPositionValue(position: Position, data: InferenceResult<Position & Bond>['data'], formatted: boolean = false): number | string {
  const { figi, size } = position;

  if (!data[figi]) {
    return 0;
  }

  const bid = data[figi][Side.bid].price.prev || 0;
  const offer = data[figi][Side.offer].price.prev || 0;
  const price = size >= 0 ? bid : offer;

  const value = size * price / 100;

  if (formatted) {
    return numericFormatter(value, POSITION_VALUE_FORMAT);
  }

  return value;
}

export const getPortfolioNAV = (portfolio: PortfolioResponse | undefined, data: InferenceResult<Position & Bond>['data']): number => {
  if (!portfolio || !portfolio.value) {
    return 0;
  }

  if (typeof portfolio.value.nav === 'number') {
    return portfolio.value.nav;
  }

  // using deepmm nav by default 
  const deepmmNAV = portfolio.value.positions.reduce((acc, position) => {
    return acc + Math.abs(getPositionValue(position, data));
  }, 0)

  return deepmmNAV;
}

export function getPositionPrice(position: Position, data: InferenceResult<Position & Bond>['data'], key: 'current' | 'diff' | 'prev'): number | undefined
export function getPositionPrice(position: Position, data: InferenceResult<Position & Bond>['data'], key: 'current' | 'diff' | 'prev', formatted?: false): number | undefined
export function getPositionPrice(position: Position, data: InferenceResult<Position & Bond>['data'], key: 'current' | 'diff' | 'prev', formatted?: true): string
export function getPositionPrice(position: Position, data: InferenceResult<Position & Bond>['data'], key: 'current' | 'diff' | 'prev', formatted?: boolean): number | string | undefined {
  const { figi, size } = position;

  if (!data[figi]) {
    // TODO: what do we want to return if inference data is missing?
    return formatted ? '-' : 0;
  }

  const bid = data[figi][Side.bid].price;
  const offer = data[figi][Side.offer].price

  if (formatted) {
    const keyString = key === 'current'
      ? 'currentString'
      : key === 'diff'
        ? 'diffString'
        : 'prevString';

    return size >= 0 ? bid[keyString] : offer[keyString]
  } else {
    return size >= 0 ? bid[key] : offer[key];
  }
}

export function showCsvImportFailedDialog({
  fileName,
  missingCusips,
  missingFigis,
  missingIsins,
  onRetry,
}: {
  fileName?: string;
  missingCusips: string[];
  missingFigis: string[];
  missingIsins: string[];
  onRetry: () => void
}) {
  openConfirmationDialog({
    title: 'Import fail',
    buttonText: 'Try again',
    content: (
      <div className='text-[0.875rem] w-full'>
        We were not able to bring the information. Please check the file.
        {fileName && (
          <div className='mt-4 flex items-center gap-[0.3125rem] text-[#EDEDF4]'>
            <FaFileCircleExclamation className='min-w-[1rem]' />
            <span className='min-w-0 flex-1 truncate'>
              <span className=''>{fileName}</span>
            </span>
          </div>
        )}


        <div className='mt-4  p-3 bg-[#1F2034] rounded-md'>
          * Make sure that file is <span className='text-[#EDEDF4]'>.csv</span> file with <span className='text-[#EDEDF4]'>comma</span> separated columns. One of the columns should be <span className='text-[#EDEDF4]'>`figi` or `cusip`</span>.
        </div>

        <ImportResultUniqueIdMissing title="Missing CUSIPs" missingIds={missingCusips} />
        <ImportResultUniqueIdMissing title="Missing FIGIs" missingIds={missingFigis} />
        <ImportResultUniqueIdMissing title="Missing ISINs" missingIds={missingIsins} />
      </div>
    ),
    onButtonClick: () => {
      closeConfirmationDialog();
      onRetry();
    }
  })
}

export function showCsvImportSuccessDialog({
  fileName,
  positionsAddedCount,
  positionsUpdatedCount,
  missingCusips,
  missingFigis,
  missingIsins,
  sizeMissingCount,
}: {
  fileName?: string;
  positionsUpdatedCount: number;
  positionsAddedCount: number;
  sizeMissingCount: number;
  missingFigis: string[];
  missingCusips: string[];
  missingIsins: string[];
}) {
  openConfirmationDialog({
    title: 'Import completed',
    buttonText: 'Ok',
    hideCancel: true,
    content: (
      <div className='text-[0.875rem] w-full max-h-[70vh] overflow-y-auto overflow-x-hidden'>
        <ImportModalFileRow fileName={fileName} />


        <div className='mt-4 text-[1rem] flex-col flex gap-1'>
          <ImportResultItem title="Added positions" value={positionsAddedCount} />
          <ImportResultItem title="Updated positions" value={positionsUpdatedCount} />
          <ImportResultItem title="Positions with missing size" value={sizeMissingCount} />
          
          <ImportResultUniqueIdMissing title="Missing CUSIPs" missingIds={missingCusips} />
          <ImportResultUniqueIdMissing title="Missing FIGIs" missingIds={missingFigis} />
          <ImportResultUniqueIdMissing title="Missing ISINs" missingIds={missingIsins} />
        </div>

        {sizeMissingCount > 0 && (
          <div className='mt-4  p-3 bg-[#1F2034] rounded-md'>
            * default size <span className='text-[#EDEDF4]'>1 MM</span> for positions with missing size
          </div>
        )}
      </div>
    ),
  })
}

export function showCsvImportSuccessDialogNothingChanged({
  fileName,
  missingCusips,
  missingFigis,
  missingIsins,
}: {
  fileName?: string;
  missingFigis: string[];
  missingCusips: string[];
  missingIsins: string[];
}) {
  openConfirmationDialog({
    title: 'Import completed',
    buttonText: 'Ok',
    hideCancel: true,
    content: (
      <div className='text-[0.875rem] w-full max-h-[70vh] overflow-y-auto overflow-x-hidden'>
        Seems like your portfolio is alread in sync with the file.

        <ImportModalFileRow fileName={fileName} />

        <ImportResultUniqueIdMissing title="Missing CUSIPs" missingIds={missingCusips} />
        <ImportResultUniqueIdMissing title="Missing FIGIs" missingIds={missingFigis} />
        <ImportResultUniqueIdMissing title="Missing ISINs" missingIds={missingIsins} />

      </div>
    ),
  })
}