import { useCallback, useMemo } from 'react';
import { useOpenPage } from '../../hooks/useOpenPage';
import { useCreatePortfolioObjectMutation, useLazyGetPortfolioQuery, useUpdatePortfolioObjectMutation } from '@/store/api/portfolio.endpoints';
import { captureException } from '@sentry/core';
import { toast } from 'react-toastify';
import { useDeleteObjectMutation } from '@/store/api/object.endpoints';
import { ObjectResponse } from './objects';
import { DEFAULT_POSITION_SIZE, MAX_POSITIONS_IN_PORTFOLIO } from '../portfolio/portfolio.constants';
import { openConfirmationDialog } from '../components/confirmationDialog/confirmationDialog.utils';

export type Position = {
    figi: string;
    // position: number; // deprecated. Do not use this field
    size:  number; // this property is missing for old positions. We add it as `0` values when we fetch portfolio data
}

export type PortfolioValue = {
  nav: number | null;
  positions: Position[];
}

export type PortfolioResponse = ObjectResponse<PortfolioValue>


export const useCreatePortfolio = () => {
  const openPage = useOpenPage();
  const [createPortfolioApi] = useCreatePortfolioObjectMutation();

  const createPortfolio = useCallback(async (name: string, figis: Set<string>) => {
    if (!name.length) {
      return;
    }

    try {
      const positions: Position[] = Array.from(figis).map((figi): Position => ({ figi, size: DEFAULT_POSITION_SIZE }));
      const result = await createPortfolioApi({ name, positions }).unwrap();
      openPage.portfolio(result.id);
      return result;
    } catch (e) {
      captureException(e);
      toast.error('Failed to create portfolio, try again...');
    }

  }, [createPortfolioApi, openPage])

  return createPortfolio;
}


export const useAddToPortfolio = () => {
  const openPage = useOpenPage();
  const [getPortfolioApi] = useLazyGetPortfolioQuery();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  const addToPortfolio = useMemo(() =>
    async (id: string, figis: Set<string>) => {
      let portfolio: PortfolioResponse | null = null;
      try {
        portfolio = await getPortfolioApi(id).unwrap()
      } catch (e) {
        captureException(e);
        toast.error('Failed to add to portfolio, try again...');
        return;
      }


      const existingFigis = new Set(portfolio.value.positions.map(p => p.figi));
      const newPositions: Position[] = Array.from(figis)
        .filter(figi => !existingFigis.has(figi)).map((figi): Position => ({ figi, size: DEFAULT_POSITION_SIZE }))

      const positions: Position[] = [
        ...portfolio.value.positions,
        ...newPositions
      ];

      if (positions.length > MAX_POSITIONS_IN_PORTFOLIO) {
        let title: string;
        let content: string;

        if (newPositions.length === 1) {
          title = 'You can not add more positions to this portfolio.';
          content = `Maximum number of positions is ${MAX_POSITIONS_IN_PORTFOLIO}.`;
        } else {
          title = `You can have up to ${MAX_POSITIONS_IN_PORTFOLIO} positions in a portfolio.`;
          content = `Please remove ${positions.length - MAX_POSITIONS_IN_PORTFOLIO} positions before adding them to portfolio.`;
        }
        openConfirmationDialog({
          title,
          content,
          buttonText: 'OK',
          hideCancel: true,
        })
        return {
          success: false,
          tooManyPositions: true,
        };
      }

      try {
        const response = await updatePortfolioApi({
          id,
          version: portfolio.version,
          optimistic: false,
          data: {
            ...portfolio.value,
            positions,
          }
        }).unwrap();
        openPage.portfolio(response.id)
        return response;
      } catch (e) {
        captureException(e);
        toast.error('Failed to add to portfolio, try again...');
      }
    }
  , [openPage, getPortfolioApi, updatePortfolioApi]);

  return addToPortfolio;
}


export const useRemoveFromPortfolio = () => {
  const [getPortfolioApi] = useLazyGetPortfolioQuery();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  const removeFromPortfolio = useCallback(
    async (id: string, figisToRemove: Set<string>) => {
      try {
        const portfolio = await getPortfolioApi(id).unwrap();
        const positions = portfolio.value.positions.filter(p => !figisToRemove.has(p.figi))
        await updatePortfolioApi({
          id,
          version: portfolio.version,
          optimistic: false,
          data: {
            ...portfolio.value,
            positions,
          }
        });
      } catch (e) {
        captureException(e);
        toast.error('Failed to remove from portfolio, try again...');
        return false;
      }
    }
  , [getPortfolioApi, updatePortfolioApi]);

  return removeFromPortfolio;
}

export const useEditPortfolio = () => {
  const [getPortfolioApi] = useLazyGetPortfolioQuery();
  const [updatePortfolioApi] = useUpdatePortfolioObjectMutation();

  const editPortfolio = useCallback(
    async (id: string, name: string) => {
      try {
        const portfolio = await getPortfolioApi(id).unwrap();
        await updatePortfolioApi({
          id,
          metadata: { name },
          version: portfolio.version,
          optimistic: false,
        });
      } catch (e) {
        captureException(e);
        toast.error('Failed to edit portfolio, try again...');
        return false;
       }
    }
  , [getPortfolioApi, updatePortfolioApi]);

  return editPortfolio;
}

export const useDeletePortfolio = () => {
  const [deletePortfolioApi] = useDeleteObjectMutation();

  const deletePortfolio = useCallback(
    async (id: string) => {
      try {
        await deletePortfolioApi({ type: 'portfolio', id }).unwrap();
      } catch (e) {
        captureException(e);
        debugger;
        toast.error('Failed to delete portfolio, try again...');
        return false;
      }
    }
  , [deletePortfolioApi]);

  return deletePortfolio;
}