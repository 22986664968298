import { useContext } from "react";
import { XMarkIcon } from '@heroicons/react/20/solid';
import { DataContext } from "../data/dataProvider";
import Notification from "./notification";

const ApplicationErrorsNotification = () => {

  const { applicationErrorList, dispatch } = useContext(DataContext);

  if (!Array.isArray(applicationErrorList)) {
    // temporary fix as it breaks application for some reason
    return null;
  }

  // TODO: handle data loading notifications, not sure if spamming user with those is a good idea. We need to show these kind of errors above the table or in the rows where we have an issue or come up with a solution where we save to localstorage that user closed these erorrs as they currently triggered on each refresh or even more often.
  return null;

  return (
    <Notification
      body={
        <div className="flex flex-col px-[1rem] py-[0.25rem] w-full">
          <div className="flex flex-row items-center justify-end w-full">
            <button
              type="button"
              className="bg-[#0A0B11] flex flex-row gap-[0.625rem] items-center mt-[0.625rem] p-[0.25rem] rounded-[0.625rem] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => void dispatch({ type: 'deleteApplicationErrors', payload: applicationErrorList})}
            >
              <span>Clear All</span>
              <XMarkIcon className="h-[1.25rem] w-[1.25rem]" aria-hidden="true" />
            </button>
          </div>
          {
            applicationErrorList.map(e =>
              <div className="border-b border-[#7D7D82] gap-[0.125rem] grid grid-cols-[1fr,auto] grid-rows-1 py-[0.625rem] w-full last:border-0" key={e}>
                <div>{e}</div>
                <button
                  type="button"
                  className="bg-[#0A0B11] p-[0.25rem] rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => void dispatch({ type: 'deleteApplicationErrors', payload: [e]})}
                >
                  <span className="sr-only">Clear</span>
                  <XMarkIcon className="h-[1.25rem] w-[1.25rem]" aria-hidden="true" />
                </button>
              </div>
            )
          }
        </div>
      }
      divCss="max-h-[16rem] overflow-auto"
      show={applicationErrorList.length > 0}
    />
  );
}

export default ApplicationErrorsNotification;
