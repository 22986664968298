import { useCallback, useEffect, useState } from "react"
import { NavigateOptions, useSearchParams } from "react-router-dom";

const DEFAULT_PAGE_SIZE = 25;

export const getPageSize = (pageSizeParam: string | number | null) =>
  Math.max(
    10,
    Math.min(
      100,
      pageSizeParam === null || isNaN(+pageSizeParam) ? DEFAULT_PAGE_SIZE : +pageSizeParam
    )
  );

export const useTablePagination = ({
  useUrl,
  tableName,
  itemsCount,
  defaultPageSize = DEFAULT_PAGE_SIZE,
}: {
  useUrl?: boolean
  tableName: string;
  itemsCount: number;
  defaultPageSize?: number;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageSize = useUrl ? getPageSize(searchParams.get('pageSize')) : defaultPageSize;
  const [startLocal, setStartLocal] = useState(0);
  const [localPageSize, setLocalPageSize] = useState(initialPageSize);
  const pageSize = useUrl ? getPageSize(searchParams.get(`${tableName}-pageSize`)) : localPageSize;

  const getStart = useCallback((startParam: string | number | null) => {
    return Math.max(
      0,
      Math.min(
        Math.floor((itemsCount - 1) / pageSize) * pageSize,
        Math.floor((startParam === null || isNaN(+startParam) ? 0 : +startParam) / pageSize) * pageSize
      )
    )
  }, [pageSize, itemsCount]);

  const start = useUrl ? getStart(searchParams.get(`${tableName}-start`)) : startLocal;

  useEffect(() => {
    // reset pagination when items change
    if (useUrl) {
      // reset search params pagination
      setSearchParams(prev => {
        prev.set(`${tableName}-pageSize`, `${getPageSize(prev.get(`${tableName}-pageSize`))}`);
        prev.set(`${tableName}-start`, `${getStart(prev.get(`${tableName}-start`))}`);
        return prev;
      }, { replace: true });
    } else {
      // reset local state pagination
      setStartLocal(start => getStart(start));
    }
  }, [getStart, setSearchParams, itemsCount, tableName, useUrl]);

  function goToPage(startItemIndex: number, navigateOpts?: NavigateOptions | undefined) {
    if (useUrl) {
      setSearchParams(prev => {
        prev.set(`${tableName}-start`, `${startItemIndex}`);
        return prev;
      }, navigateOpts)
    } else {
      setStartLocal(() => startItemIndex)
    }
  }

  function goToPrevPage() {
    goToPage(getStart(start - pageSize));
  }

  function goToNextPage() {
    goToPage(getStart(start + pageSize));
  }

  return {
    pageSize,
    start,
    getStart,
    goToPage,
    goToPrevPage,
    goToNextPage,
  }
}