import { FunctionComponent } from "react";
import { classNames } from "..";

const Loading: FunctionComponent<{ className?: string, spinnerClassName?: string }> = ({ className, spinnerClassName }) => (
  /* adapted from https://loading.io/css/ */
  <div className={classNames("flex items-center justify-center px-5 z-[40]", className || '')}>
    <div className={classNames('animate-spin border-[.3125rem] border-[#C9CADE44_transparent_#C9CADE44_transparent] h-10 w-10 rounded-[50%]', spinnerClassName || '')}></div>
  </div>
);

export const LoadingOverlay = ({ className }: { className?: string }) => (
  <Overlay className={className}>
    <Loading />
  </Overlay>
);

export const Overlay = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return <div className={classNames('absolute inset-0 bg-[#00000033] z-[10] flex items-center justify-center', className || '')}>{children}</div>
}

export default Loading;
