export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// creates memoized getRandomColor function
// this function will return the same color for the same key
export function generateMemoizedGetRandomColor() {
  const cache = new Map<string, string>();

  return (key: string) => {
    if (!cache.has(key)) {
      cache.set(key, getRandomColor());
    }

    return cache.get(key);
  }
}