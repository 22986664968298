import { ReactNode } from "react";

export const ImportResultItem = ({ 
  title, 
  value,
  children,
}: {
  title: string,
  value?: string | number | null,
  children?: ReactNode,
}) => {
  if (!value && !children) {
    return null;
  }

  return (
    <div className="flex justify-between gap-4 max-h-[18.75rem] overflow-y-auto -mr-2">
      <div className="sticky top-0">{title}</div>
      <div className="text-right pr-2">{children || value}</div>
    </div>
  )
}