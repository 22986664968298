import { FunctionComponent, ReactNode } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../..';
import ListBox from './listbox';
import clsx from 'clsx';
import { Tooltip } from '@components/tooltip';
import { Percentile, percentileOptions } from '@/constants';

const TablePercentileListBox: FunctionComponent<{
  selectValue: (v: Percentile) => void,
  value: Percentile
  disabled?: boolean;
  disabledTooltipText?: ReactNode;
}> =
  ({ selectValue, value, disabled, disabledTooltipText  }) => {
    return (
      <Tooltip 
        tooltip={disabledTooltipText} 
        openEvents={{
          click: disabled ? true : false,
        }}
        closeEvents={{
          mouseleave: true,
        }}
      >
        <div className={clsx(disabled && 'cursor-not-allowed')}>
          <div className={clsx(disabled && 'pointer-events-none')}>
            <ListBox
              button={props =>
                <div className={clsx('bg-[#8183B3] font-medium leading-[1.17rem] px-[0.3125rem] rounded-[0.3125rem] text-[#0A0B11] text-[1rem] text-right w-[3.75rem] whitespace-nowrap', {
                  'cursor-not-allowed opacity-50': disabled,
                })}>
                  {props.value}%
                </div>
              }
              option={(value, props) =>
                <>
                  <div className={`${props.selected ? 'font-medium' : 'font-normal'}`}>
                    {value}%
                  </div>
                  {props.selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-[0.25rem]">
                      <CheckIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              }
              optionCss={() => 'bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[1.6rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0] hover:bg-[#484A7A]'}
              optionsCss={classNames('absolute -left-[0.625rem] max-h-[7.875rem] mt-[0.1875rem] overflow-auto rounded-[0.625rem] w-[5rem] z-[1]')}
              selectValue={selectValue}
              value={value}
              values={percentileOptions}
              disabled={disabled}
            />
          </div>
        </div>
      </Tooltip>
    );
  }

export default TablePercentileListBox;
