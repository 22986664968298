import { Chart, TooltipModel } from "chart.js";
import { CustomLabel } from "./chart.types";

const SINGLE_TOOLTIP_WIDTH = 240;
const MULTIPLE_TOOLTIP_WIDTH = 400;
const TOOLTIP_HEIGHT = 280;


const getOrCreateTooltip = (chart: Chart) => {
  if (!chart.canvas.parentNode) {
    return;
  }

  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.background = 'rgba(93, 95, 157, 0.8)';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.padding = '8px';
    tooltipEl.style.width = `${SINGLE_TOOLTIP_WIDTH}px`;

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.width = '100%';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: { chart: Chart, tooltip: TooltipModel<any> }) => {
  const { chart, tooltip } = context;

  if (!chart) {
    return;
  }

  // Tooltip Element
  const tooltipEl = getOrCreateTooltip(chart);

  if (!tooltipEl) {
    return;
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  let bodyLines: string[][] = [];
  if (tooltip.body) {
    bodyLines = tooltip.body.map((b) => b.lines);

    const { tableBody, tableHead } = bodyLines.length > 1
      ? renderMultipleDatasetTooltip(tooltip, bodyLines)
      : renderSingleDatasetTooltip(tooltip, bodyLines);

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot?.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }


  // tooltip position
  const tooltipWidth = bodyLines?.length > 1 ? MULTIPLE_TOOLTIP_WIDTH : SINGLE_TOOLTIP_WIDTH;
  const { left, top } = getTooltipPosition(chart, tooltip, tooltipWidth);
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = `${left}px`;
  tooltipEl.style.top = `${top}px`;
  tooltipEl.style.width = `${tooltipWidth}px`;
};

// getSeparatorLine - creates an empty row to separate table rows
function getSeparatorLine() {
  const tr = document.createElement('tr');
  tr.style.backgroundColor = 'inherit';
  tr.style.borderWidth = '0';

  const td = document.createElement('td');
  td.colSpan = 2;
  td.style.height = '20px';

  tr.appendChild(td);
  return tr;
}

// getTableHeader - returns table header
const getTableHeader = (tooltip: TooltipModel<any>) => {
  const titleLines = tooltip.title || [];
  const tableHead = document.createElement('thead');

  titleLines.forEach(title => {
    const tr = document.createElement('tr');
    tr.style.borderWidth = '0';

    const th = document.createElement('th');
    th.style.borderWidth = '0';
    const text = document.createTextNode(title);

    th.appendChild(text);
    tr.appendChild(th);
    tableHead.appendChild(tr);
  });

  return tableHead;
}

function getTooltipPosition(chart: Chart, tooltip: TooltipModel<any>, tooltipWidth: number) {
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const canvasHeight = chart.canvas.clientHeight;
  const canvasWidth = chart.canvas.clientWidth

  let top = TOOLTIP_HEIGHT + tooltip.caretY > canvasHeight ? canvasHeight - TOOLTIP_HEIGHT - 40 : tooltip.caretY;

  let left = tooltip.caretX;
  if (tooltip.caretX + tooltipWidth > canvasWidth) { // tooltip is too far right
    left = canvasWidth - (tooltipWidth / 2)
  } else if (tooltip.caretX < tooltipWidth / 2) { // tooltip is too far left
    left = tooltipWidth / 2;
  }

  left += positionX;
  top += positionY;

  return {
    top,
    left,
  }
}

const getDataLine = (line: CustomLabel) => {
  const tr = document.createElement('tr');
  tr.style.backgroundColor = 'inherit';
  tr.style.borderWidth = '0';

  const tdLabel = document.createElement('td');
  tdLabel.style.borderWidth = '0';
  const labelText = document.createTextNode(line.label || '');
  tdLabel.appendChild(labelText);
  tdLabel.style.paddingRight = '12px';

  const tdValue = document.createElement('td');
  tdValue.style.borderWidth = '0';
  const valueText = document.createTextNode(`${line.value ?? ''}`);
  tdValue.appendChild(valueText);
  tdValue.style.textAlign = 'right';

  if (line.id === 'price') {
    tdLabel.style.fontWeight = '600';
    tdValue.style.fontWeight = '600';
    tdLabel.style.fontSize = '14px';
    tdValue.style.fontSize = '14px';
  }

  tr.appendChild(tdLabel);
  tr.appendChild(tdValue);

  return tr;
}

function renderSingleDatasetTooltip(tooltip: TooltipModel<any>, bodyLines: string[][]) {
  const tableHead = getTableHeader(tooltip);

  const tableBody = document.createElement('tbody');
  tableBody.style.fontSize = '12px';

  bodyLines.forEach((body, i) => {
    const lines = body as unknown as CustomLabel[];

    lines.forEach((line) => {

      if (line.type === 'separtor') {
        tableBody.appendChild(getSeparatorLine());
        return
      }

      const tr = getDataLine(line);
      tableBody.appendChild(tr);
    })
  });

  return {
    tableHead,
    tableBody
  }
}

function renderMultipleDatasetTooltip(tooltip: TooltipModel<any>, bodyLines: string[][]) {
  // table head
  const tableHead = document.createElement('thead');
  const tr = createRow();
  tr.appendChild(createColumn('Cusip'));
  tr.appendChild(createColumn('Ticker'));
  tr.appendChild(createColumn('Coupon'));
  tr.appendChild(createColumn('Maturity'));
  tr.appendChild(createColumn(''));
  tableHead.appendChild(tr);


  // table body
  const tableBody = document.createElement('tbody');

  bodyLines.forEach((body, i) => {
    const lines = body as unknown as CustomLabel[];
    const tr = createRow();

    const getValue = (id: string) => `${lines.find((line) => line.id === id)?.value || ''}`;

    // cusip
    const cusip = getValue('cusip');
    tr.appendChild(createColumn(cusip, 'td'));

    // ticker
    const ticker = getValue('ticker');
    tr.appendChild(createColumn(ticker, 'td'));
    
    // coupon
    const coupon = getValue('coupon');
    tr.appendChild(createColumn(coupon, 'td'));

    // maturity
    const maturity = getValue('maturity');
    tr.appendChild(createColumn(maturity, 'td'));

    // price
    const price = getValue('price');
    tr.appendChild(createColumn(price, 'td'));

    tableBody.appendChild(tr);
  });


  return {
    tableHead,
    tableBody
  }
}

export const createColumn = (text: string, tag: 'th' | 'td' = 'th') => {
  const th = document.createElement(tag);
  th.style.borderWidth = '0';
  th.style.paddingRight = '12px';
  th.style.textAlign = 'left';
  th.style.fontSize = '12px';
  th.style.fontWeight = tag === 'th' ? '600' : '400';
  th.style.whiteSpace = 'nowrap';
  const textNode = document.createTextNode(text);
  th.appendChild(textNode);
  return th;
}

const createRow = () => {
  const tr = document.createElement('tr');
  tr.style.borderWidth = '0';
  return tr;
}