import { Drawer } from "@/app/components/drawer/drawer"
import { NoteItem } from "./components/noteItem"
import { CircleButton } from "@/app/components/buttons/CircleButton"
import { FaFileCirclePlus } from "react-icons/fa6"
import { UseBondObjectResult, useBondObject } from "@/app/data/bonds"
import { LoadingOverlay } from "@/app/loading"
import { WarningTooltip } from "@/app/components/warningTooltip"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useSyncBondObject } from "../../hooks/useSyncBondObject"
import { Note } from "@/types/types"
import { openYesNoDialog } from "@/app/components/confirmationDialog/confirmationDialog.utils"
import { isEmpty, noop } from "lodash"

export const NotesDrawer = ({
  open,
  onClose,
  bondObjectApiRes,
}: {
  open: boolean;
  onClose: () => void;
  bondObjectApiRes: UseBondObjectResult
}) => {
  const {
    objectMissing,
    isError,
    isFetching,
    data,
  } = bondObjectApiRes;
  const [newNoteVisible, setNewNoteVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const { createNote, updateNote, deleteNote } = useSyncBondObject({ bondObject: data });
  const loading = isFetching || (objectMissing && !isError); // we consider object missing as loading state unless there is an error as we create object right away if it's missing

  // delete note
  const handleDeleteNote = (note: Note) => {
    setConfirmDeleteVisible(true)

    openYesNoDialog({
      title: 'Delete note',
      content: 'Are you sure you want to delete this note?',
      onButtonClick: () => {
        if (!note.id) {
          return;
        }
        setConfirmDeleteVisible(false)
        deleteNote(note.id)
      },
      onCancelClick: () => {
        setConfirmDeleteVisible(false)
      }
    })
  }

  // group notes by date
  const grouppedNotes: Record<string, Note[]> = useMemo(() => {
    if (!data) {
      return {};
    }
    const dateFormatter = new Intl.DateTimeFormat('default', { dateStyle: 'medium' });
    const today = dateFormatter.format(new Date())
    const sortedNotes = [...data.value.notes].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

    const grouped = sortedNotes.reduce((acc, note) => {

      let date = dateFormatter.format(new Date(note.createdAt))
      if (date === today) {
        date = 'Today'
      }

      if (!acc[date]) {
        acc[date] = []
      }

      acc[date].push(note)

      return acc
    }, {} as Record<string, Note[]>)

    return grouped;
  }, [data?.value.notes])


  return (
    <Drawer
      hideBackdrop
      open={open}
      onClose={confirmDeleteVisible ? noop : onClose} // we need to prevent closing drawer when confirm delete dialog is open (as outside click will close the drawer)
      position="right"
      top='top-[--header-height]'
    >
      {loading && <LoadingOverlay />}

      {/* content */}
      <div className="w-[20.625rem] px-[0.625rem] pb-6 pt-10">
        {isError && (
          <div className="absolute flex p-2 text-red-500  text-[0.875rem] gap-2.5">
            <ExclamationTriangleIcon width={40} />
            Error happened while loading notes. Refresh the page to try again.
          </div>
        )}

        {!isError && (
          <>
            {/* add note button */}
            <CircleButton className="top-1.5 left-2 absolute" onClick={() => setNewNoteVisible(true)}>
              <FaFileCirclePlus />
            </CircleButton>

            {newNoteVisible && (
              <div className="mt-4">
                <NoteItem
                  note={{
                    createdAt: new Date().toISOString(),
                    text: '',
                  }}
                  onSave={(text) => {
                    createNote(text)
                    setNewNoteVisible(false)
                  }}
                  onCancel={() => setNewNoteVisible(false)}
                />
              </div>
            )}

            {/* notes list */}
            {isEmpty(data?.value.notes) && !newNoteVisible && !loading && (
              <div className="text-[#8183B3] text-center text-[0.875rem] leading-4 mb-[0.9375rem] mt-4">
                No notes yet
              </div>
            )}

            {Object.entries(grouppedNotes).map(([date, notes]) => {

              return (
                <Fragment key={date}>
                  <div className="text-[#8183B3] text-center text-[0.875rem] leading-4 mb-[0.9375rem] mt-4">
                    {date}
                  </div>

                  <div className="flex flex-col gap-5 mb-[0.9375rem]">
                    {notes.map((note) => {
                      return (
                        <NoteItem
                          key={note.id}
                          note={note}
                          onSave={(text, note) => {
                            if (!note.id) {
                              return;
                            }

                            updateNote({
                              id: note.id,
                              text,
                            })
                          }}
                          onDelete={handleDeleteNote}
                        />
                      )
                    })}
                  </div>
                </Fragment>
              )
            })}


          </>
        )}


      </div>
    </Drawer>
  )
}