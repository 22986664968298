import { Portal } from "@headlessui/react";
import { PartialUpdateData, useSyncAlerts } from "../hooks/useSyncAlerts";
import { alertEndpoints } from "../../../../store/api/alert.endpoints";
import { useAlertObject } from "../../hooks/useAlertObject";
import { getAppState, useSelector } from "../../../../store";
import { selectAlerts, selectVisibleAlertsNotifications, selectVisibleAlertsNotificationsCount } from "../../../../store/slices/alert.slice";
import { AlertState } from "../../../data/alerts";
import { AlertSnoozeButton } from "./alertSnoozeButton";
import { AlertTableItem } from "../../alertsTable";

export const AlertsNotificationsActions = () => {
  const { updateAlerts } = useSyncAlerts();
  const { alertObjectDetails } = useAlertObject();
  const visibleNotificationsCount =  useSelector(selectVisibleAlertsNotificationsCount)

  if (visibleNotificationsCount < 2) {
    return null;
  }

  const getVisibleNotificationsIdsMap = () => {
    const visibleNotifications = selectVisibleAlertsNotifications(getAppState())
    const visibleNotificationsIdsMap = visibleNotifications.reduce((acc, a) => {
      acc[a.alert.id] = true;
      return acc;
    }, {} as Record<string, boolean>)

    return visibleNotificationsIdsMap;
  }

  const getUpatedAlerts = (cb: (a: AlertTableItem) => PartialUpdateData) => {
    const visibleNotificationsIdsMap = getVisibleNotificationsIdsMap();
    const alerts = selectAlerts(getAppState());

    return alerts
      .filter(a => visibleNotificationsIdsMap[a.alert.id])
      .map(cb)
  }

  function handleDisableAll(e: React.MouseEvent) {
    e.stopPropagation();
    const updatedAlerts = getUpatedAlerts(a => ({
      id: a.alert.id,
      state: AlertState.Disabled,
    }))

    updateAlerts(updatedAlerts);
  }

  function handleSnoozeAll(durationMs: number) {
    const snoozedUntil = Date.now() + durationMs;
    const updatedAlerts = getUpatedAlerts(a => ({
      id: a.alert.id,
      snoozedUntil,
    }))

    updateAlerts(updatedAlerts);
  }

  return (
    <Portal>
      <div className="text-sm font-medium fixed z-[1001] bg-red h-[3rem] top-[4.2rem] right-4 flex items-center gap-2.5   mt-2.5 rounded-[0.625rem] justify-between text-[#EDEDF4] ">
        <button type="button" onClick={handleDisableAll} className="h-[3rem] px-4 rounded-[50px] bg-[#0A0B11] text-[#FB275A] hover:text-[#ff4c76]">
          Disable all
        </button>

        <AlertSnoozeButton 
          onSnooze={handleSnoozeAll} 
          renderLabel={(d) => `Snooze all ${d}`} 
          className="h-[3rem] pl-2 pr-4 rounded-[50px] bg-[#0A0B11]"
        />
      </div>
    </Portal>
  )
}