import { captureException } from "@sentry/core";
import { baseApi } from ".";
import { portfolioEndpoints } from "./portfolio.endpoints";
import { runEndpoints } from "./run.endpoints";

export const objectEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    deleteObject: builder.mutation<null, { type: 'portfolio' | 'run', id: string }>({
      queryFn:  async ({ id }, api, o, baseQuery) => {
        const privilege = await baseQuery({ url: `/privileges/${id}`, method: 'GET', });

        if (privilege.error) {
          return privilege;
        }
        
        if (!Array.isArray(privilege?.data)) {
          return { error: { status: 404, data: 'Privilege not found' } };
        }
        
        if (privilege.data[0]['privilege'] !== 'owner') {
          return { error: { status: 403, data: 'Not owner' } };
        }

        const result = await baseQuery({ 
          url: `/privileges/${id}`, 
          method: 'PATCH',
          body: {
            email: privilege.data[0]['email'],
            privilege: null,
            privilege_version: privilege.data[0]['privilege_version']
          }
        })

        if ((result?.data as any)?.message !== 'Privilege successfully removed.') {
          return { error: { status: 500, data: 'Failed to remove privilege' } };
        }

        return { data: null };
      },

      // update data in store
      async onQueryStarted( { id, type  }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          
          try {
            if (type === 'portfolio') {
              dispatch(
                portfolioEndpoints.util.updateQueryData('getPortfolioList', undefined, (prevData) => {
                  return prevData.filter(p => p.id !== id)
                })
              );
            } else if (type === 'run') {
              dispatch(
                runEndpoints.util.updateQueryData('getRunList', undefined, (prevData) => {
                  return prevData.filter(p => p.id !== id)
                })
              );
            }
          } catch (e) {
            captureException(e)
            console.error(e)
          }
        } catch (e) {}
      },
    })

  }),
});

export const {
  useDeleteObjectMutation,
} = objectEndpoints;


