
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
// import { tokenReceived, loggedOut } from './authSlice'
import { Mutex } from 'async-mutex'
import { TOKEN_REFRESH_WINDOW } from '../../constants'
import { TAppState } from '..'
import { selectApiUrl, selectExpiration, selectRefreshTokenFn, selectSignOut } from '../slices/auth.slice'

const mutex = new Mutex()

function getBaseQuery(apiUrl: string) {
  return fetchBaseQuery({
    // baseUrl: `${API_BASE_URL}`,
    baseUrl: apiUrl,
    mode: 'cors',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as TAppState).auth.token;
  
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
  
      return headers;
    },
  });
}


export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  // check if we need to refresh the token before running the base query
  const now = Date.now()
  const expiration = selectExpiration(api.getState() as TAppState)
  const refreshTime = (expiration - TOKEN_REFRESH_WINDOW.earliest) * 1000;

  if (now >= refreshTime) {
    // refresh token
    const release = await mutex.acquire();

    const refreshTokenFn = selectRefreshTokenFn(api.getState() as TAppState);
    try {
      await refreshTokenFn();
      release();
    } catch (error) {
      const signOut = selectSignOut(api.getState() as TAppState)
      signOut?.();
      release();
      return { error: { status: 401, data: null } };
    }
  }

  // run base query
  const apiUrl = selectApiUrl(api.getState() as TAppState)
  const baseQuery = getBaseQuery(apiUrl)
  return baseQuery(args, api, extraOptions)
}