import { useMemo } from "react";
import { filterByExactValue, filterByMaturity, useFilters } from "@/app/components/filters/hooks/useFilters";
import { AlertTableItem } from "../alertsTable";
import { useSelector } from "react-redux";
import { selectAlerts } from "@/store/slices/alert.slice";

export const useFilteredAlerts = (alerts: AlertTableItem[]) => {
  const allAlerts = useSelector(selectAlerts);
  const allBonds = useMemo(() => allAlerts.map(a => a.bond), [allAlerts]); 

  const filters = useFilters(allBonds);
  const filteredItems = useMemo(() => {
    return alerts.filter(a => {
      if (!filterByExactValue(filters.values.ticker, a.bond.ticker)) {
        return false;
      }

      if (!filterByExactValue(filters.values.coupon, a.bond.coupon)) {
        return false;
      }

      if (!filterByExactValue(filters.values.series, a.bond.series || '-')) {
        return false;
      }

      if (!filterByExactValue(filters.values.rating, a.bond.rating || '-')) {
        return false;
      }

      if (!filterByExactValue(filters.values.series, a.bond.series || '-')) {
        return false;
      }

      if (!filterByMaturity(filters.values.tenor, a.bond.maturity)) {
        return false;
      }

      return true;
    });
  }, [filters.values, alerts])

  return filteredItems;
}