import { useEffect, useMemo } from "react";
import { useDataContext } from "../../../data/dataProvider";
import { useAlertObject } from "../../hooks/useAlertObject";
import { AlertTableItem } from "../../alertsTable";
import { isAlertTableItem } from "../../alert.utils";
import { useGetAlertInference } from "../../hooks/useGetAlertInference";
import { useAlertInferenceData } from "../../hooks/useAlertInferenceData";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../store/slices/alert.slice";

const EMPTY_ARRAY: AlertTableItem[] = [];

// calculates alerts table data and alerts inference data and stores it in redux store for global access
export function useCalculateAlertsGlobalState() {
  const dispatch = useDispatch();
  const { alertObjectDetails } = useAlertObject();
  const { getBond } = useDataContext();

  const alerts = useMemo(() => {
    if (!alertObjectDetails?.value?.alerts || !getBond) {
      return EMPTY_ARRAY;
    }

    const alertItems = alertObjectDetails.value.alerts.map(alert => {
      const bond = getBond(alert.figi);
      if (!bond) {
        return null;
      }

      const alertTableItem: AlertTableItem = {
        alert,
        bond,
        targetInvalid: alert.targetValue === undefined || alert.targetValue === null,
      };

      return alertTableItem;
    }).filter(isAlertTableItem);

    
    return alertItems;
  }, [alertObjectDetails, getBond])

  useEffect(() => {
    // store alerts in redux for global access
    dispatch(alertActions.setAlerts(alerts));
  }, [alerts, dispatch])


  // configure inference requests
  const getInference = useGetAlertInference(alerts);
  const data = useAlertInferenceData(alerts, getInference);

  useEffect(() => {
    // store inferences result in global state so we can use it in multiple places
    dispatch(alertActions.setInferenceData(data));
  }, [data, dispatch])

}