/**
 * Type representing allowed values.
 * Includes string, number, boolean, null, symbol, bigint, array, object, and function types.
 */
export type NonUndefinedValues =
  | string
  | number
  | boolean
  | null
  | symbol
  | bigint
  | Array<unknown>
  | Record<string, unknown>
  | ((...args: unknown[]) => unknown);

/**
 * Checks if a value is not undefined.
 * If the value is not undefined, narrows the type to AllowedValues.
 *
 * @param {unknown} value - The value to check.
 * @returns {boolean} - Returns true if the value is not undefined, false otherwise.
 */
const notUndefined = (value: unknown): value is NonUndefinedValues =>
  value !== undefined;

export default notUndefined;
