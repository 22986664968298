import Table, { Column } from "@/app/components/table/table"
import { PriceType } from "@/types/types"
import { ProbabilityItem, getProbabilityArray } from "@/utils/inference/percentile.utils"
import { formatPrice } from "@/utils/number.utils"
import { ReactNode, useCallback, useMemo } from "react"
import { NumericFormatProps, numericFormatter } from "react-number-format"
import { Side } from "../data/api"
import { PROBABILITY_FORMAT } from "@/constants"


const tdCss = () => 'px-[0.3125rem] first:rounded-l-[0.625rem] last:rounded-r-[0.625rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]';

const header = (content: ReactNode) => (
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full px-[0.3125rem] w-full">
    <div className={'flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-[#C9CADE] text-start font-bold'}>
      {content}
    </div>
  </div>
)

const cell = (content: ReactNode) => (
  <div className={'flex flex-col h-full justify-center px-[0.625rem] py-[1rem] text-[0.875rem]'}>
    {content}
  </div>
)

export const ProbabilityTable = ({ 
  quantiles,
  side,
  priceType,
}: {
  quantiles: number[],
  side: Side,
  priceType: PriceType,
}) => {
  const probabilities = useMemo(() => getProbabilityArray(quantiles, side, priceType), [quantiles, side, priceType]);

  const columns: Column<ProbabilityItem>[] = useMemo(() => {
    const columns: Column<ProbabilityItem>[] = [
      {
        id: 'price',
        Cell: (d) => {
          const config: NumericFormatProps = priceType === PriceType.GSpread ? { decimalScale: 1} : {};
          return cell(
            formatPrice(d.percentile, priceType, config)
          )
        },
        Header: () => header('Price'),
        tdCss
      },
      {
        id: 'probability',
        Cell: (d) => cell(numericFormatter(`${d.probability * 100}`, PROBABILITY_FORMAT)),
        Header: () => header('Probability'),
        tdCss
      },
    ]

    return columns
  }, [priceType]);


  const trCss = useCallback(() => 'rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]', []);

  return (
    <Table
      columns={columns}
      generateItemKey={b => `${b.probability}`}
      items={probabilities}
      tableCss='w-full'
      tableName='probability-calc-table'
      theadCss='bg-[#333557] sticky top-0 z-[2]'
      trCss={trCss}
      scrollerCss="!max-h-[60vh]"
      defaultPageSize={25}
      noDataMessage="Loading..."
      simplePagination
    />
  )
}
