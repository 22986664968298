import { TextareaField } from "@/app/components/fields/TextareaField/TextareaField";
import { Note } from "@/types/types"
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { FaCheck, FaPencil, FaTrash, FaX } from "react-icons/fa6";

const dateFormatter = new Intl.DateTimeFormat('default', { timeStyle: 'medium' });

export const NoteItem = ({
  note,
  onCancel,
  onSave,
  onDelete,
}: {
  note: Note
  onCancel?: () => void
  onSave: (text: string, note: Note) => void
  onDelete?: (note: Note) => void
}) => {
  const { createdAt, text } = note;
  const isNewNote = !note.id;
  const [isEditing, setIsEditing] = useState(isNewNote);
  const [localValue, setLocalValue] = useState(text);

  useEffect(() => {
    setLocalValue(text)
  }, [text])

  const buttonCss = clsx(
    'text-inherit w-6 h-6 flex items-center justify-center rounded-full text-[0.75rem]',
    'group-hover/noteitem:opacity-100 group-hover/noteitem:scale-100 transition-transform duration-200',
    'bg-[#333557] hover:contrast-125'
  )

  function save() {
    setIsEditing(false);
    onSave(localValue, note)
  }

  const formattedDate = useMemo(() => {
    return dateFormatter.format(new Date(createdAt))
  }, [createdAt])

  return (
    <div className="flex flex-col bg-[#5D5F9D] rounded-[0.625rem] p-2.5 text-[0.875rem] leading-4 relative group/noteitem  ">
        
      {/* Date */}
      <div className="mb-[0.3125rem] text-[#B7B7BA]">
        {formattedDate}
      </div>

      {/* Actions */}
      <div className="flex gap-1 absolute right-1 top-1">
        {!isEditing && (
          <>
            <button type="button" className={clsx(buttonCss, 'opacity-0 scale-50')} onClick={() => setIsEditing(true)}>
              <FaPencil />
            </button>
            <button type="button" className={clsx(buttonCss, 'opacity-0 scale-50')} onClick={() => onDelete?.(note)}>
              <FaTrash />
            </button>
          </>
        )}

        {isEditing && (
          <>
            <button type="button" className={clsx(buttonCss, 'opacity-100 scale-100')} onClick={save}>
              <FaCheck />
            </button>
            <button 
              type="button" 
              className={clsx(buttonCss, 'opacity-100 scale-100')} 
              onClick={() => {
                setIsEditing(false)
                onCancel?.()
              }}
            >
              <FaX />
            </button>
          </>
        )}
      </div>

      {/* Text */}
      <TextareaField 
        className={clsx(
          'text-[#F1F1F1] placeholder:text-[#C9CADE] p-0 leading-4 text-[0.875rem] min-h-[1rem] resize-none border-none focus:border-transparent shadow-transparent focus:shadow-transparent ring-transparent focus:righ-transparent',
          {
            'bg-transparent': !isEditing,
            'p-2 bg-[#7073c4] rounded-md': isEditing,
          }
        )}
        style={{ boxShadow: 'none' }}
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        additionalHeight={0}
        readOnly={!isEditing}
        resizeTrigger={isEditing}
        autoFocus={isEditing}
        placeholder={isNewNote ? 'Add a note' : ''}
      />
    </div>
  )
}
