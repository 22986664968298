import { useMemo } from 'react'
import { useTimer, TimerResult } from 'react-timer-hook'

type ChildrenData = TimerResult & {
  formatted: {
    minutes: string
    hours: string
    seconds: string
  }
}

export type TimerProps = {
  expiryTimestamp: Date | number
  children: (data: ChildrenData) => JSX.Element
  onExpire?: () => void
}

const Timer = ({ children, expiryTimestamp, onExpire }: TimerProps) => {
  const endTime = useMemo(() => typeof expiryTimestamp === 'number' ? new Date(expiryTimestamp) : expiryTimestamp, [expiryTimestamp]);
  const timerData = useTimer({ expiryTimestamp: endTime, onExpire })

  return children({
    ...timerData,
    formatted: {
      seconds: `${timerData.seconds}`.padStart(2, '0'),
      minutes: `${timerData.minutes}`.padStart(2, '0'),
      hours: `${timerData.hours}`.padStart(2, '0'),
    },
  })
}

export default Timer