import { PriceType } from "@/types/types";

const spectrum: { [key: string]: string } = {
  '-100': 'bg-[rgb(176,41,30)]',
  '-99': 'bg-[rgb(174,41,30)]',
  '-98': 'bg-[rgb(172,41,31)]',
  '-97': 'bg-[rgb(171,41,31)]',
  '-96': 'bg-[rgb(169,41,31)]',
  '-95': 'bg-[rgb(167,41,31)]',
  '-94': 'bg-[rgb(165,41,32)]',
  '-93': 'bg-[rgb(163,40,32)]',
  '-92': 'bg-[rgb(162,40,32)]',
  '-91': 'bg-[rgb(160,40,32)]',
  '-90': 'bg-[rgb(158,40,33)]',
  '-89': 'bg-[rgb(157,40,33)]',
  '-88': 'bg-[rgb(155,40,33)]',
  '-87': 'bg-[rgb(153,40,34)]',
  '-86': 'bg-[rgb(151,40,34)]',
  '-85': 'bg-[rgb(150,40,34)]',
  '-84': 'bg-[rgb(148,40,34)]',
  '-83': 'bg-[rgb(147,40,35)]',
  '-82': 'bg-[rgb(145,40,35)]',
  '-81': 'bg-[rgb(143,39,35)]',
  '-80': 'bg-[rgb(142,39,35)]',
  '-79': 'bg-[rgb(140,39,36)]',
  '-78': 'bg-[rgb(139,39,36)]',
  '-77': 'bg-[rgb(137,39,36)]',
  '-76': 'bg-[rgb(136,39,36)]',
  '-75': 'bg-[rgb(135,39,37)]',
  '-74': 'bg-[rgb(133,39,37)]',
  '-73': 'bg-[rgb(132,39,37)]',
  '-72': 'bg-[rgb(131,39,37)]',
  '-71': 'bg-[rgb(129,39,37)]',
  '-70': 'bg-[rgb(128,39,38)]',
  '-69': 'bg-[rgb(127,39,38)]',
  '-68': 'bg-[rgb(126,38,38)]',
  '-67': 'bg-[rgb(125,38,38)]',
  '-66': 'bg-[rgb(124,38,38)]',
  '-65': 'bg-[rgb(122,38,39)]',
  '-64': 'bg-[rgb(121,38,39)]',
  '-63': 'bg-[rgb(120,38,39)]',
  '-62': 'bg-[rgb(119,38,39)]',
  '-61': 'bg-[rgb(118,38,39)]',
  '-60': 'bg-[rgb(117,38,39)]',
  '-59': 'bg-[rgb(116,38,40)]',
  '-58': 'bg-[rgb(114,38,40)]',
  '-57': 'bg-[rgb(113,38,40)]',
  '-56': 'bg-[rgb(112,37,40)]',
  '-55': 'bg-[rgb(111,37,40)]',
  '-54': 'bg-[rgb(110,37,41)]',
  '-53': 'bg-[rgb(108,37,41)]',
  '-52': 'bg-[rgb(107,37,41)]',
  '-51': 'bg-[rgb(106,37,41)]',
  '-50': 'bg-[rgb(105,37,41)]',
  '-49': 'bg-[rgb(103,37,42)]',
  '-48': 'bg-[rgb(102,37,42)]',
  '-47': 'bg-[rgb(101,37,42)]',
  '-46': 'bg-[rgb(99,37,42)]',
  '-45': 'bg-[rgb(98,37,42)]',
  '-44': 'bg-[rgb(96,37,43)]',
  '-43': 'bg-[rgb(95,36,43)]',
  '-42': 'bg-[rgb(93,36,43)]',
  '-41': 'bg-[rgb(92,36,43)]',
  '-40': 'bg-[rgb(90,36,43)]',
  '-39': 'bg-[rgb(89,36,44)]',
  '-38': 'bg-[rgb(87,36,44)]',
  '-37': 'bg-[rgb(85,36,44)]',
  '-36': 'bg-[rgb(84,36,44)]',
  '-35': 'bg-[rgb(82,36,45)]',
  '-34': 'bg-[rgb(81,36,45)]',
  '-33': 'bg-[rgb(79,36,45)]',
  '-32': 'bg-[rgb(78,35,45)]',
  '-31': 'bg-[rgb(76,35,45)]',
  '-30': 'bg-[rgb(74,35,46)]',
  '-29': 'bg-[rgb(73,35,46)]',
  '-28': 'bg-[rgb(71,35,46)]',
  '-27': 'bg-[rgb(70,35,46)]',
  '-26': 'bg-[rgb(68,35,47)]',
  '-25': 'bg-[rgb(67,35,47)]',
  '-24': 'bg-[rgb(65,35,47)]',
  '-23': 'bg-[rgb(63,35,47)]',
  '-22': 'bg-[rgb(62,35,47)]',
  '-21': 'bg-[rgb(60,34,48)]',
  '-20': 'bg-[rgb(59,34,48)]',
  '-19': 'bg-[rgb(57,34,48)]',
  '-18': 'bg-[rgb(56,34,48)]',
  '-17': 'bg-[rgb(55,34,49)]',
  '-16': 'bg-[rgb(53,34,49)]',
  '-15': 'bg-[rgb(52,34,49)]',
  '-14': 'bg-[rgb(50,34,49)]',
  '-13': 'bg-[rgb(49,34,49)]',
  '-12': 'bg-[rgb(47,33,50)]',
  '-11': 'bg-[rgb(46,33,50)]',
  '-10': 'bg-[rgb(45,33,50)]',
  '-9': 'bg-[rgb(43,33,50)]',
  '-8': 'bg-[rgb(42,33,50)]',
  '-7': 'bg-[rgb(41,33,51)]',
  '-6': 'bg-[rgb(39,33,51)]',
  '-5': 'bg-[rgb(38,33,51)]',
  '-4': 'bg-[rgb(36,32,51)]',
  '-3': 'bg-[rgb(35,32,51)]',
  '-2': 'bg-[rgb(34,32,52)]',
  '-1': 'bg-[rgb(32,32,52)]',
  '0': 'bg-[rgb(31,32,52)]',
  '1': 'bg-[rgb(31,32,52)]',
  '2': 'bg-[rgb(31,33,52)]',
  '3': 'bg-[rgb(32,33,52)]',
  '4': 'bg-[rgb(32,34,52)]',
  '5': 'bg-[rgb(32,34,52)]',
  '6': 'bg-[rgb(33,34,52)]',
  '7': 'bg-[rgb(33,35,53)]',
  '8': 'bg-[rgb(33,35,53)]',
  '9': 'bg-[rgb(33,36,53)]',
  '10': 'bg-[rgb(34,36,53)]',
  '11': 'bg-[rgb(34,37,53)]',
  '12': 'bg-[rgb(34,38,53)]',
  '13': 'bg-[rgb(35,38,53)]',
  '14': 'bg-[rgb(35,39,53)]',
  '15': 'bg-[rgb(36,39,53)]',
  '16': 'bg-[rgb(36,40,53)]',
  '17': 'bg-[rgb(37,41,54)]',
  '18': 'bg-[rgb(37,42,54)]',
  '19': 'bg-[rgb(38,43,54)]',
  '20': 'bg-[rgb(38,43,54)]',
  '21': 'bg-[rgb(39,44,54)]',
  '22': 'bg-[rgb(39,45,54)]',
  '23': 'bg-[rgb(40,47,54)]',
  '24': 'bg-[rgb(41,48,55)]',
  '25': 'bg-[rgb(42,49,55)]',
  '26': 'bg-[rgb(42,50,55)]',
  '27': 'bg-[rgb(43,51,55)]',
  '28': 'bg-[rgb(44,53,55)]',
  '29': 'bg-[rgb(45,54,55)]',
  '30': 'bg-[rgb(46,56,56)]',
  '31': 'bg-[rgb(47,57,56)]',
  '32': 'bg-[rgb(48,59,56)]',
  '33': 'bg-[rgb(49,60,56)]',
  '34': 'bg-[rgb(50,62,56)]',
  '35': 'bg-[rgb(51,64,57)]',
  '36': 'bg-[rgb(53,65,57)]',
  '37': 'bg-[rgb(54,67,57)]',
  '38': 'bg-[rgb(55,69,57)]',
  '39': 'bg-[rgb(56,70,57)]',
  '40': 'bg-[rgb(57,72,58)]',
  '41': 'bg-[rgb(58,74,58)]',
  '42': 'bg-[rgb(59,75,58)]',
  '43': 'bg-[rgb(60,77,58)]',
  '44': 'bg-[rgb(62,79,58)]',
  '45': 'bg-[rgb(63,81,59)]',
  '46': 'bg-[rgb(64,82,59)]',
  '47': 'bg-[rgb(65,84,59)]',
  '48': 'bg-[rgb(66,85,59)]',
  '49': 'bg-[rgb(67,87,59)]',
  '50': 'bg-[rgb(68,89,60)]',
  '51': 'bg-[rgb(69,90,60)]',
  '52': 'bg-[rgb(70,91,60)]',
  '53': 'bg-[rgb(71,93,60)]',
  '54': 'bg-[rgb(72,94,60)]',
  '55': 'bg-[rgb(73,96,61)]',
  '56': 'bg-[rgb(74,97,61)]',
  '57': 'bg-[rgb(74,98,61)]',
  '58': 'bg-[rgb(75,99,61)]',
  '59': 'bg-[rgb(76,101,61)]',
  '60': 'bg-[rgb(77,102,62)]',
  '61': 'bg-[rgb(78,103,62)]',
  '62': 'bg-[rgb(79,105,62)]',
  '63': 'bg-[rgb(80,106,62)]',
  '64': 'bg-[rgb(80,107,62)]',
  '65': 'bg-[rgb(81,108,62)]',
  '66': 'bg-[rgb(82,110,63)]',
  '67': 'bg-[rgb(83,111,63)]',
  '68': 'bg-[rgb(84,112,63)]',
  '69': 'bg-[rgb(85,114,63)]',
  '70': 'bg-[rgb(86,115,63)]',
  '71': 'bg-[rgb(87,117,64)]',
  '72': 'bg-[rgb(88,118,64)]',
  '73': 'bg-[rgb(89,120,64)]',
  '74': 'bg-[rgb(90,121,64)]',
  '75': 'bg-[rgb(91,123,65)]',
  '76': 'bg-[rgb(92,125,65)]',
  '77': 'bg-[rgb(93,127,65)]',
  '78': 'bg-[rgb(95,129,65)]',
  '79': 'bg-[rgb(96,130,65)]',
  '80': 'bg-[rgb(97,132,66)]',
  '81': 'bg-[rgb(98,134,66)]',
  '82': 'bg-[rgb(100,136,66)]',
  '83': 'bg-[rgb(101,138,66)]',
  '84': 'bg-[rgb(102,141,67)]',
  '85': 'bg-[rgb(104,143,67)]',
  '86': 'bg-[rgb(105,145,67)]',
  '87': 'bg-[rgb(107,147,67)]',
  '88': 'bg-[rgb(108,149,68)]',
  '89': 'bg-[rgb(110,152,68)]',
  '90': 'bg-[rgb(111,154,68)]',
  '91': 'bg-[rgb(113,156,69)]',
  '92': 'bg-[rgb(114,159,69)]',
  '93': 'bg-[rgb(116,161,69)]',
  '94': 'bg-[rgb(117,163,69)]',
  '95': 'bg-[rgb(119,166,70)]',
  '96': 'bg-[rgb(121,168,70)]',
  '97': 'bg-[rgb(122,171,70)]',
  '98': 'bg-[rgb(124,173,70)]',
  '99': 'bg-[rgb(125,176,71)]',
  '100': 'bg-[rgb(127,178,71)]',
};

const lightSpectrum: { [key: string]: string } = {
  '-100': 'bg-[rgb(176,41,30)]',
  '-99': 'bg-[rgb(175,41,31)]',
  '-98': 'bg-[rgb(174,42,32)]',
  '-97': 'bg-[rgb(173,42,33)]',
  '-96': 'bg-[rgb(172,42,34)]',
  '-95': 'bg-[rgb(171,43,35)]',
  '-94': 'bg-[rgb(170,43,36)]',
  '-93': 'bg-[rgb(169,44,37)]',
  '-92': 'bg-[rgb(168,44,38)]',
  '-91': 'bg-[rgb(167,44,39)]',
  '-90': 'bg-[rgb(166,45,40)]',
  '-89': 'bg-[rgb(165,45,41)]',
  '-88': 'bg-[rgb(164,45,41)]',
  '-87': 'bg-[rgb(163,46,42)]',
  '-86': 'bg-[rgb(161,46,43)]',
  '-85': 'bg-[rgb(160,46,44)]',
  '-84': 'bg-[rgb(159,47,45)]',
  '-83': 'bg-[rgb(158,47,46)]',
  '-82': 'bg-[rgb(157,47,47)]',
  '-81': 'bg-[rgb(156,48,48)]',
  '-80': 'bg-[rgb(155,48,49)]',
  '-79': 'bg-[rgb(154,48,50)]',
  '-78': 'bg-[rgb(153,49,51)]',
  '-77': 'bg-[rgb(152,49,52)]',
  '-76': 'bg-[rgb(151,49,53)]',
  '-75': 'bg-[rgb(150,50,54)]',
  '-74': 'bg-[rgb(149,50,54)]',
  '-73': 'bg-[rgb(148,51,55)]',
  '-72': 'bg-[rgb(147,51,56)]',
  '-71': 'bg-[rgb(146,51,57)]',
  '-70': 'bg-[rgb(145,52,58)]',
  '-69': 'bg-[rgb(144,52,59)]',
  '-68': 'bg-[rgb(143,52,60)]',
  '-67': 'bg-[rgb(142,53,61)]',
  '-66': 'bg-[rgb(141,53,61)]',
  '-65': 'bg-[rgb(140,53,62)]',
  '-64': 'bg-[rgb(140,53,63)]',
  '-63': 'bg-[rgb(139,54,64)]',
  '-62': 'bg-[rgb(138,54,65)]',
  '-61': 'bg-[rgb(137,54,66)]',
  '-60': 'bg-[rgb(136,55,67)]',
  '-59': 'bg-[rgb(135,55,67)]',
  '-58': 'bg-[rgb(134,55,68)]',
  '-57': 'bg-[rgb(133,56,69)]',
  '-56': 'bg-[rgb(132,56,70)]',
  '-55': 'bg-[rgb(131,56,71)]',
  '-54': 'bg-[rgb(130,57,72)]',
  '-53': 'bg-[rgb(129,57,72)]',
  '-52': 'bg-[rgb(128,57,73)]',
  '-51': 'bg-[rgb(127,58,74)]',
  '-50': 'bg-[rgb(126,58,75)]',
  '-49': 'bg-[rgb(125,58,76)]',
  '-48': 'bg-[rgb(124,58,76)]',
  '-47': 'bg-[rgb(124,59,77)]',
  '-46': 'bg-[rgb(123,59,78)]',
  '-45': 'bg-[rgb(122,59,79)]',
  '-44': 'bg-[rgb(121,60,80)]',
  '-43': 'bg-[rgb(120,60,81)]',
  '-42': 'bg-[rgb(119,60,81)]',
  '-41': 'bg-[rgb(118,60,82)]',
  '-40': 'bg-[rgb(117,61,83)]',
  '-39': 'bg-[rgb(116,61,84)]',
  '-38': 'bg-[rgb(115,61,85)]',
  '-37': 'bg-[rgb(114,62,86)]',
  '-36': 'bg-[rgb(113,62,86)]',
  '-35': 'bg-[rgb(112,62,87)]',
  '-34': 'bg-[rgb(111,63,88)]',
  '-33': 'bg-[rgb(111,63,89)]',
  '-32': 'bg-[rgb(110,63,90)]',
  '-31': 'bg-[rgb(109,63,91)]',
  '-30': 'bg-[rgb(108,64,92)]',
  '-29': 'bg-[rgb(107,64,92)]',
  '-28': 'bg-[rgb(106,64,93)]',
  '-27': 'bg-[rgb(104,65,94)]',
  '-26': 'bg-[rgb(103,65,95)]',
  '-25': 'bg-[rgb(102,65,96)]',
  '-24': 'bg-[rgb(101,66,97)]',
  '-23': 'bg-[rgb(100,66,98)]',
  '-22': 'bg-[rgb(99,66,99)]',
  '-21': 'bg-[rgb(98,67,100)]',
  '-20': 'bg-[rgb(97,67,101)]',
  '-19': 'bg-[rgb(96,67,102)]',
  '-18': 'bg-[rgb(94,68,103)]',
  '-17': 'bg-[rgb(93,68,104)]',
  '-16': 'bg-[rgb(92,68,105)]',
  '-15': 'bg-[rgb(91,69,106)]',
  '-14': 'bg-[rgb(90,69,107)]',
  '-13': 'bg-[rgb(88,69,108)]',
  '-12': 'bg-[rgb(87,70,109)]',
  '-11': 'bg-[rgb(86,70,110)]',
  '-10': 'bg-[rgb(85,70,111)]',
  '-9': 'bg-[rgb(83,71,112)]',
  '-8': 'bg-[rgb(82,71,113)]',
  '-7': 'bg-[rgb(81,71,114)]',
  '-6': 'bg-[rgb(80,72,116)]',
  '-5': 'bg-[rgb(78,72,117)]',
  '-4': 'bg-[rgb(77,73,118)]',
  '-3': 'bg-[rgb(76,73,119)]',
  '-2': 'bg-[rgb(75,73,120)]',
  '-1': 'bg-[rgb(73,74,121)]',
  '0': 'bg-[rgb(72,74,122)]',
  '1': 'bg-[rgb(73,75,121)]',
  '2': 'bg-[rgb(73,76,121)]',
  '3': 'bg-[rgb(74,78,120)]',
  '4': 'bg-[rgb(75,79,120)]',
  '5': 'bg-[rgb(75,80,119)]',
  '6': 'bg-[rgb(76,81,119)]',
  '7': 'bg-[rgb(77,83,118)]',
  '8': 'bg-[rgb(77,84,118)]',
  '9': 'bg-[rgb(78,85,117)]',
  '10': 'bg-[rgb(79,86,116)]',
  '11': 'bg-[rgb(79,88,116)]',
  '12': 'bg-[rgb(80,89,115)]',
  '13': 'bg-[rgb(81,90,115)]',
  '14': 'bg-[rgb(81,91,114)]',
  '15': 'bg-[rgb(82,92,114)]',
  '16': 'bg-[rgb(83,93,113)]',
  '17': 'bg-[rgb(83,95,113)]',
  '18': 'bg-[rgb(84,96,112)]',
  '19': 'bg-[rgb(85,97,112)]',
  '20': 'bg-[rgb(85,98,111)]',
  '21': 'bg-[rgb(86,99,111)]',
  '22': 'bg-[rgb(86,100,110)]',
  '23': 'bg-[rgb(87,101,110)]',
  '24': 'bg-[rgb(88,103,109)]',
  '25': 'bg-[rgb(88,104,109)]',
  '26': 'bg-[rgb(89,105,108)]',
  '27': 'bg-[rgb(89,106,108)]',
  '28': 'bg-[rgb(90,107,107)]',
  '29': 'bg-[rgb(90,108,107)]',
  '30': 'bg-[rgb(91,109,106)]',
  '31': 'bg-[rgb(92,110,106)]',
  '32': 'bg-[rgb(92,111,105)]',
  '33': 'bg-[rgb(93,112,105)]',
  '34': 'bg-[rgb(93,113,104)]',
  '35': 'bg-[rgb(94,114,104)]',
  '36': 'bg-[rgb(94,115,103)]',
  '37': 'bg-[rgb(95,116,103)]',
  '38': 'bg-[rgb(95,117,102)]',
  '39': 'bg-[rgb(96,118,102)]',
  '40': 'bg-[rgb(96,119,101)]',
  '41': 'bg-[rgb(97,120,101)]',
  '42': 'bg-[rgb(97,121,100)]',
  '43': 'bg-[rgb(98,122,100)]',
  '44': 'bg-[rgb(98,123,99)]',
  '45': 'bg-[rgb(99,124,99)]',
  '46': 'bg-[rgb(99,125,98)]',
  '47': 'bg-[rgb(100,126,98)]',
  '48': 'bg-[rgb(100,127,97)]',
  '49': 'bg-[rgb(101,128,97)]',
  '50': 'bg-[rgb(101,129,97)]',
  '51': 'bg-[rgb(102,130,96)]',
  '52': 'bg-[rgb(102,131,95)]',
  '53': 'bg-[rgb(103,132,95)]',
  '54': 'bg-[rgb(103,133,94)]',
  '55': 'bg-[rgb(104,134,94)]',
  '56': 'bg-[rgb(105,135,93)]',
  '57': 'bg-[rgb(105,136,93)]',
  '58': 'bg-[rgb(106,137,92)]',
  '59': 'bg-[rgb(106,138,92)]',
  '60': 'bg-[rgb(107,139,91)]',
  '61': 'bg-[rgb(107,140,91)]',
  '62': 'bg-[rgb(108,141,90)]',
  '63': 'bg-[rgb(108,142,90)]',
  '64': 'bg-[rgb(109,143,89)]',
  '65': 'bg-[rgb(109,144,89)]',
  '66': 'bg-[rgb(110,145,88)]',
  '67': 'bg-[rgb(110,146,88)]',
  '68': 'bg-[rgb(111,147,87)]',
  '69': 'bg-[rgb(111,148,86)]',
  '70': 'bg-[rgb(112,149,86)]',
  '71': 'bg-[rgb(113,150,85)]',
  '72': 'bg-[rgb(113,151,85)]',
  '73': 'bg-[rgb(114,152,84)]',
  '74': 'bg-[rgb(114,153,84)]',
  '75': 'bg-[rgb(115,154,83)]',
  '76': 'bg-[rgb(115,155,83)]',
  '77': 'bg-[rgb(116,156,82)]',
  '78': 'bg-[rgb(116,157,82)]',
  '79': 'bg-[rgb(117,158,81)]',
  '80': 'bg-[rgb(117,159,81)]',
  '81': 'bg-[rgb(118,160,80)]',
  '82': 'bg-[rgb(118,161,80)]',
  '83': 'bg-[rgb(119,162,79)]',
  '84': 'bg-[rgb(119,163,79)]',
  '85': 'bg-[rgb(120,164,78)]',
  '86': 'bg-[rgb(120,165,78)]',
  '87': 'bg-[rgb(121,166,77)]',
  '88': 'bg-[rgb(121,167,77)]',
  '89': 'bg-[rgb(122,168,76)]',
  '90': 'bg-[rgb(122,169,76)]',
  '91': 'bg-[rgb(123,170,75)]',
  '92': 'bg-[rgb(123,171,75)]',
  '93': 'bg-[rgb(124,172,74)]',
  '94': 'bg-[rgb(124,172,74)]',
  '95': 'bg-[rgb(125,173,73)]',
  '96': 'bg-[rgb(125,174,73)]',
  '97': 'bg-[rgb(126,175,72)]',
  '98': 'bg-[rgb(126,176,72)]',
  '99': 'bg-[rgb(127,177,71)]',
  '100': 'bg-[rgb(127,178,71)]'
};

export const darkTogreenSpectrum: { [key: string]: string } = {
  '0': 'bg-[rgb(72,74,122)]',
  '1': 'bg-[rgb(73,75,121)]',
  '2': 'bg-[rgb(73,76,121)]',
  '3': 'bg-[rgb(74,78,120)]',
  '4': 'bg-[rgb(75,79,120)]',
  '5': 'bg-[rgb(75,80,119)]',
  '6': 'bg-[rgb(76,81,119)]',
  '7': 'bg-[rgb(77,83,118)]',
  '8': 'bg-[rgb(77,84,118)]',
  '9': 'bg-[rgb(78,85,117)]',
  '10': 'bg-[rgb(79,86,116)]',
  '11': 'bg-[rgb(79,88,116)]',
  '12': 'bg-[rgb(80,89,115)]',
  '13': 'bg-[rgb(81,90,115)]',
  '14': 'bg-[rgb(81,91,114)]',
  '15': 'bg-[rgb(82,92,114)]',
  '16': 'bg-[rgb(83,93,113)]',
  '17': 'bg-[rgb(83,95,113)]',
  '18': 'bg-[rgb(84,96,112)]',
  '19': 'bg-[rgb(85,97,112)]',
  '20': 'bg-[rgb(85,98,111)]',
  '21': 'bg-[rgb(86,99,111)]',
  '22': 'bg-[rgb(86,100,110)]',
  '23': 'bg-[rgb(87,101,110)]',
  '24': 'bg-[rgb(88,103,109)]',
  '25': 'bg-[rgb(88,104,109)]',
  '26': 'bg-[rgb(89,105,108)]',
  '27': 'bg-[rgb(89,106,108)]',
  '28': 'bg-[rgb(90,107,107)]',
  '29': 'bg-[rgb(90,108,107)]',
  '30': 'bg-[rgb(91,109,106)]',
  '31': 'bg-[rgb(92,110,106)]',
  '32': 'bg-[rgb(92,111,105)]',
  '33': 'bg-[rgb(93,112,105)]',
  '34': 'bg-[rgb(93,113,104)]',
  '35': 'bg-[rgb(94,114,104)]',
  '36': 'bg-[rgb(94,115,103)]',
  '37': 'bg-[rgb(95,116,103)]',
  '38': 'bg-[rgb(95,117,102)]',
  '39': 'bg-[rgb(96,118,102)]',
  '40': 'bg-[rgb(96,119,101)]',
  '41': 'bg-[rgb(97,120,101)]',
  '42': 'bg-[rgb(97,121,100)]',
  '43': 'bg-[rgb(98,122,100)]',
  '44': 'bg-[rgb(98,123,99)]',
  '45': 'bg-[rgb(99,124,99)]',
  '46': 'bg-[rgb(99,125,98)]',
  '47': 'bg-[rgb(100,126,98)]',
  '48': 'bg-[rgb(100,127,97)]',
  '49': 'bg-[rgb(101,128,97)]',
  '50': 'bg-[rgb(101,129,97)]',
  '51': 'bg-[rgb(102,130,96)]',
  '52': 'bg-[rgb(102,131,95)]',
  '53': 'bg-[rgb(103,132,95)]',
  '54': 'bg-[rgb(103,133,94)]',
  '55': 'bg-[rgb(104,134,94)]',
  '56': 'bg-[rgb(105,135,93)]',
  '57': 'bg-[rgb(105,136,93)]',
  '58': 'bg-[rgb(106,137,92)]',
  '59': 'bg-[rgb(106,138,92)]',
  '60': 'bg-[rgb(107,139,91)]',
  '61': 'bg-[rgb(107,140,91)]',
  '62': 'bg-[rgb(108,141,90)]',
  '63': 'bg-[rgb(108,142,90)]',
  '64': 'bg-[rgb(109,143,89)]',
  '65': 'bg-[rgb(109,144,89)]',
  '66': 'bg-[rgb(110,145,88)]',
  '67': 'bg-[rgb(110,146,88)]',
  '68': 'bg-[rgb(111,147,87)]',
  '69': 'bg-[rgb(111,148,86)]',
  '70': 'bg-[rgb(112,149,86)]',
  '71': 'bg-[rgb(113,150,85)]',
  '72': 'bg-[rgb(113,151,85)]',
  '73': 'bg-[rgb(114,152,84)]',
  '74': 'bg-[rgb(114,153,84)]',
  '75': 'bg-[rgb(115,154,83)]',
  '76': 'bg-[rgb(115,155,83)]',
  '77': 'bg-[rgb(116,156,82)]',
  '78': 'bg-[rgb(116,157,82)]',
  '79': 'bg-[rgb(117,158,81)]',
  '80': 'bg-[rgb(117,159,81)]',
  '81': 'bg-[rgb(118,160,80)]',
  '82': 'bg-[rgb(118,161,80)]',
  '83': 'bg-[rgb(119,162,79)]',
  '84': 'bg-[rgb(119,163,79)]',
  '85': 'bg-[rgb(120,164,78)]',
  '86': 'bg-[rgb(120,165,78)]',
  '87': 'bg-[rgb(121,166,77)]',
  '88': 'bg-[rgb(121,167,77)]',
  '89': 'bg-[rgb(122,168,76)]',
  '90': 'bg-[rgb(122,169,76)]',
  '91': 'bg-[rgb(123,170,75)]',
  '92': 'bg-[rgb(123,171,75)]',
  '93': 'bg-[rgb(124,172,74)]',
  '94': 'bg-[rgb(124,172,74)]',
  '95': 'bg-[rgb(125,173,73)]',
  '96': 'bg-[rgb(125,174,73)]',
  '97': 'bg-[rgb(126,175,72)]',
  '98': 'bg-[rgb(126,176,72)]',
  '99': 'bg-[rgb(127,177,71)]',
  '100': 'bg-[rgb(127,178,71)]',
};

const spectrumMap = {
  dark: spectrum,
  light: lightSpectrum,
  darkTogreen: darkTogreenSpectrum,
}

type SpectrumType = keyof typeof spectrumMap;

export const getPercentSpectrumBackground = (percent: number, type: SpectrumType = 'dark') => {
  const s = spectrumMap[type];
  return s[Math.round(100 * percent)];
}

export const getAsymptoticSpectrumBackground = (value: number | undefined, type: SpectrumType = 'dark', multiplier: number = 10) => {
  const percent = value
    ? value > 0
      ? 1 - 1 / (multiplier * value + 1) // 1 - 1/(10x+1)
      : 1 / (1 - multiplier * value) - 1 // 1/(1-10x) - 1
    : 0
  return getPercentSpectrumBackground(percent, type);
}

export const getPriceAsymptoticSpectrumBackground = (value: number | undefined,  type: SpectrumType = 'dark', priceType: `${PriceType}`) => {
  const multiplier = priceType === PriceType.GSpread ? 0.1 : 10;

  // revert the value for gspread and ytm
  if (priceType === 'gspread' || priceType === 'ytm') {
    value = typeof value === 'number' ? -1 * value : value;
  }

  return getAsymptoticSpectrumBackground(value, type, multiplier);
}

/*
// To adjust, make changes to the following and run in a JSFiddle, CodePen, etc.:

<!DOCTYPE html>
<script src="https://cdn.jsdelivr.net/npm/d3@7"></script>
<script>

const negative = d3.interpolateRgbBasis(['#B0291E', '#832725', '#6C2529', '#41232F', '#1F2034']);
const positive = d3.interpolateRgbBasis(['#1F2034', '#252A36', '#475D3C', '#577540', '#7FB247']);

// light spectrum
// const negative = d3.interpolateRgbBasis(['#B0291E', '#963236', '#7E3A4B', '#68415F', '#484A7A']);
// const positive = d3.interpolateRgbBasis(['#484A7A', '#59696C', '#658061', '#739B53', '#7FB247']);

const map = {};
for (let i = -100; i <= 0; i++) {
	map[i] = negative((i + 100) / 100);
}
for (let i = 0; i <= 100; i++) {
	map[i] = positive(i / 100);
}
let mapContents = '';
for (let i = -100; i <= 100; i++) {
	mapContents += `  '${i}': 'bg-[${map[i].replaceAll(" ", "")}]',\n`;
}
console.log(`const spectrum: { [key: string]: string } = {\n${mapContents}};`);

</script>
*/


/*
// Render this code inside of component to check how spectrum looks like

{[...Array(101)].map((_, i) => (
  <div key={i} className={`w-full h-[20px] ${getPercentSpectrumBackground(i/100, 'darkTogreen')}`} />
))}

*/