import { ReactNode } from 'react';
import { CheckIcon, PlayIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../..';
import ListBox, { ListboxProps, Value } from './listbox';
import clsx from 'clsx';

type StyledListboxProps<T extends readonly string[], Multiple extends boolean = false> = ListboxProps<T, Multiple> &  {
  buttonContent?: (props: {disabled: boolean, open: boolean, value: Value<T, Multiple>}) => ReactNode;
  buttonCss?: (props: {disabled: boolean, open: boolean, value: T[number]}) => string;
  optionContent?: (value: T[number], props: { active: boolean, disabled: boolean, selected: boolean }) => ReactNode;
  optionCss?: (props: { active: boolean, disabled: boolean, selected: boolean }) => string;
  optionsCss?: string;
};

const StyledListBox = <T extends readonly string[], Multiple extends boolean = false>({ 
  buttonContent, 
  buttonCss, 
  optionContent, 
  optionCss, 
  optionsCss, 
  selectValue, 
  value, 
  values,
  multiple,
  disabled,
}: StyledListboxProps<T, Multiple>) => {

  return (
    <ListBox
      button={props =>
        <div className={clsx("bg-[#343558] border-[0.0625rem] gap-[0.625rem] grid grid-cols-[1fr,auto] grid-rows-1 items-center justify-items-start p-[0.625rem] relative text-[#89AFEE] text-[0.875rem] text-left w-full", {
          "border-[#2E65A0] rounded-[0.625rem]": !props.open,
          "border-b-[#8183B3] border-l-[#2E65A0] border-r-[#2E65A0] border-t-[#2E65A0] rounded-t-[0.625rem]": props.open,
          'cursor-not-allowed opacity-50': disabled,
        })}>
          { buttonContent ? buttonContent(props) : <span className="text-[#FBFBFD] truncate w-full">
            {Array.isArray(value) ? value.join(', ') : value}
          </span> }
          <PlayIcon className={`h-[0.625rem] inline transform transition-transform${props.open ? ' rotate-90' : ''}`} aria-hidden="true" />
        </div>
      }
      buttonCss={props => classNames('w-full', buttonCss ? buttonCss(props) : '')}
      divCss='w-full'
      option={(value, props) =>
        <>
          <span className={`block truncate ${props.selected ? 'font-medium' : 'font-normal'}`}>
            {optionContent ? optionContent(value, props) : value}
          </span>
          {props.selected ? (
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </>
      }
      optionsCss={classNames('bg-[#5D5F9D] border-b-[0.0625rem] border-l-[0.0625rem] border-r-[0.0625rem] border-[#2E65A0] max-h-[10rem] overflow-auto rounded-b-[0.625rem] z-[3]', optionsCss || '')}
      optionCss={(_, props) => classNames('border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[2.5rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0]', optionCss ? optionCss(props) : '')}
      selectValue={selectValue}
      value={value}
      values={values}
      multiple={multiple}
      disabled={disabled}
    />
  );
}

export default StyledListBox;
