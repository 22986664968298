import { MouseEvent } from "react"
import { AlertTriggeredIcon } from "../../alert/alertTriggeredIcon"
import { selectTriggeredAlertsByCusip } from "../../../store/slices/alert.slice"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"

export const AlertCell = ({ cusip }: { cusip: string }) => {
  const triggeredAlerts = useSelector(selectTriggeredAlertsByCusip(cusip))

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }

  if (isEmpty(triggeredAlerts)) {
    return null;
  }
  
  return (
    <div className="cursor-pointer relative inline-flex w-fit" onClick={handleClick}> 
      <AlertTriggeredIcon cusip={cusip} />

      {/* just a placeholder to make clickable space around the alert icon without making table cell bigger */}
      {/* <div className="absolute -left-1 -top-1 -right-1 -bottom-1 opacity-60" /> */}
    </div>
  )
}