import { classNames } from "../..";
import { Bond, Side } from '../data/dataProvider';
import { RunBond } from "../data/runs";
import { getAsymptoticSpectrumBackground } from "../components/spectrum";
import { Col, DeleteButton, Row } from "../components/itemCard";
import { BondCardData } from "../components/data/bondCardData";
import { useUiMode } from "@hooks/useUiMode";
import { InferenceResult } from "@hooks/data/useSimpleInferenceData";
import { getColumnTitle } from "./run.utils";
import { RunColumn } from "./run.constants";
import { PriceType, UIMode } from "@/types/types";

export const RunCard = ({
  bond,
  inferenceResult,
  remove,
  selected,
}: {
  bond: RunBond & Bond,
  inferenceResult: InferenceResult<RunBond & Bond>;
  remove: (figi: string) => void,
  selected?: boolean
}) => {
  const gct = getColumnTitle('');
  const { isIgUiMode, isHyUiMode, uiMode } = useUiMode();
  const { data } = inferenceResult;
  const d = data[bond.figi];

  const getPercentileValue = (side: Side) => (b: Bond & RunBond) => {
    const value = side === Side.bid ? b.bidPercentile : b.offerPercentile;
    const priceType = uiMode === UIMode.InvestmentGrade ? PriceType.GSpread : PriceType.Price

    const v = typeof b.bidOfferSpread?.[uiMode] === 'number'
      ? data[b.figi][side][priceType].data?.maxProbability || 0
      : value

    return v;
  }

  // bid gspd
  const bidGspdStr = d[Side.bid].gspread.currentString;
  const bidGspdChangeStr = d[Side.bid].gspread.diffString;
  const bidGspdChange = d[Side.bid].gspread.diff;

  // offer gspd
  const offerGSpdStr = d[Side.offer].gspread.currentString;

  // bid yield to maturity
  const bidYTMStr = d[Side.bid].ytm.currentString;

  // offer yied to maturity
  const offerYTMStr = d[Side.offer].ytm.currentString;

  // bid px
  const bidPxStr = d[Side.bid].price.currentString;
  const bidPxChangeStr = d[Side.bid].price.diffString;
  const bidPxChange = d[Side.bid].price.diff;

  // offer px
  const offerPxStr = d[Side.offer].price.currentString;

  // percentiles
  const bidPercentile = getPercentileValue(Side.bid)(bond);
  const offerPercentile = getPercentileValue(Side.offer)(bond);
  
  // spread
  const bidOfferSpread = bond.bidOfferSpread?.[uiMode] ?? 'Percentiles';

  // tenor
  const tenor = d[Side.bid].gspread.tenor;


  return (
    <div className={classNames(selected ? "border-[#4384C8]" : "border-transparent", "bg-[#1F2034] border-2 flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] pb-[0.625rem] pt-[1.875rem] px-[0.625rem] rounded-[0.625rem] w-[17.1875rem] text-[0.875rem]")}>
      <DeleteButton
        onClick={e => { /* stop propogation since the card also has an onClick */ e.stopPropagation(); remove(bond.figi); }}
      />
      <BondCardData bond={bond} tenor={tenor} />


      <div className="bg-[#1F2034] flex flex-col gap-[0.75rem] p-[0.625rem] rounded-[0.4375rem] w-full">

        {isIgUiMode && (
          <>
            <Row>
              <Col label={gct(RunColumn.BidGSpd)} value={bidGspdStr} />
              <Col label={gct(RunColumn.OfferGSpd)} value={offerGSpdStr} />
            </Row>

            <Row>
              <Col
                label={gct(RunColumn.BidGSpdChange)}
                value={bidGspdChangeStr}
                valueClassName={classNames(getAsymptoticSpectrumBackground(bidGspdChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                box
              />
            </Row>
          </>
        )}

        {isHyUiMode && (
          <>
            <Row>
              <Col label={gct(RunColumn.BidPx)} value={bidPxStr} />
              <Col label={gct(RunColumn.OfferPx)} value={offerPxStr} />
            </Row>
            <Row>
              <Col
                label={gct(RunColumn.BidPxChange)}
                value={bidPxChangeStr}
                valueClassName={classNames(getAsymptoticSpectrumBackground(bidPxChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                box
              />
            </Row>
          </>
        )}

        <Row>
          <Col label={gct(RunColumn.BOSpd)} value={bidOfferSpread} />
        </Row>

        <Row>
          <Col label={gct(RunColumn.BidPercentile)} value={`${bidPercentile}%`} />
          <Col label={gct(RunColumn.OfferPercentile)} value={`${offerPercentile}%`} end />
        </Row>

        {isIgUiMode &&(
          <Row>
            <Col label={gct(RunColumn.BYTM)} value={bidYTMStr} />
            <Col label={gct(RunColumn.OfferYTM)} value={offerYTMStr} />
          </Row>
        )}

        <Row style={{ order: 6 }}>
          <Col label={gct(RunColumn.BidSize)} value={(bond.bidSize / 1000000) + ' MM'} />
          <Col label={gct(RunColumn.OfferSize)} value={(bond.offerSize / 1000000) + ' MM'} end />
        </Row>


      </div>
    </div>
  )
};

