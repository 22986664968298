import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaFilter } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';


export const FilterDrawerBase = ({
  children,
  open, 
  onClose,
}: {
  children: ReactNode;
  open: boolean; 
  onClose: () => void;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[10]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#0A0B11]/[0.70]" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="bg-[#333557] bottom-0 fixed flex flex-col gap-[0.625rem] items-center left-0 max-w-[30rem] overflow-y-auto p-[1.25rem] shadow-xl top-[--header-height] w-screen">

            {/* Header */}
            <Dialog.Title className="flex flex-row items-center min-h-[3.5rem] justify-between text-[#A5A6C9] w-full py-2.5">
              <FaFilter className="text-[1rem] ml-2.5" />
              <span className="sr-only">Options</span>
              <button
                className="bg-transparent text-[#FBFBFD] p-[0.5rem] rounded-[0.625rem] focus:outline-none focus:ring focus:ring-indigo-500 focus:ring-offset-1"
                onClick={() => onClose()}
              >
                <span className="sr-only">Close</span>
                <FaTimes />
              </button>
            </Dialog.Title>

            {/* Body */}
            <div className="flex flex-col gap-[0.875rem] mt-[1.25rem] w-full flex-1">
              {children}
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

