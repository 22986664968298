import { FunctionComponent } from "react";
import { Bond } from '../data/dataProvider';
import { formatAmountOutstanding } from "@/utils/number.utils";

const BondCard: FunctionComponent<{ bond: Bond }> = ({ bond }) => {

  return (
    <div className="bg-[#333557] flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.25rem] rounded-[0.625rem] w-[17.1875rem]">
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col">
          <span className="text-[#8183B3]">Ticker</span>
          <span>{bond.ticker}</span>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-[#8183B3]">Coupon</span>
          <span>{bond.coupon.toFixed(3)}%</span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col">
          <span className="text-[#8183B3]">Maturity</span>
          <span>{bond.maturity}</span>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-[#8183B3]">Series</span>
          <span>{bond.series || '-'}</span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col">
          <span className="text-[#8183B3]">CUSIP</span>
          <span>{bond.cusip}</span>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-[#8183B3]">S&P</span>
          <span>{bond.rating || '-'}</span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col">
          <span className="text-[#8183B3]">Outstanding</span>
          <span>{formatAmountOutstanding(bond.amountOutstanding)}</span>
        </div>
      </div>
      <div className="flex flex-col items-start w-full">
        <span className="text-[#8183B3]">Name</span>
        <span className="overflow-x-auto whitespace-nowrap w-[15.9375rem]">{bond.issuer}</span>
      </div>
    </div>
  )
};

export default BondCard;
