import { Fragment, ReactNode, useMemo } from 'react';
import { Listbox as ReactListbox, Portal, Transition, ListboxProps as ReactListboxProps } from '@headlessui/react';
import { Manager, Reference, Popper, Modifier, PopperProps } from 'react-popper';
import clsx from 'clsx';


export type Value<T extends readonly string[], Multiple extends boolean> = Multiple extends true ? T : T[number];


export type ListboxProps<T extends readonly string[], Multiple extends boolean = false> = {
  button?: (props: { disabled: boolean, open: boolean, value: Value<T, Multiple> }) => ReactNode;
  buttonCss?: (props: { disabled: boolean, open: boolean, value: Value<T, Multiple> }) => string;
  divCss?: string;
  option?: (value: T[number], props: { active: boolean, disabled: boolean, selected: boolean }) => ReactNode;
  optionCss?: (value: T[number], props: { active: boolean, disabled: boolean, selected: boolean }) => string;
  optionsCss?: string;
  popperProps?: Partial<PopperProps<any>> & { portal?: boolean, zIndex?: number};
  values: T;
  multiple?: Multiple;
  value?: Multiple extends true ? T : T[number];
  disabled?: boolean;
  selectValue:  (v: Value<T, Multiple>) => void;
};

const ListBox = <T extends readonly string[], Multiple extends boolean = false>({ 
    button, 
    buttonCss, 
    divCss, 
    option, 
    optionCss, 
    optionsCss, 
    popperProps, 
    selectValue, 
    value, 
    values,
    multiple,
    disabled,
}: ListboxProps<T, Multiple>) => {

  // popper modifiers configuration
  const popperModifiers: Partial<Modifier<string, object>>[] = useMemo(() => [
    { 
      name: 'hideOnOutside', 
      enabled: true, 
      phase: 'beforeWrite',
      requires: ['hide'],
      fn: ({ state }) => {
        // hide popper when element is scrolled and popper reference elemement is not visible
        if (state.modifiersData.hide?.isReferenceHidden) {
          state.styles.popper.opacity = '0';
          state.styles.popper.pointerEvents = 'none';
        } else {
          state.styles.popper.opacity = '1';
          state.styles.popper.pointerEvents = 'auto';
        }
      }  
    },
    { name: 'hide', enabled: true },
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top', 'bottom'],
      },
    },
    {
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({ state }) => {
        state.elements.popper.style.width = `${(state.elements.reference as any).offsetWidth}px`;
      }
    },
  ], []);

  const PortalElement =  popperProps?.portal === false ? Fragment : Portal;

  return (
    <Manager>
      <ReactListbox value={value} onChange={selectValue} multiple={multiple} disabled={disabled}>
        {props =>
          <>
            {/* dropdown handle */}
            <Reference >
              {({ ref }) => (
                <div className={clsx('relative', divCss)} ref={ref}>
                  <ReactListbox.Button className={buttonCss ? buttonCss(props) : ''}>
                    {button ? button(props) : <span className="truncate w-full">{value}</span>}
                  </ReactListbox.Button>
                </div>
              )}
            </Reference>


            {/* dropdown list */}
            <PortalElement>
              <Popper 
                placement="bottom-start" 
                strategy="fixed" 
                modifiers={popperModifiers}
                {...popperProps}
              >
                {({ ref, style }) => {
                  return (
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      show={props.open}
                    >
                      <ReactListbox.Options 
                        className={optionsCss || ''}
                        ref={ref}
                        style={{
                          minWidth: 'fit-content',
                          zIndex: popperProps?.zIndex || 100,
                          ...style,
                        }}
                      >
                        {
                          values.map(v =>
                            <ReactListbox.Option
                              key={v}
                              value={v}
                              className={props => optionCss ? optionCss(v, props) : ''}
                            >
                              {props => option ? <>{option(v, props)}</> : <span>{v}</span>}
                            </ReactListbox.Option>
                          )
                        }
                      </ReactListbox.Options>
                    </Transition>
                  )
                }}

              </Popper>
            </PortalElement>
          </>

        }
      </ReactListbox>
    </Manager>
  );
}

export default ListBox;
