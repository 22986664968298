import { useAuthenticator } from "@aws-amplify/ui-react";
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { ReactNode, memo, useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions, selectExpiration, selectToken } from "../../store/slices/auth.slice";
import { UIPermission } from "./auth.types";
import { getAppState } from "@/store";
import { ONE_SECOND, TOKEN_REFRESH_WINDOW } from "@/constants";
import { ConfigContext } from "@/site";
import * as Sentry from '@sentry/browser';

export const RefreshToken = memo(({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const reduxToken = useSelector(selectToken);
  const config = useContext(ConfigContext);

  useEffect(() => {
    // save api url in redux store for rtk query. Remove it when we switch to one api per environment
    dispatch(authActions.setApiUrl(config.api))
  }, [config.api, dispatch])
  
  useEffect(() => {
    // reset state when user is not available
    return () => {
      Sentry.setUser(null);
      dispatch({ type: 'USER_LOGOUT' })
    }
  }, [dispatch])

  const syncWithRedux = useCallback(() => {
    let { token, expiration, uiPermission, hasCusipAccess, email, username, uiAccess, uiTrialExpiration   } = extractTokenData(user!.getSignInUserSession()!.getIdToken());

    Sentry.setUser({ email, id: username });

    dispatch(authActions.setAuthData({
      token,
      expiration,
      uiPermission,
      hasCusipAccess,
      uiAccess,
      uiTrialExpiration,
      signOut,
    }));

    return { token }
  }, [user, dispatch, signOut])

  const refreshToken = useCallback(async () => {
    // refreshes user token which will trigger use effect hook because user data change
    await new Promise<void>((resolve, reject) => {

      user!.refreshSession(user!.getSignInUserSession()!.getRefreshToken(), (err) => {
        if (err) {
          (signOut || (() => {}))();
          reject(err)
        } else {
          resolve();
        }
      });
    });

    return syncWithRedux(); // sync latest auth data with redux
  }, [user, syncWithRedux, signOut])

  const getToken = useCallback(async () => {
    const now = Date.now()
    const expiration = selectExpiration(getAppState());
    const refreshTime = (expiration - TOKEN_REFRESH_WINDOW.earliest) * 1000;

    if (now >= refreshTime) {
      return refreshToken();
    } else {
      return {
        token: selectToken(getAppState()) as string,
      };
    }

  }, [refreshToken])

  // store token in redux store and trigger refresh token
  useEffect(() => {
    if (!user) {
      return;
    }

    if (!reduxToken) {
      // sync with redux only one time. Later sync is done by `refreshToken` function
      syncWithRedux();
    }

    dispatch(authActions.setRefreshTokenFn(refreshToken));
    dispatch(authActions.setGetTokenFn(getToken));
  }, [user, dispatch, refreshToken])

  useEffect(() => {
    // refresh token every x seconds
    const timerId = setInterval(() => {
      getToken(); // get token will refresh token if needed
    }, ONE_SECOND * 10)

    return () => clearInterval(timerId);
  }, [getToken])

  return reduxToken ? children : null;
})

interface DeepMMJwtPayload extends JwtPayload {
  deepmm_api: string
  deepmm_api_trial: string;
  'cognito:username': string;
  email: string;
  deepmm_ui: string; // true if user has access to the ui
  deepmm_ui_trial: string; // unix timestamp
  deepmm_cusip_access: string; 
}

type ExtractTokenData = (idToken: CognitoIdToken) => { 
  token: string, 
  expiration: number, 
  uiPermission: UIPermission 
  hasCusipAccess: boolean
  email: string;
  username: string;
  uiAccess: boolean
  uiTrialExpiration: number;
};

const extractTokenData: ExtractTokenData  = (idToken) => {
  const expiration = idToken.getExpiration(); // factors in local clock drift
  const token = idToken.getJwtToken();
  const decodedToken = jwtDecode<DeepMMJwtPayload>(token);

  const hasCusipAccess = decodedToken.deepmm_cusip_access === 'true';
  const uiPermission = decodedToken.deepmm_ui === 'true' ? 'full' :
    Date.now() / 1000 <= Number(decodedToken.deepmm_ui_trial) ? 'trial' :
      'none'

  return {
    token, 
    expiration, 
    uiPermission, 
    hasCusipAccess,
    username: decodedToken['cognito:username'],
    email: decodedToken.email,
    uiAccess: decodedToken.deepmm_ui === 'true',
    uiTrialExpiration: Number(decodedToken.deepmm_ui_trial)
  };
}

