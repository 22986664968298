import { selectFile } from "@/utils/file.utils";
import { parse } from 'csv-parse/browser/esm/sync'
import { captureException } from "@sentry/react";
import { DEFAULT_POSITION_SIZE, MIN_POSITION_SIZE } from "../portfolio.constants";
import { isEmpty, isObject } from "lodash";
import { Position } from "@/app/data/portfolios";
import { GetBond, GetBondByCusip, GetBondByIsin } from "@/app/data/bondIndex";

type PositionWithOptionalSize = {
  figi: string;
  size: number | null;
}


// asks user to select a file and imports the portfolio from the file
// positions from current portfolio are merged with csv positions
// positions from csv override positions from current portfolio
// if a position from csv is not valid it is ignored
// if a position from csv is valid it updates position or creates it if it's not in the current portfolio
// we DO NOT remove position if it's not in the csv
// if position size is missing in csv we use position size of current portfolio
// if position size is missing in current portfolio we use DEFAULT_POSITION_SIZE
export async function importPortfolio({
  positions,
  getBond,
  getBondByCusip,
  getBondByIsin,
}: {
  positions: Position[]
  getBond: GetBond
  getBondByCusip: GetBondByCusip
  getBondByIsin: GetBondByIsin
}) {
  const file = await selectFile('.csv');

  if (!file) {
    return;
  }

  const {
    positions: csvPositions,
    errors,
    csvRows,
    missingCusips,
    missingFigis,
    missingIsins,
  } = await readCsvPortfolio({ file, getBond, getBondByCusip, getBondByIsin });

  const {
    positions: updatedPositions,
    positionsUpdatedCount,
    positionsAddedCount,
    sizeMissingCount,
  } = mergePositionsWithCsvPositions(positions, csvPositions);

  return {
    positions: updatedPositions,
    csvPositions,
    errors,
    positionsUpdatedCount,
    positionsAddedCount,
    sizeMissingCount,
    file,
    csvRows,
    missingCusips,
    missingFigis,
    missingIsins,
  }

}


function mergePositionsWithCsvPositions(positions: Position[], csvPositions: PositionWithOptionalSize[]): {
  positions: Position[],
  positionsUpdatedCount: number,
  positionsAddedCount: number,
  sizeMissingCount: number,
} {
  let positionsUpdatedCount = 0;
  let positionsAddedCount = 0;
  let sizeMissingCount = 0;

  if (isEmpty(csvPositions)) {
    return {
      positions,
      positionsUpdatedCount,
      positionsAddedCount,
      sizeMissingCount,
    }
  }


  let newPositions: Position[] = [...positions];

  // sync positions with csv positions if position already exists
  newPositions = newPositions.map(position => {
    const csvPosition = csvPositions.find(p => p.figi === position.figi);

    if (!csvPosition) {
      return position;
    }

    if (!csvPosition.size) {
      sizeMissingCount++;
    }

    // update position with csv position size
    positionsUpdatedCount++
    return {
      ...position,
      size: csvPosition.size ?? position.size,
    }
  })


  // add positions from csv that are not in the portfolio
  csvPositions.forEach(csvPosition => {
    if (newPositions.find(p => p.figi === csvPosition.figi)) {
      // position was already updated -> see above
      return;
    }

    // position does not exist in the portfolio
    if (!csvPosition.size) {
      sizeMissingCount++;
    }

    positionsAddedCount++;
    newPositions.push({
      figi: csvPosition.figi,
      size: csvPosition.size || DEFAULT_POSITION_SIZE,
    })
  })

  return {
    positions: newPositions,
    positionsUpdatedCount,
    positionsAddedCount,
    sizeMissingCount,
  };

}

function readCsvPortfolio({
  file,
  getBond,
  getBondByCusip,
  getBondByIsin,
}: {
  file: File,
  getBond: GetBond,
  getBondByCusip: GetBondByCusip
  getBondByIsin: GetBondByIsin
}): Promise<{
  positions: PositionWithOptionalSize[],
  errors: string[],
  csvRows: any[],
  missingFigis: string[];
  missingCusips: string[];
  missingIsins: string[];
}> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const text = e.target.result;

        try {
          const result = getPositionsFromCsvString({
            csvString: text as string,
            getBond,
            getBondByCusip,
            getBondByIsin,
          });
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      reject(error)
    }
  })
}

function getPositionSizeFromRow(row: Record<any, any>): number | null {
  if (!isObject(row)) {
    return DEFAULT_POSITION_SIZE;
  }

  const rowKeys = Object.keys(row);
  const positionKey = rowKeys.find(key => key.toLowerCase() === 'position' || key.toLowerCase() === 'size');
  let positionSize = positionKey ? Number(row[positionKey]) : null;
  if (positionSize !== null && isNaN(positionSize)) {
    positionSize = null;
  } else if (positionSize !== null && Math.abs(positionSize) < MIN_POSITION_SIZE) {
    // position is too small, reset value to something bigger
    positionSize = positionSize < 0 ? -1 * MIN_POSITION_SIZE : MIN_POSITION_SIZE;
  }

  return positionSize
}

function getPositionsFromCsvString({
  csvString,
  getBond,
  getBondByCusip,
  getBondByIsin,
}: {
  csvString: string,
  getBond: GetBond,
  getBondByCusip: GetBondByCusip,
  getBondByIsin: GetBondByIsin,
}): {
  positions: PositionWithOptionalSize[],
  errors: string[],
  csvRows: any[],
  missingFigis: string[];
  missingCusips: string[];
  missingIsins: string[];
} {
  const rows = parse(csvString, {
    columns: true,
    skip_empty_lines: true
  });

  const missingFigis = new Set<string>();
  const missingCusips = new Set<string>();
  const missingIsins = new Set<string>();
  const positions: PositionWithOptionalSize[] = [];

  rows.forEach((row: any) => {
    const figi = getFigiFromRow({ 
      row, 
      getBond, 
      getBondByCusip, 
      getBondByIsin,
      missingFigis,
      missingCusips,
      missingIsins,
    });

    if (!figi) {
      // we're not able to import this position without figi
      return;
    }

    const positionSize = getPositionSizeFromRow(row);

    positions.push({
      figi,
      size: positionSize,
    })
  })

  return {
    positions,
    errors: [],
    csvRows: rows,
    missingFigis: Array.from(missingFigis),
    missingCusips: Array.from(missingCusips),
    missingIsins: Array.from(missingIsins),
  }
}

const getFigiFromRow = ({
  row,
  missingIsins,
  missingFigis,
  missingCusips,
  getBond,
  getBondByCusip,
  getBondByIsin,
}: {
  row: Record<any, any>,
  missingIsins: Set<string>,
  missingFigis: Set<string>,
  missingCusips: Set<string>,
  getBond: GetBond,
  getBondByCusip: GetBondByCusip,
  getBondByIsin: GetBondByIsin,
}): string | undefined => {
  if (!getBond || !getBondByCusip || !getBondByIsin) {
    throw new Error('getBond and getBondByCusip and getBondByIsin are required');
  }

  const rowKeys = Object.keys(row);
  const cusipKey = rowKeys.find(key => key.toLowerCase() === 'cusip');
  const figiKey = rowKeys.find(key => key.toLowerCase() === 'figi');
  const isinKey = rowKeys.find(key => key.toLowerCase() === 'isin');

  if (!cusipKey && !figiKey && !isinKey) {
    // figi and cusip key are missing, we're not able to import this position
    return;
  }

  let cusip = row[cusipKey!];
  let figi = row[figiKey!];
  let isin = row[isinKey!];

  if (isin) {
    // check if isin is valid
    const bond = getBondByIsin(isin);
    if (bond) {
      // isin is valid
      return bond.figi;
    } else {
      // isin is not valid
      missingIsins.add(isin);
    }
  }

  if (figi) {
    // check if figi is valid
    const bond = getBond(figi);
    if (bond) {
      // figi is valid
      return figi;
    } else {
      // figi is not valid
      missingFigis.add(figi);
    }
  }

  if (cusip) {
    // figi is missing or not valid
    // get figi from cusip
    const bond = getBondByCusip(cusip);
    if (bond) {
      figi = bond.figi;
      return figi;
    } else {
      missingCusips.add(cusip);
    }
  }

  return;
}