import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AtsIndicator, createInferenceKey, DataContext, Inference, Side } from "./dataProvider";
import { PriceType } from '@/types/types';
import { isEqual } from 'lodash';
import { InferenceRequestItem } from './api';

export type InferenceRequest = {
  figi: string;
  atsIndicator: AtsIndicator;
  quantity: number;
  side: Side;
  rfq_label: PriceType;
};

const inferencesEqual = (a: Inference | null, b: Inference | null) => {
  return isEqual(a, b);
};

const useInferences = (inferenceRequests: InferenceRequest[], previousTimestamp?: number) => {
  const { getPrevEoD, inferences: allInferences, send } = useContext(DataContext);

  const prevEod = getPrevEoD ? getPrevEoD(Date.now()) : null;
  const _previousTimestamp = previousTimestamp ? previousTimestamp : prevEod;
  const prevInferenceCache = useRef<{ [key: string]: Inference }>({});

  // clear the prevInferenceCache any time _previousTimestamp changes so it doesn't grow too big
  useEffect(() => {
    prevInferenceCache.current = {};
  }, [_previousTimestamp]);

  const requests = inferenceRequests;

  useEffect(() => {
    if (requests && requests.length && send) {
      send({ inference: requests.map(r => ({ figi: r.figi, ats_indicator: r.atsIndicator, quantity: r.quantity, side: r.side, rfq_label: r.rfq_label, subscribe: true })) });
      return () => { send({ inference: requests.map(r => ({ figi: r.figi, ats_indicator: r.atsIndicator, quantity: r.quantity, rfq_label: r.rfq_label, side: r.side, unsubscribe: true })) }, false); }
    }
  }, [requests, send]);

  const [inferences, setInferences] = useState<{ [key: string]: Inference }>({});
  useEffect(() => {
    const newInferences = {} as { [key: string]: Inference };
    requests.forEach(r => {
      const key = createInferenceKey(r.figi, r.atsIndicator, r.quantity, r.side, r.rfq_label);

      const requestInferences = (r.figi && allInferences[key]) || [];
      const newestInference = requestInferences.reduce((a: Inference | null, c) => !a || a.date < c.date ? c : a, null);
      if (newestInference) {
        newInferences[`${key}_C`] = newestInference
      }
      const previousInference = _previousTimestamp ? requestInferences.findLast(i => i.date.getTime() === _previousTimestamp) || null : null;
      if (previousInference) {
        newInferences[`${key}_P`] = previousInference
      }
    });
    setInferences(prev => {
      const prevKeys = Object.getOwnPropertyNames(prev);
      const newKeySet = new Set(Object.getOwnPropertyNames(newInferences));
      return prevKeys.length === newKeySet.size &&
        prevKeys.every(k => newKeySet.has(k)) &&
        prevKeys.every(k => inferencesEqual(prev[k], newInferences[k]))
        ? prev : newInferences
    });
  }, [allInferences, _previousTimestamp, requests]);

  useEffect(() => {
    if (_previousTimestamp && send) {
      const previousRequests = requests.reduce((a, c) => {
        const key = createInferenceKey(c.figi, c.atsIndicator, c.quantity, c.side, c.rfq_label);
        if (!inferences[`${key}_P`] && inferences[`${key}_C`]) {
          a.push({ figi: c.figi, ats_indicator: c.atsIndicator, quantity: c.quantity, side: c.side, rfq_label: c.rfq_label, timestamp: [(new Date(_previousTimestamp)).toISOString()] });
        }
        return a;
      }, [] as InferenceRequestItem[]);
      if (previousRequests.length) {
        send({ inference: previousRequests });
      }
    }
  }, [inferences, _previousTimestamp, requests, send]);

  const getInference: GetInference = useCallback((figi, atsIndicator, quantity, side, rfq_label, previous = false) => {
    const key = createInferenceKey(figi, atsIndicator, quantity, side, rfq_label);
    if (previous) {
      // if it's a previous inference then check/update prevInferenceCache to prevent a gap
      // when the previous inferences drop out of the global inferences cache and
      // get re-requested
      const inferencesKey = `${key}_P`;
      if (!prevInferenceCache.current[inferencesKey] && inferences[inferencesKey]) {
        prevInferenceCache.current[inferencesKey] = inferences[inferencesKey];
      }
      return prevInferenceCache.current[inferencesKey] as Inference | undefined;
    } else {
      return inferences[`${key}_C`] as Inference | undefined;
    }
  }, [inferences]);

  return [getInference, inferences, prevInferenceCache.current] as const;
}

export type GetInference = (figi: string, atsIndicator: AtsIndicator, quantity: number, side: Side, rfq_label: PriceType, previous?: boolean) => Inference | undefined;

export default useInferences;
